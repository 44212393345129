<template>
    <h1>Planowane wyłączenia - panel akceptującego</h1>
    <PlannedOutageReportAcceptSearchCriteria ref="plannedOutageReportAcceptSearchCriteria" v-model="searchCriteria"
                                             @update:modelValue="search" @confirmDelete="deleteSelectedOutages" />
    <PlannedOutageReportAcceptTable ref="plannedOutageReportAcceptTable" :search-criteria="searchCriteria" />
</template>

<script>
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import PlannedOutageReportAcceptSearchCriteria from "./components/PlannedOutageReportAcceptSearchCriteria";
    import PlannedOutageReportAcceptTable from "./components/PlannedOutageReportAcceptTable";

    export default {
        name: "PlannedOutageReportAcceptView",

        components: {
            PlannedOutageReportAcceptSearchCriteria,
            PlannedOutageReportAcceptTable,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.plannedOutageReportAcceptTable.search();
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

            getClearSearchCriteria() {
                return {
                    id: null,
                    addresses: {address: ""},
                    outageStart: null,
                    deleted: false,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },

            deleteSelectedOutages() {
                this.$refs.plannedOutageReportAcceptTable.deleteSelectedOutages();
            },
        },
    };
</script>

<style lang="less" scoped>

</style>
