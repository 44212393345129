<template>
    <div :class="computedClass">
        <div class="eon-search-criteria">
            <EonInputText width="280" name="address" label="Adres" v-model="internalValue.address"
                          @update:modelValue="update" :showLabel="true" />
            <EonTimePicker width="280" name="picker" label="Planowany czas rozpoczęcia od" v-model="internalValue.start"
                           @update:modelValue="update" :showLabel="true" :minDate="getYesterday()" :showTime="false"/>
        </div>
    </div>
</template>

<script>
    import EonInputNumber from "@/components/form/EonInputNumber";
    import EonDropdown from "@/components/form/EonDropdown";
    import EonTimePicker from "@/components/form/EonTimePicker";
    import EonButton from "@/components/form/EonButton";
    import {SystemRole} from "@/utils/SystemRole";
    import EonInputText from "@/components/form/EonInputText";

    export default {
        name: "PlannedOutageReportSearchCriteriaPublic",

        props: {
            modelValue: null,
            mapView: {
                default: false,
            },
        },

        emits: ["update:modelValue", "confirmDelete"],

        methods: {
            update() {
                this.$emit("update:modelValue", this.internalValue);
            },
            deleteSelected() {
                this.$emit("confirmDelete");
            },
            getYesterday() {
                return (function (d) { d.setDate(d.getDate() - 1); return d; }(new Date()));
            },
        },

        data() {
            return {
                Role: SystemRole,
            };
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            computedClass() {
                return this.mapView ? "map-view" : "table-view";
            },
            canDelete() {
                return SystemRole.isAdmin() || SystemRole.isDispatcher();
            },
        },

        components: {
            EonInputText,
            EonButton,
            EonTimePicker,
            EonDropdown,
            EonInputNumber,
        },
    };
</script>

<style lang="less" scoped>
@import "../../assets/less/eon-search-criteria.less";
</style>
