<template>
    <PrimeButton iconPos="right" class="eon-outlined-button" type="button" :width="svgWidth" :color="color">
        <span class="button-inside">
            <span class="button-icon-label">
                <span class="button-icon" v-if="icon" :class="icon"></span>
                <label class="button-label" v-if="label" for="eon-outlined-button">{{ label }}</label>
            </span>
            <span v-if="withSvg">
                <!-- eslint-disable max-len -->
                <svg :width="svgWidth" height="48" :viewBox="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g class="background">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.76724 0.99305C4.60071 1.5442 2.11529 4.01404 1.54424 7.17705C-0.514748 18.2986 -0.514748 29.7035 1.54424 40.8251C2.11651 43.9857 4.60225 46.4523 7.76724 47C13.2302 47.893 24.0002 48 24.0002 48V4.95911e-05C24.0002 4.95911e-05 12.6162 0.12005 7.76724 0.99305Z" fill="#FFFFFF"/>
                        <rect :width="rectWidth" height="48" transform="translate(23.0002 9.91821e-05)" fill="#FFFFFF"/>
                        <path :transform="transform" fill-rule="evenodd" clip-rule="evenodd" d="M118 4.95911e-05V48C118 48 128.77 47.893 134.233 47C137.399 46.452 139.886 43.984 140.457 40.822C142.515 29.7005 142.515 18.2956 140.457 7.17405C139.885 4.01205 137.399 1.5437 134.233 0.99305C129.384 0.12005 118 4.95911e-05 118 4.95911e-05Z" fill="#FFFFFF"/>
                    </g>
                    <g class="outline">
                        <path d="M8.123 45.0345C6.97579 44.8429 5.91622 44.3003 5.09022 43.4815C4.26422 42.6626 3.71245 41.6078 3.5109 40.4623C1.5061 29.579 1.5061 18.4211 3.5109 7.5378C3.71056 6.39112 4.26151 5.33478 5.08759 4.51481C5.91366 3.69484 6.97407 3.15175 8.1222 2.9606C12.7491 2.1271 23.9087 2.001 24 2V0C24 0 12.6161 0.1188 7.7676 0.9922C6.22166 1.26151 4.79572 1.99909 3.68261 3.10517C2.56949 4.21126 1.8229 5.6325 1.5438 7.1767C-0.5146 18.2978 -0.5146 29.7023 1.5438 40.8234C1.82299 42.3671 2.56971 43.7878 3.68289 44.8931C4.79607 45.9985 6.22197 46.7351 7.7676 47.0034C13.2305 47.8926 24 48 24 48V46C23.5793 45.9954 13.3047 45.8716 8.123 45.0345Z" fill="#EA1B0A"/>
                        <path :d="lines" fill="#EA1B0A"/>
                        <path :transform="transform" d="M133.877 45.0345C135.025 44.8429 136.084 44.3003 136.91 43.4815C137.736 42.6626 138.288 41.6078 138.489 40.4623C140.494 29.579 140.494 18.4211 138.489 7.5378C138.29 6.39113 137.739 5.3348 136.913 4.51484C136.087 3.69488 135.026 3.15177 133.878 2.9606C129.251 2.1271 118.092 2.001 118 2V0C118 0 129.384 0.1188 134.233 0.9922C135.779 1.26151 137.205 1.99909 138.318 3.10517C139.431 4.21126 140.177 5.6325 140.457 7.1767C142.515 18.2978 142.515 29.7023 140.457 40.8234C140.177 42.3671 139.431 43.7878 138.317 44.8931C137.204 45.9985 135.778 46.7351 134.233 47.0034C128.77 47.8926 118 48 118 48V46C118.421 45.9954 128.696 45.8716 133.877 45.0345Z" fill="#EA1B0A"/>
                    </g>
                </svg>
                <!-- eslint-enable max-len -->
            </span>
        </span>
    </PrimeButton>
</template>

<script>

    import PrimeButton from "primevue/button";

    export default {
        name: "EonOutlinedButton",
        components: {PrimeButton},
        computed: {
            svgWidth() {
                return 142 + Number(this.extension);
            },
            rectWidth() {
                return 96 + Number(this.extension);
            },
            linesWidth() {
                return 118 + Number(this.extension);
            },
            viewBox() {
                return "0 0 " + this.svgWidth + " 48";
            },
            lines() {
                return "M24.0002 2.00005H" + this.linesWidth
                    + "V-1.99995H24.0002V2.00005ZM118 46H24.0002V50H" + this.linesWidth + "V46Z";
            },
            transform() {
                return "translate(" + this.extension + ")";
            },
        },
        props: {
            label: {
                type: String,
            },
            icon: {
                type: String,
                default: null,
            },
            withSvg: {
                type: Boolean,
                default: true,
            },
            width: {
                type: Number,
                default: 94.0001,
            },
            extension: {
                type: String,
                default: "0",
            },
            color: {
                type: String,
                default: "@color-red",
            },
        },
    };
</script>

<style lang="less">
@import "../assets/less/outlined-button";
.eon-outlined-button {
    .button-inside {
        label.button-label {
            cursor: pointer;
        }
    }
}
</style>
