<template>
    <div class="view">
        <Toast/>
        <h1>Edytuj dane użytkownika</h1>
        <VeeForm v-if="loaded" @submit="saveUser" v-slot="{ isSubmitting }" class="form">
            <div class="field">
                <p>Rola</p>
                <EonDropdown width="250" :items="role" itemLabel="name" itemValue="code"
                             placeholder="Rola" name="systemRole" v-model="user.systemRole"/>
            </div>
            <div class="field">
                <p>Imię i nazwisko</p>
                <EonInputText width="250" label="Imię i nazwisko" name="fullName"
                              v-model="user.fullName" rules="required"/>
            </div>
            <div class="field">
                <p>E-mail</p>
                <EonInputText width="250" label="E-mail" name="email"
                              v-model="user.email" rules="required|email"/>
            </div>
            <div class="buttons">
                <EonButton label="Zapisz" type="submit" :disabled="isSubmitting"/>
                <EonOutlinedButton label="Anuluj" type="button" @click="goBack"/>
            </div>
        </VeeForm>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import {Form as VeeForm} from "vee-validate";
    import {
        editUser,
        getUser,
        getArea,
    } from "@/swagger/vue-api-client";
    import EonButton from "@/components/form/EonButton";
    import EonInputText from "@/components/form/EonInputText";
    import EonDropdown from "@/components/form/EonDropdown";
    import EonInputNumber from "@/components/form/EonInputNumber";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import {StatusCodes} from "@/utils/StatusCodes";
    import CustomPassword from "@/components/form/CustomPassword";
    import CustomInputText from "@/components/form/CustomInputText";

    export default {
        name: "UserFormView",

        components: {
            EonOutlinedButton,
            CustomInputText,
            CustomPassword,
            Toast,
            VeeForm,
            EonButton,
            EonInputText,
            EonDropdown,
            EonInputNumber,
        },

        props: {
            value: {},
        },

        data() {
            return {
                ar: null,
                user: {
                    id: null,
                    systemRole: null,
                    area: null,
                    fullName: null,
                    email: null,
                    phone: null,
                },
                loaded: false,
                role: [
                    {name: "Mistrz prac planowych", code: "DISPATCHER"},
                    {name: "Administrator", code: "ADMIN"},
                    {name: "Call Center", code: "CALLCENTER"},
                    {name: "Akceptujący", code: "ACCEPTER"},
                ],
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getUser({id: this.$route.params.id}).then((response) => {
                    this.user = response.data;
                    if (!this.user.area) {
                        this.user.area = {id: null};
                    }
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
            }
        },

        methods: {
            async saveUser() {
                if (this.user.area && this.user.area.id) {
                    const areaResponse = await getArea({id: this.user.area.id});
                    this.user.area = areaResponse.data;
                }
                editUser({body: this.user, id: this.user.id}).then(() => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Zapisano dane użytkownika",
                        life: 2000,
                    });
                })
                    .catch((error) => {
                        if (error.response.status === StatusCodes.FORBIDDEN) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Nie masz wystarczających uprawnień",
                                life: 2000,
                            });
                        } else {
                            this.$toast.add({
                                severity: "error",
                                summary: "Wystąpił nieoczekiwany błąd",
                                life: 2000,
                            });
                        }
                    });
                // eslint-disable-next-line no-promise-executor-return
                await new Promise((r) => setTimeout(r, 1000));
                this.goBack();
            },
            goBack() {
                this.$router.go(-1);
            },
        },
    };
</script>

<style lang="less" scoped>
@import "../assets/less/edit-view.less";
</style>
