<template>
    <!-- eslint-disable max-len -->
    <div class="privacy-policy-wrapper">
        <div class="privacy-policy-BG">
            <div class="privacy-policy-header-container">
                <div class="privacy-policy-header-container__text">
                    <h1>Polityka prywatności</h1>
                </div>
                <div class="privacy-policy-header-container__img"></div>
            </div>
            <section class="privacy-policy-body">
                <article>
                    <header>
                        POSTANOWIENIA OGÓLNE
                    </header>
                    <ol>
                        <li>Polityka określa w jaki sposób wykorzystujemy i przetwarzamy Twoje dane osobowe (dalej: dane) w związku z korzystaniem przez Ciebie z naszej strony internetowej. Prezentujemy także informacje o plikach cookies i innych stosowanych przez nas narzędziach.</li>
                        <li>Polityka dotyczy wszystkich naszych stron internetowych i serwisów internetowych (dalej łącznie: Serwis/Serwisy).</li>
                    </ol>
                </article>
                <article>
                    <header>
                        PRZETWARZANIE DANYCH OSOBOWYCH
                    </header>
                    <ol>
                        <li>Administratorem Twoich danych osobowych jest Stoen Operator sp. z o.o. z siedzibą w Warszawie, ul. Piękna 46, 00-672 Warszawa, wpisana do rejestru przedsiębiorców przez Sąd Rejonowy dla m.st. Warszawy, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS 0000270640, NIP 525-238-60-94, REGON 140787780, kapitał zakładowy: 2.628.938.750,00 złotych.</li>
                        <li>Twoje dane osobowe zbieramy poprzez: zaakceptowane przez Ciebie pliki cookies, używanie narzędzia analitycznego Google Analytics, skorzystanie przez Ciebie z formularza kontaktowego, formularza zgłoszenia awarii, rejestrację i logowanie na: <a href="http://www.e-bok.stoen.pl" target="_blank">www.e-bok.stoen.pl</a> lub <a href="http://przylacza.stoen.pl" target="_blank">przylacza.stoen.pl</a>.</li>
                        <li>Podczas korzystania przez Ciebie z naszych usług w Serwisach zbieramy w szczególności: adresy IP, adres URL, informacje o zachowaniach w Serwisach, kliknięcia w elementy stron internetowych w Serwisach, nazwy otwieranych plików, daty i godziny wejść do Serwisów, nazwę Twojego dostawcy usług internetowych, informację o rodzaju Twojego systemu operacyjnego oraz o rodzaju i wersji Twojej przeglądarki.</li>
                        <li>Jeżeli kontaktujesz się z nami za pośrednictwem formularzy kontaktowych dostępnych w Serwisach, w zależności od rodzaju formularza, w zbieramy także Twoje imię, nazwisko, adres e-mail, numer telefonu, a także numer identyfikacyjny (numer konta umowy, numer partnera handlowego), numer licznika, adres poboru energii, a w przypadku, gdy rejestrujesz się lub logujesz się w: <a href="http://www.e-bok.stoen.pl" target="_blank">www.e-bok.stoen.pl</a> lub <a href="http://przylacza.stoen.pl">przylacza.stoen.pl</a> zbieramy Twój adres e-mail.</li>
                        <li>Twoje dane osobowe są przetwarzane w celu korzystania przez Ciebie z pełnej funkcjonalności naszych Serwisów, realizacji na Twoją rzecz usług świadczonych drogą elektroniczną, do celów analitycznych, aby poprawić jakość Serwisów, do celów rozwoju, naprawy lub testów różnych funkcjonalności Serwisów, do obsługi Twojej wiadomości w formularzu kontaktowym lub formularzu zgłoszenia awarii, a także do badań satysfakcji.&nbsp; &nbsp;</li>
                    </ol>
                </article>
                <article>
                    <header>
                        PRAWA DOTYCZĄCE PRZETWARZANIA TWOICH DANYCH OSOBOWYCH
                    </header>
                    <ol>
                        <li>Masz prawo dostępu do przetwarzanych przez nas Twoich danych osobowych i otrzymania kopii Twoich danych, które przetwarzamy.</li>
                        <li>Masz prawo do żądania sprostowania (poprawienia, uzupełnienia) Twoich danych przetwarzanych w Serwisach, ograniczenia ich przetwarzania lub usunięcia.</li>
                        <li>Masz prawo złożenia sprzeciwu wobec wykorzystywania danych na cel ustalenia, dochodzenia lub obrony przed roszczeniami.</li>
                        <li>Masz prawo żądania przeniesienia danych, które przetwarzamy tj. możesz je otrzymać w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego oraz masz prawo przesłać te dane innemu administratorowi. Jeżeli jest to technicznie możliwe, masz prawo żądania, aby dane osobowe zostały przesłane przez nas bezpośrednio innemu administratorowi.</li>
                        <li>Masz prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych w przypadku uznania, że przetwarzanie Twoich danych osobowych narusza przepisy z zakresu ochrony danych osobowych.</li>
                        <li>W przypadku akceptacji plików cookies masz także prawo cofnąć wyrażoną zgodę w dowolnym czasie. Szczegółowe informacje dotyczące przetwarzania Twoich danych osobowych w przypadku akceptacji plików cookies znajdziesz <a href="https://stoen.pl/files/2018-05/oi-osd-www-cookies-p.pdf" target="_blank">tutaj</a>.</li>
                        <li>Szczegółowe informacje dotyczące przetwarzania Twoich danych osobowych w przypadku skorzystania z formularza kontaktowego w Zakładce KONTAKT: znajdziesz <a href="https://stoen.pl/files/2023-05/o21.-oi-www-formularz-kontaktowy-stoen-2023-05-08.pdf" target="_blank">tutaj</a>.</li>
                        <li>Szczegółowe informacje dotyczące przetwarzania Twoich danych osobowych w przypadku skorzystania z formularza zgłoszenia awarii znajdziesz <a href="https://stoen.pl/files/2023-06/o20.-oi-www-formularz-zgloszenia-awarii-portal-awarii-stoen-2023-06-02.pdf" target="_blank">tutaj</a>.</li>
                    </ol>
                    <p>Aby skorzystać z ww. praw możesz się z nami skontaktować:</p>
                    <ul>
                        <li>telefonicznie pod numerem: (22) 821-31-31;</li>
                        <li>pod adresem e-mail: <a href="mailto:operator@stoen.pl">operator@stoen.pl</a>;</li>
                        <li>przez elektroniczny formularz kontaktowy dostępny na stronie <a href="http://www.stoen.pl">www.stoen.pl</a>;</li>
                        <li>pisemnie, przesyłając korespondencję na adres: Stoen Operator Sp. z o.o. ul. Nieświeska 52, 03-867 Warszawa;</li>
                        <li>w Biurze Obsługi Klientów - Dystrybucja w Warszawie (aktualny adres Biura dostępny jest na stronie internetowej <a href="http://www.stoen.pl" target="_blank">www.stoen.pl</a>).</li>
                    </ul>
                </article>
                <article>
                    <header>
                        PLIKI COOKIES
                    </header>
                    <ol>
                        <li>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w Twoim urządzeniu końcowym i przeznaczone są do korzystania z Serwisów. Pliki cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</li>
                        <li>Pliki cookies wykorzystywane są przez nas w celu:<br>
                            a) dostosowania zawartości naszych stron internetowych do Twoich preferencji oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać Twoje urządzenie i odpowiednio wyświetlić stronę internetową, dostosowaną do Twoich indywidualnych potrzeb;<br>
                            b) tworzenia statystyk, które pomagają zrozumieć, w jaki sposób korzystasz ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości,<br>
                            c) w serwisie www.e-bok.stoen.pl do zapamiętania wybranych przez Ciebie ustawień oraz umożliwienia automatycznego uzupełnienia pola nazwy użytkownika na Twoim urządzeniu przy ponownym uruchomieniu tego serwisu.</li>
                        <li>Stosujemy dwa rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym do czasu opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym przez czas określony w parametrach plików cookies lub do czasu ich usunięcia.</li>
                        <li>W ramach Serwisu stosowane są następujące rodzaje plików cookies:<br>
                            a) „niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Serwisu, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Serwisu;<br>
                            b) pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach Serwisu;<br>
                            c) „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania z Serwisu;<br>
                            d) „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Ciebie ustawień i personalizację interfejsu, np. w zakresie wybranego języka lub regionu, z którego pochodzisz, rozmiaru czcionki, wyglądu strony internetowej itp.</li>
                        <li>Jeśli nie chcesz, by pliki cookies były instalowane na Twoim urządzeniu, możesz nie wyrazić zgody na instalowanie plików cookies w następujący sposób:<br>
                            a) W przeglądarce Internet Explorer należy: wybrać z menu głównego pozycję "Narzędzia/Opcje internetowe", a następnie w sekcji "Prywatność" zaznaczyć "Zaawansowane" i wyłączyć pliki cookie.<br>
                            b) W przeglądarce Mozilla Firefox należy: wybrać z menu pozycję "Narzędzia", a następnie pozycję "Opcje" i określić poziom prywatności w sekcji "Prywatność".<br>
                            c) W przeglądarce Opera należy: wybrać z menu pozycję "Narzędzia", a następnie z pozycji "Preferencje"wybrać "Zaawansowane", a potem "Ciasteczka".<br>
                            d) W przeglądarce Google Chrome należy: z menu po prawej stronie należy wybrać pozycję "Opcje", a następnie "Dla zaawansowanych" oraz "Ustawienia plików cookies".<br>
                            e) W przeglądarce Safari: w menu Ustawienia należy wybrać pozycję "Safari", a następnie w sekcji Prywatność i ochrona wybrać "Blokuj cookie".</li>
                        <li>Są to przykładowe typy przeglądarek. W większości przeglądarek tego typu informacje dotyczące plików cookie można znaleźć w menu "Narzędzia" lub "Opcje”.</li>
                        <li>Pamiętaj, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na naszych stronach internetowych.</li>
                    </ol>
                </article>
                <article>
                    <header>
                        INNE NARZĘDZIA ANALITYCZNE
                    </header>
                    <h3 style="font-size: 1.25em;">Google Analytics</h3>
                    <p>
                        W Serwisach wykorzystujemy również narzędzie Google Analytics do analizy zachowań użytkowników stron internetowych. Narzędzie to zbiera dane o plikach cookies. Potrzebujemy ich do celów statystycznych, aby sprawdzić jak często odwiedzane są poszczególne strony internetowe, ich zakładki. Dane te wykorzystujemy również do optymalizacji i rozwoju Serwisów.
                    </p>
                </article>
            </section>
        </div>
    </div>
    <!-- eslint-enable max-len -->
</template>

<script>
    export default {

        data() {
            return {
            };
        },

        beforeMount() {
        },

        methods: {
        },

        components: {
        },
    };
</script>

<style lang="less" scoped>
@import "@/assets/theme/variable.less";
    .privacy-policy-wrapper {
        padding-top: 4.5rem;
        .privacy-policy-BG {
            .privacy-policy-header-container {
                width: 100%;
                height: 500px;
                display: flex;
                margin-bottom: 100px;
                &__text {
                    background-color: @tint-bordeaux-00;
                    width: 48%;
                    padding: 50px 140px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: 700px) {
                        width: 100%;
                    }
                    h1 {
                        color: white;
                        font-size: 100px;
                        font-weight: 900;
                        text-align: right;
                        @media screen and (max-width: 1130px) {
                            font-size: 80px;
                        }
                        @media screen and (max-width: 940px) {
                            font-size: 60px;
                        }
                        @media screen and (max-width: 700px) {
                            font-size: 80px;
                        }
                        @media screen and (max-width: 450px) {
                            font-size: 60px;
                        }
                    }
                }
                &__img {
                    width: 52%;
                    background-image: url("../../assets/aboutThirdBG.jpg");
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    @media screen and (max-width: 700px) {
                        display: none;
                    }
                }
            }
            .privacy-policy-body {
                padding: 0 180px;
                @media screen and (max-width: 1000px) {
                    padding: 0 100px;
                }
                @media screen and (max-width: 700px) {
                    padding: 0 50px;
                }
                @media screen and (max-width: 450px) {
                    padding: 0 20px;
                }
                text-align: left;
                margin-bottom: 100px;
                header {
                    color: @tint-bordeaux-00;
                    font-size: 32px;
                    font-weight: bold;
                    margin-bottom: 50px;
                }
                ol, ul {
                    margin-bottom: 50px;
                }
            }
        }
    }
</style>
