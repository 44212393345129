<template>
    <div class="eon-dropdown-field" :style="'width: ' + width + 'px;'">
        <span class="p-float-label" :style="'width: ' + width + 'px;'">
            <Dropdown v-model="internalValue" :id="name" :options="items" :optionLabel="itemLabel" :filter="filter"
                      :optionValue="itemValue" @change="onChange" :style="'width: ' + width + 'px;'"
                      emptyFilterMessage="Brak wyników"/>
            <!--  :placeholder="placeholder"-->
            <label class="eon-dropdown-label" :for="name">{{ placeholder }}</label>
        </span>
        <span v-show="errorMessage" class="p-invalid">
            <i class="pi pi-exclamation-triangle" /> {{ errorMessage }}
        </span>
    </div>
</template>
<script>
    import Dropdown from "primevue/dropdown";
    import {useField} from "vee-validate";

    export default {
        name: "EonDropdown",
        props: {
            name: {
                type: String,
            },
            placeholder: {
                type: String,
            },
            width: {
                type: String,
            },
            itemLabel: {
                type: String,
            },
            itemValue: {
                type: String,
            },
            items: {
                type: Array,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            rules: undefined,
            modelValue: null,
            filter: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                internalItems: this.items,
                selectedValue: null,
            };
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            onChange(val) {
                if (val && val.value) {
                    this.handleChange(val.originalEvent);
                    this.$emit("change", val.value);
                } else {
                    this.handleChange(undefined);
                    this.$emit("change", undefined);
                }
            },
        },

        mounted() {
            if (this.emptyValue) {
                this.internalItems = [{value: null, label: this.$t("message.other.emptyValue")}, ...this.items];
            }
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {Dropdown},
    };
</script>

<style lang="less">
@import "../assets/less/eon-dropdown.less";
.eon-dropdown-label {
    color: @tint-grey-dark-01;
    opacity: 0.8;
}
.p-dropdown-filter-container {
    width: 100px;
}
li.p-dropdown-empty-message {
    text-align: center;
    padding: 4px;
}
</style>
