<template>
    <div class="eon-login-layout-wrapper">
        <div class="eon-logo-field">
            <div class="eon-logo">
                <img src="../../assets/logoStoenWhite.svg" alt="Logo" />
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
    export default {
        name: "EonLayoutLogin",
    };
</script>

<style lang="less" scoped>
@import "../assets/less/eon-layout-simple.less";
.eon-login-layout-wrapper {
    background-color: white;
}
</style>
