<template>
    <div>
        <PrimeDialog class="poll" :id="name" v-model:visible="computedDisplay" :draggable="false"
                     :modal="true" :style="'width: ' + dialogWidth + 'px;'">
            <div class="poll-logo">
                <img src="../../assets/logoStoenRed.svg" alt="Logo" class="poll-logo-img"/>
            </div>
            <slot></slot>
            <EonCheckbox class="poll-checkbox" v-model="noMoreShow"
                         :label="($t('message.poll.never'))"/>
            <div class="poll-buttons">
                <EonButton class="poll-button  inverted turquoise" @click="later" :label="$t('message.poll.later')" />
                <EonButton class="poll-button" @click="navigateToPoll"
                           :label="$t('message.poll.goToPoll')" />
            </div>

        </PrimeDialog>
    </div>
</template>
<script>
    import PrimeDialog from "primevue/dialog";
    import EonButton from "@/components/form/EonButton";
    import VueCookies from "vue-cookies";
    import EonCheckbox from "@/components/form/EonCheckbox";

    export default {
        name: "EonPollDialog",
        props: {
            name: {
                type: String,
            },
            dialogWidth: {
                type: String,
            },
        },
        components: {
            EonCheckbox, PrimeDialog, EonButton, VueCookies,
        },
        data() {
            return {
                value: null,
                display: false,
                noMoreShow: false,
            };
        },
        mounted() {
            if (this.computedDisplay) {
                document.body.classList.add("modal-blur");
            }
            if (this.$cookies.isKey("instantPoll")) {
                this.$cookies.remove("instantPoll");
                this.display = true;
            } else {
                setTimeout(() => {
                    this.display = true;
                }, 60 * 1000);
            }
        },
        methods: {
            navigateToPoll() {
                this.display = false;
                document.body.classList.remove("modal-blur");
                this.$router.replace({name: "poll"});
            },
            close() {
                this.display = false;
                document.body.classList.remove("modal-blur");
            },
            later() {
                if (this.noMoreShow) {
                    this.$cookies.set("poll", "never", "90d");
                } else {
                    this.$cookies.set("poll", "later", "1d");
                }
                this.display = false;
                document.body.classList.remove("modal-blur");
            },
        },
        computed: {
            computedDisplay() {
                return (this.display && !this.$cookies.isKey("poll"));
            },
        },
    };
</script>

<style lang="less">
@import "../../components/assets/less/dialog.less";
@import "../../components/assets/less/eon-poll.less";

</style>
