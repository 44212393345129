<template>
    <div class="add-report-location">
        <h2 class="add-report-header"> Gdzie nie ma zasilania? </h2>
        <div class="add-report-location-inputs">
            <div v-if="pickedLocation">
                <EonRadioButton name="setLocation" v-model="locationType" value="setLocation" @click="setLocation"/>
                <label class="ml-1" for="setLocation"> Użyj zaznaczonej lokalizacji </label>
                <p v-if="pickedLocation && geolocationSucess">
                    {{ this.location.address.full }}
                </p>
            </div>
            <div>
                <EonRadioButton name="findMe" v-model="locationType" value="geolocation" @click="locateUser"/>
                <label class="ml-1" for="findMe"> Użyj mojej lokalizacji </label>
                <p class="geolocation-error" v-if="geolocationError">
                    <i class="pi pi-exclamation-triangle" />
                    Wystąpił błąd lokalizacji
                </p>
                <p class="geolocation-in-progress" v-if="geolocationInProgress">
                    Trwa wyszukiwanie lokalizacji. Proszę czekać.
                </p>
                <p v-if="!pickedLocation && geolocationSucess">
                    {{ this.location.address.full }}
                </p>
            </div>
            <div>
                <EonRadioButton name="typeAddress" v-model="locationType" value="address" @click="clearLocation"/>
                <label class="ml-1" for="typeAddress"> Wprowadź lokalizację ręcznie </label>
            </div>
            <PlacesAutocomplete :google-maps-api="googleMapsApi"
                                placeholder="Adres"
                                @updateAddress="updateAddress"
                                @updateCoords="updateCoords"
                                @updateAddressDetails="updateAddressDetails"
                                :disabled="disabledInput"
                                width="280"
                                class="places-autocomplete"
            />
            <p class="geolocation-error" v-if="invalidReportLocation">
                <i class="pi pi-exclamation-triangle" />
                Adres obsługiwany przez innego Operatora Sieci Dystrybucyjnej.
            </p>
            <p class="geolocation-error" v-if="areaMatchError">
                <i class="pi pi-exclamation-triangle" />
                Awaria w danej lokalizacji jest już zgłoszona.
            </p>
        </div>
        <OutageInAreaDialog icon="pi pi-plus" label="Dialog" dialogWidth="600" :showDialog="showDialog"
                            :isPlanned="isPlanned" :outageStart="outageStart" :outageEnd="outageEnd"
                            @sendReport="redirect" @closeReport="close" @hideDialog="hideDialog"/>
        <div class="add-report-location-buttons">
            <EonButton class="turquoise-button"
                       label="Zatwierdź" @click="submit" :disabled="disabledForm"/>
            <!-- <EonButton v-if="outageInArea" class="turquoise-button" label="Zamknij" @click="close" /> -->
            <!-- <EonOutlinedButton v-if="outageInArea" class="turquoise-button"
                               label="Chcę zgłosić inną awarię"
                               extension="50" @click="redirect"/> -->
        </div>
    </div>
</template>
<script>
    import EonRadioButton from "@/components/form/EonRadioButton";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import EonButton from "@/components/form/EonButton";
    import EonAddressAutocomplete from "@/components/form/EonAddressAutocomplete";
    import {
        getAreaByCoordinates as getAreaByCoords,
        getPointElectricityAreaStatus as checkOutageInArea,
    }
        from "@/swagger/vue-api-client";
    import {TimeUtils} from "@/utils/TimeUtils";
    import PlacesAutocomplete from "@/components/form/PlacesAutocomplete";
    import {MapUtils} from "@/utils/MapUtils";
    import OutageInAreaDialog from "./OutageInAreaDialog";

    export default {
        name: "AddUserReportLocation",
        props: {
            googleMapsApi: null,
        },
        emits: [
            "locationToReport",
        ],
        data() {
            return {
                canAddReportOutsideArea: false,
                locationType: null,
                geolocationError: false,
                geolocationInProgress: false,
                geolocationSucess: false,
                outageInArea: false,
                location: {
                    address: {
                        full: null,
                        streetNumber: null,
                        street: null,
                        city: null,
                        district: null,
                        postcode: null,
                    },
                    coordinates: {
                        lat: null,
                        lng: null,
                    },
                },
                // outage: {
                //     start: null,
                //     end: null,
                //     areaName: null,
                //     comment: null,
                //     type: null,
                // },
                outageData: {
                    start: null,
                    end: null,
                    type: "",
                },
                invalidReportLocation: false,
                nearestPlanned: null,
                pickedLocation: false,
                showDialog: false,
                isPlanned: false,
                inExtensiveArea: false,
                extensiveAreaDescription: "",
                outageStart: "",
                outageEnd: "",
            };
        },

        async beforeMount() {
            const lng = Number(this.$route.params.lng);
            const lat = Number(this.$route.params.lat);
            if (!Number.isNaN(lng) && !Number.isNaN(lat)) {
                this.location.coordinates.lng = lng;
                this.location.coordinates.lat = lat;
                await this.getAddress(this.location.coordinates);
                await this.validCoords();
                this.pickedLocation = true;
            }
        },

        created() {
        },

        computed: {
            disabledInput() {
                return (this.locationType !== "address");
            },
            disabledForm() {
                return (this.location.address.full === null
                    || this.location.coordinates.lat === null
                    || this.location.coordinates.lng === null
                    || this.invalidReportLocation);
            },
        },
        methods: {
            updateAddress(address) {
                this.geolocationSucess = false;
                this.location.address.full = address;
            },

            updateAddressDetails(address) {
                const getAddressDetail = function (name) {
                    const fields = address.filter((obj) => obj.types.includes(name));
                    if (fields.length > 0) return fields[0].long_name;
                    return undefined;
                };
                const subpremises = getAddressDetail("subpremise");
                this.location.address.streetNumber = getAddressDetail("street_number")
                    + (subpremises ? "/" + subpremises : "");
                this.location.address.street = getAddressDetail("route");
                this.location.address.city = getAddressDetail("locality");
                this.location.address.district = getAddressDetail("sublocality");
                this.location.address.postcode = getAddressDetail("postal_code");
            },

            updateCoords(coords) {
                this.geolocationSucess = false;
                this.location.coordinates = coords;
                this.validCoords();
            },

            close() {
                this.$router.push({name: "publicMapView"});
            },

            hideDialog() {
                this.showDialog = false;
            },

            isAddressInExceptions() {
                const streetNumber = this.location.address.streetNumber.toLowerCase();
                const street = this.location.address.street.toLowerCase();
                if (street === undefined || streetNumber === undefined) {
                    return false;
                }

                if (street.includes("kasprzaka")) {
                    return ["29", "29a", "29b", "29c", "31", "31a", "31b"].includes(streetNumber);
                }
                if (street.includes("gizów")) {
                    return ["1", "3"].includes(streetNumber);
                }
                if (street.includes("prymasa tysiąclecia")) {
                    return ["83a"].includes(streetNumber);
                }
                if (street.includes("konstruktorska")) {
                    return ["7", "9", "9a"].includes(streetNumber);
                }
                if (street.includes("wolska")) {
                    return ["165"].includes(streetNumber);
                }
                if (street.includes("jana kazimierza")) {
                    return ["28", "30", "32", "32a", "32b"].includes(streetNumber);
                }
                return false;
            },

            async validCoords() {
                if (this.canAddReportOutsideArea) {
                    this.invalidReportLocation = false;
                } else {
                    // exceptions in Stoen area - included on rendered map,
                    // impossible to validate them using getAreaByCoords
                    if (this.isAddressInExceptions()) {
                        this.invalidReportLocation = true;
                        return;
                    }
                    try {
                        await getAreaByCoords({body: this.location.coordinates});
                        this.invalidReportLocation = false;
                    } catch (e) {
                        this.invalidReportLocation = true;
                    }
                }
            },

            mapTempResponseDataToOutage(outage, responseData) {
                outage.start = responseData.outageStart;
                outage.end = responseData.outageEnd;
                outage.areaName = responseData.area.name;
                outage.comment = responseData.comment;
                outage.type = responseData.type;
                outage.distance = responseData.distanceToNearestReport;
            },

            mapResponseDataToOutage(outage, responseData) {
                outage.start = responseData.start;
                outage.end = responseData.end;
                outage.areaName = responseData.areaName;
                outage.comment = responseData.comment;
                outage.type = responseData.type;
            },

            async isOutageInArea() {
                this.outageData = {
                    start: null,
                    end: null,
                    type: "",
                };
                let inArea;
                await checkOutageInArea({
                    body: {
                        lat: this.location.coordinates.lat,
                        lng: this.location.coordinates.lng,
                    },
                })
                    .then((response) => {
                        this.inExtensiveArea = response.data.pointElectricityStatus === "EXTENSIVE";
                        if (this.inExtensiveArea) {
                            this.extensiveAreaDescription = response.data.description;
                        }
                        inArea = response.data.pointElectricityStatus === "PLANNED_OUTAGE"
                            || response.data.pointElectricityStatus === "FAILURE"
                            || response.data.pointElectricityStatus === "EXTENSIVE";
                        if (response.data !== "HAS_ELECTRICITY") {
                            this.outageData.type = response.data === "PLANNED_OUTAGE" ? "PLANNED" : "CONFIRMED";
                        }
                        return inArea;
                    });

                return inArea;
            },

            // isPointInsidePolygon(points, lat, lng, outage) {
            //     const n = points.length;
            //     if (n < 3) {
            //         return false;
            //     }

            //     let isInside = false;
            //     for (let i = 0, j = n - 1; i < n;) {
            //         if ((points[i].lat > lat) !== (points[j].lat > lat)
            //             && (lng < ((points[j].lng - points[i].lng) * (lat - points[i].lat))
            //                 / (points[j].lat - points[i].lat) + points[i].lng)) {
            //             isInside = !isInside;
            //         }

            //         i += 1;
            //         j = i === n ? n - 1 : i - 1;
            //     }

            //     if (isInside) {
            //         if (outage.start !== null && outage.start !== undefined && outage.start !== "") {
            //             this.outageData.start = outage.start;
            //         }
            //         if (outage.end !== null && outage.end !== undefined && outage.end !== "") {
            //             this.outageData.end = outage.end;
            //         }
            //         this.outageData.type = outage.type;
            //     }

            //     return isInside;
            // },

            redirect() {
                // this.$emit("update:location", this.location);
                const lng = this.location.coordinates.lng !== "undefined" ? this.location.coordinates.lng : undefined;
                const lat = this.location.coordinates.lat !== "undefined" ? this.location.coordinates.lat : undefined;
                const num = this.location.address.streetNumber !== "undefined"
                    ? this.location.address.streetNumber : undefined;
                const str = this.location.address.street !== "undefined"
                    ? this.location.address.street : undefined;
                const city = this.location.address.city !== "undefined"
                    ? this.location.address.city : undefined;
                const post = this.location.address.postcode !== "undefined"
                    ? this.location.address.postcode : undefined;
                const district = this.location.address.district !== "undefined"
                    ? this.location.address.district : undefined;
                const {
                    outageInArea, isPlanned, inExtensiveArea, extensiveAreaDescription,
                } = this;

                this.$emit("locationToReport", this.location);
                this.$router.replace({
                    name: "publicAddReportDetails",
                    params: {
                        lng,
                        lat,
                        num,
                        str,
                        city,
                        post,
                        district,
                        outageInArea,
                        isPlanned,
                        inExtensiveArea,
                        extensiveAreaDescription,
                    },
                });
            },

            async submit() {
                await this.isOutageInArea()
                    .then((result) => {
                        this.outageInArea = result;

                        if (this.outageInArea) {
                            this.isPlanned = this.outageData.type === "PLANNED";
                            if (this.outageData.start !== null) {
                                this.outageStart = this.outageData.start !== null ? "od "
                                    + new Date(this.outageData.start).toLocaleString("pl-PL", TimeUtils.options) : "";
                                this.outageEnd = this.outageData.start !== null ? " do "
                                    + new Date(this.outageData.end).toLocaleString("pl-PL", TimeUtils.options) : "";
                            } else {
                                this.outageStart = "";
                                this.outageEnd = "";
                            }
                            this.showDialog = true;
                        }

                        if (!this.outageInArea) {
                            this.redirect();
                        }
                    });
            },

            async getAddress(latlng) {
                const geocoder = MapUtils.getGeocoder();
                await geocoder.geocode({location: latlng}).then(
                    (response) => {
                        if (response.results[0]) {
                            this.location.address.full = response.results[0].formatted_address;
                            this.updateAddressDetails(response.results[0].address_components);
                            this.geolocationSucess = true;
                        } else {
                            this.clearLocation();
                            this.geolocationError = true;
                        }
                    },
                ).catch(() => {
                    this.clearLocation();
                    this.geolocationError = true;
                });
            },

            clearLocation() {
                this.geolocationSucess = false;
                this.geolocationError = false;
                this.location.address.full = null;
                this.location.address.city = null;
                this.location.address.postcode = null;
                this.location.address.streetNumber = null;
                this.location.address.street = null;
                this.location.coordinates.lat = null;
                this.location.coordinates.lng = null;
            },

            setLocation() {

            },

            locateUser() {
                this.geolocationInProgress = true;
                this.clearLocation();
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            this.geolocationError = false;
                            this.location.coordinates.lng = position.coords.longitude;
                            this.location.coordinates.lat = position.coords.latitude;
                            this.validCoords();
                            this.getAddress(this.location.coordinates);
                            this.geolocationInProgress = false;
                        },
                        () => {
                            this.geolocationError = true;
                            this.geolocationInProgress = false;
                        },
                    );
                } else {
                    this.geolocationError = true;
                    this.geolocationInProgress = false;
                }
            },
        },
        components: {
            PlacesAutocomplete,
            EonButton,
            EonOutlinedButton,
            EonRadioButton,
            EonAddressAutocomplete,
            OutageInAreaDialog,
        },
    };
</script>
<style lang="less">
@import "../../../components/assets/less/radio-button.less";
@import "../../../assets/theme/variable.less";

div.pac-container {
    z-index: 10000;
    position: relative;
}
.add-report-location {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    h2.add-report-header {
        color: @color-turquoise;
    }
    .add-report-location-buttons {
        display: flex;
        justify-content: center;
        column-gap: 0.5em;
    }
    .add-report-location-inputs {
        display: flex;
        flex-direction: column;
        row-gap: 1em;
        width: 100%;
        margin-bottom: 1em;
    }
    .geolocation-error {
        color: @tint-bordeaux-00;
    }
    .places-autocomplete {
        margin-left: 1.5em;
    }
}
.eon-dialog.add-report-dialog {
    background-image: url("../../../assets/userAddReportBG.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    .p-dialog-header {
        background-color: rgba(255, 255, 255, 0.75);
    }
    .p-dialog-content {
        background-color: rgba(255, 255, 255, 0.75);
    }
}
.message-title, .message-content {
    text-align: center;
}
</style>
