<template>
    <div class="href-button">
        <a class="href" :href="href" :style="computedColor">
            <svg class="href-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <!-- eslint-disable max-len -->
                <path  d="M5 13h11.17l-4.88 4.88c-.39.39-.39 1.03 0 1.42.39.39 1.02.39 1.41 0l6.59-6.59c.39-.39.39-1.02 0-1.41l-6.58-6.6a.9959.9959 0 00-1.41 0c-.39.39-.39 1.02 0 1.41L16.17 11H5c-.55 0-1 .45-1 1s.45 1 1 1z"/>
                <!-- eslint-enable max-len -->
            </svg>
            <span class="href-label" >{{ label }}</span>
        </a>
    </div>
</template>

<script>

    export default {
        name: "EonHref",
        props: {
            href: {
                type: String,
            },
            label: {
                type: String,
            },
            color: {
                type: String,
                default: "white",
            },

        },
        computed: {
            computedColor() {
                return "color: " + this.color + ";";
            },
        },
    };
</script>

<style lang="less">

.href-button {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.href-icon {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    -moz-user-select: none;
}

.href {
    display: flex;
    font-size: 1rem;
    align-items: center;
}

a {
    text-decoration: none;
    font-weight: 400;

    &:hover {
        text-decoration: underline;
    }

    &:active {
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
    }
}

.href-label {
    margin-left: 5px;
}

</style>
