/* eslint-disable */
export const TimeUtils = {
    MINUTES_TO_MILIS: 60000,
    options: { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "2-digit" },
    announcementOptions: { year: "numeric", month: "numeric", day: "numeric" },

    // Obsługuje zmianę strefy czasowej, ponieważ komponent kalendarz z Primevue tego nie robi.
    adjustTimezone(dateTime) {
        return new Date(dateTime.getTime() - (dateTime.getTimezoneOffset() * this.MINUTES_TO_MILIS));
    },

    // Zmienia request body (DTO lub SearchCriteria) tak, aby można było poprawnie wykonywać operacje
    // dotyczące czasu (wyszukiwanie, zapisywanie do bazy danych).
    adjustRequestBody(request) {
        const newRequest = {...request};
        if (newRequest.outageStart) {
            newRequest.outageStart = this.adjustTimezone(newRequest.outageStart);
        } else if (newRequest.start) {
            newRequest.start = this.adjustTimezone(newRequest.start);
        } else if (newRequest.creationDate) {
            newRequest.creationDate = this.adjustTimezone(newRequest.creationDate);
        }
        if (newRequest.outageEnd) {
            newRequest.outageEnd = this.adjustTimezone(newRequest.outageEnd);
        } else if (newRequest.end) {
            newRequest.end = this.adjustTimezone(newRequest.end);
        }
        if (newRequest.outageAnnouncement) {
            newRequest.outageAnnouncement = this.adjustTimezone(newRequest.outageAnnouncement);
        }
        return newRequest;
    }
};
