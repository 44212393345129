<template>
    <div :class="computedClass">
        <div class="eon-search-criteria">
            <EonInputText width="220" label="Adres" name="address" v-model="internalValue.address"
                          @update:modelValue="update" />
            <EonTimePicker width="220" label="Czas rozpoczęcia" name="picker" v-model="internalValue.start" />
            <EonSelectOneAcceptStatus width="220" itemLabel="name" label="Status akceptacji" :empty-value="true"
                                      v-model="internalValue.acceptStatus" @update:modelValue="update"/>
            <EonButton extension="50" label="Pobierz raport" icon="pi pi-download" @click="downloadReport"/>
        </div>
    </div>
</template>

<script>
    import EonInputText from "@/components/form/EonInputText";
    import EonTimePicker from "@/components/form/EonTimePicker";
    import EonButton from "@/components/form/EonButton";
    import EonSelectOneAcceptStatus from "@/components/form/EonSelectOneAcceptStatus";
    import {FileUtils} from "@/utils/FileUtils";

    export default {
        name: "PlannedOutageReportAcceptSearchCriteria",

        components: {
            EonSelectOneAcceptStatus,
            EonInputText,
            EonTimePicker,
            EonButton,
        },

        props: {
            modelValue: null,
            mapView: {
                default: false,
            },
        },

        emits: ["update:modelValue", "confirmDelete"],

        methods: {
            update() {
                this.$emit("update:modelValue", this.internalValue);
            },
            deleteSelected() {
                this.$emit("confirmDelete");
            },
            downloadReport() {
                // eslint-disable-next-line max-len
                FileUtils.downloadFileUsingGET("Planowane wyłączenie.xlsx", "/api/planned-outage/report");
            },
        },

        data() {
            return {
                // Role: SystemRole,
            };
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            computedClass() {
                return this.mapView ? "map-view" : "table-view";
            },
        },
    };
</script>

<style lang="less" scoped>
@import "../../assets/less/eon-search-criteria.less";
.table-view .eon-search-criteria {
    justify-content: center;
    gap: 25px;
}
</style>
