<template>
    <div class="public-view-bg">
        <h1 class="public-view-header">Planowane wyłączenia</h1>
        <div class="public-view-table">
            <h4 class="public-view-info">Sprawdź planowane wyłączenia w swojej okolicy</h4>
            <h4 class="public-view-info public-view-info__red">
                Planowane wyłączenie może być odwołane bez uprzedzenia
            </h4>
            <PlannedOutageReportSearchCriteriaPublic ref="PlannedOutageReportSearchCriteria" v-model="searchCriteria"
                                                     @update:modelValue="search" :is-public-view="true"/>
            <PlannedOutageReportTablePublic ref="plannedOutageReportTable" :search-criteria="searchCriteria"/>
        </div>
    </div>
</template>

<script>
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import PlannedOutageReportSearchCriteriaPublic from "./components/PlannedOutageReportSearchCriteriaPublic";
    import PlannedOutageReportTablePublic from "./components/PlannedOutageReportTablePublic";

    export default {
        components: {
            PlannedOutageReportSearchCriteriaPublic,
            PlannedOutageReportTablePublic,
        },
        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },
        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.plannedOutageReportTable.search();
            },

            createdBy() {
                this.$router.push({name: "plannedOutageReportCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

            getClearSearchCriteria() {
                return {
                    id: null,
                    area: null,
                    outageStart: null,
                    outageEnd: null,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "@/assets/theme/variable.less";
    .public-view-bg {
        background-image: url("../../assets/plannedBG.jpg");
        background-attachment: fixed;
        background-size: cover;
        padding-top: 4.5em;
    }
    .with-tab-menu-body {
        padding-top: 0;
    }
    .public-view-header {
        color: white;
        margin-top: 40px;
        margin-bottom: 40px;
        margin-left: 10%;
        font-size: 100px;
        text-align: left;
        @media screen and (max-width: 600px) {
            margin-left: 10px;
        }
        @media screen and (max-width: 550px) {
            font-size: 75px;
        }
        @media screen and (max-width: 450px) {
            font-size: 50px;
        }
    }
    .public-view-info {
        color: #00738BFF;
        font-size: 32px;
        text-align: left;
        margin-left: 4%;
        @media screen and (max-width: 550px) {
            font-size: 28px;
        }
        @media screen and (max-width: 450px) {
            font-size: 24px;
        }
        &__red {
            color: @tint-bordeaux-00;
            font-size: 22px;
            @media screen and (max-width: 550px) {
                font-size: 18px;
            }
            @media screen and (max-width: 450px) {
                font-size: 16px;
            }
        }
    }
    .public-view-table {
        background-color: white;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        //margin-bottom: 50px;
        padding-top: 10px;
        min-height: calc(100vh - 306px);
        @media screen and (max-width: 600px) {
            margin: 0 10px;
            width: auto;
        }
    }
</style>
