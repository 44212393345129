<template>
    <!-- eslint-disable max-len -->
    <div class="about-container">
        <div id="first-about-window" class="single-information single-information__first">
            <h2 class="single-information-header single-information-header__white">Co to jest Portal planowanych wyłączeń i awarii?</h2>
            <div class="single-information-text single-information-text__left">
                <p>
                    Portal planowanych wyłaczeń i awarii to narzędzie, dzięki któremu w prosty sposób zgłosisz awarię sieci elektroenergetycznej. Sprawdzisz również, w jakim rejonie aktualnie występują potwierdzone awarie i wyłączenia dostaw energii z powodu prowadzonych prac technicznych.
                </p>
            </div>
            <div class="single-information-text single-information-text__right">
                <p>
                    Portal obsługuje sieć elektroenergetyczną Stoen Operator Sp. z o.o., obejmującą Warszawę i kilka gmin ościennych.
                </p>
            </div>
        </div>
        <div id="second-about-window" class="single-information single-information__second">
            <h2 class="single-information-header single-information-header__red">Jak to działa?</h2>
            <div class="left-right-text">
                <div class="single-information-text single-information-text__left">
                    <p>
                        Jeśli nie masz prądu sprawdź <a href="/public" class="to-map-link">na mapie</a>, czy w Twojej okolicy nie ma zgłoszonej lub potwierdzonej awarii albo planowanego wyłączenia.
                    </p>
                    <p>
                        Jeśli nie widzisz oznaczeń w swojej okolicy, zgłoś brak energii za pomocą formularza.
                    </p>
                    <p>
                        <router-link :to="{name: 'publicAddReportLocation'}">
                            <EonButton extension="50" label="Zgłoś awarię" icon="pi pi-plus"/>
                        </router-link>
                    </p>
                </div>
                <div class="single-information-text single-information-text__right">
                    <p>
                        Zgłoszenia oznaczamy na mapie w formie graficznej:
                    </p>
                    <p class="icon-paragraph">
                        to zgłoszenie użytkownika, które jest w trakcie sprawdzania przez naszych specjalistów,
                    </p>
                    <p class="icon-paragraph">
                        to potwierdzona przez naszych specjalistów awaria, która jest w trakcie usuwania,
                    </p>
                    <p class="icon-paragraph">
                        to planowane wyłączenie dostawy energii związane z potrzebą przeprowadzenia prac technicznych.
                    </p>
                    <p style="margin-left: -75px">
                        Po kliknięciu w dane oznaczenie zobaczysz dokładny adres zdarzenia.
                    </p>
                    <div class="single-information-text single-information-text__middle single-information-text__middle-desktop">
                        <img src="../../assets/clusterIcons.svg" alt="przykładowe ikony zbiorcze" />
                        <p class="font-size-change">
                            Jeśli Twój obszar jest objęty rozległą awarią bądź planowanym wyłączeniem, Twoje zgłoszenie nie pojawi się na mapie jako nowa pozycja, tylko zostanie uwzględnione w widocznych już oznaczeniach obejmujących większy obszar.
                        </p>
                    </div>
                </div>
            </div>
            <div class="single-information-text single-information-text__middle single-information-text__middle-mobile">
                <img src="../../assets/clusterIcons.svg" alt="przykładowe ikony zbiorcze" />
                <p>
                    Jeśli Twój obszar jest objęty rozległą awarią bądź planowanym wyłączeniem, Twoje zgłoszenie nie pojawi się na mapie jako nowa pozycja, tylko zostanie uwzględnione w widocznych już oznaczeniach obejmujących większy obszar.
                </p>
            </div>
        </div>
        <div id="third-about-window" class="single-information single-information__third">
            <h2 class="single-information-header single-information-header__white">Pamiętaj!</h2>
            <div class="single-information-text single-information-text__leftup">
                <p>
                    Prezentowane na mapie zgłoszenia użytkowników, obszary potwierdzonych awarii i planowanych wyłączeń oraz przewidywane czasy przywrócenia zasilania w energię elektryczną mają charakter orientacyjny i mogą ulec zmianie.
                </p>
            </div>
            <div class="single-information-text single-information-text__leftdown">
                <p>
                    Do obsługi zgłoszeń awarii sieci elektroenergetycznej potrzebujemy Twoich danych osobowych. <br>
                    <a href="/public/O20. OI www Formularz zgłoszenia awarii_Portal awarii_Stoen_2023_03_01.pdf"
                       class="find-out-why-button" target="_blank">
                        <i class="pi pi-arrow-right find-out-why-button-icon" />
                        <span class="hover-underline">Dowiedz się dlaczego</span>
                    </a>
                </p>
            </div>
            <div class="single-information-text single-information-text__right">
                <p class="warning-text">
                    Jeśli widzisz zagrożenie życia lub zdrowia, nie korzystaj z formularza, tylko jak najszybciej powiadom nas o tym telefonicznie pod numerem
                </p>
                <p class="warning-number">
                    <img src="@/assets/phone.svg" alt="ikona telefonu" />
                    22 821 31 31
                </p>
            </div>
        </div>
    </div>
    <!-- eslint-enable max-len -->
</template>

<script>
    import PrimeButton from "primevue/button";
    import EonButton from "@/components/form/EonButton";

    export default {

        data() {
            return {
            };
        },

        beforeMount() {
        },

        methods: {
        },

        components: {
            EonButton,
            PrimeButton,
        },
    };
</script>

<style lang="less" scoped>
    @import "../../assets/theme/variable.less";
    .about-container {
        padding-top: 4.5rem;
        .single-information {
            height: 100vh;
            .hover-underline:hover {
                text-decoration: underline;
            }
            @media screen and (min-width: 1101px) and (max-height: 840px) {
                height: 840px;
            }
            @media screen and (max-width: 1100px) {
                height: auto;
            }
            .single-information-header {
                margin: 0;
                font-size: 100px;
                text-align: right;
                width: 580px;
                &__white {
                    color: white;
                }
                &__red {
                    padding-bottom: 50px;
                    color: @color-red;
                }
                @media screen and (max-width: 650px) {
                    font-size: 56px;
                    text-align: left;
                }
            }
            &__first {
                height: auto;
                @media screen and (min-width: 1101px) {
                    height: calc(100vh - 50px);
                }
                @media screen and (min-width: 1101px) and (max-height: 740px) {
                    height: 690px;
                }
                background-image: url("../../assets/aboutFirstBG.jpg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                position: relative;
                .single-information-header {
                    position: absolute;
                    width: 80%;
                    left: 140px;
                    text-align: left;
                    font-size: 90px;
                    top: 12%;
                    @media screen and (max-width: 1500px) {
                        left: 80px;
                    }
                    @media screen and (max-width: 1400px) {
                        font-size: 75px;
                    }
                    @media screen and (max-width: 650px) {
                        font-size: 56px;
                    }
                }
                .single-information-text {
                    background-color: @tint-bordeaux-00;
                    font-size: 24px;
                    @media screen and (max-width: 1390px) {
                        font-size: 18px;
                    }
                    font-weight: 500;
                    color: white;
                    text-align: left;
                    position: absolute;
                    &__left {
                        width: 615px;
                        height: 332px;
                        padding: 40px 60px;
                        @media screen and (max-width: 420px) {
                            padding: 20px 30px;
                        }
                        top: 50%;
                        left: 140px;
                        @media screen and (max-width: 1500px) {
                            left: 100px
                        }
                        @media screen and (max-width: 1390px) {
                            height: 250px;
                            width: 450px;
                        }
                    }
                    &__right {
                        width: 400px;
                        height: 332px;
                        padding: 40px 40px;
                        @media screen and (max-width: 1500px) {
                            padding: 20px 20px;
                        }
                        top: 50%;
                        left: 960px;
                        @media screen and (max-width: 1500px) {
                            left: 800px;
                        }
                        @media screen and (max-width: 1390px) {
                            height: 250px;
                            width: 250px;
                        }
                    }
                }
                @media screen and (max-width: 1100px) {
                    padding: 40px 50px 40px 50px;
                    .single-information-header {
                        position: relative;
                        left: 0;
                        top: 0;
                        width: fit-content;
                        margin-bottom: 40px;
                    }
                    .single-information-text {
                        &__left, &__right {
                            position: relative;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: auto;
                        }
                        &__left {
                            margin-bottom: 40px;
                        }
                    }
                }
                @media screen and (max-width: 600px) {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
            &__second {
                padding: 40px 50px 100px 140px;
                position: relative;
                @media screen and (max-width: 1500px) {
                    padding-left: 100px
                }
                @media screen and (max-width: 1100px) {
                    padding-left: 50px;
                    padding-bottom: 40px;
                }
                @media screen and (max-width: 600px) {
                    padding-left: 20px;
                    padding-right: 20px;
                }
                @media screen and (min-width: 1100px) and (max-height: 850px) {
                    margin-bottom: 10%;
                }
                .single-information-header {
                    width: 550px;
                }
                .left-right-text {
                    display: flex;
                    height: 600px;
                    .single-information-text {
                        &__left {
                            width: 39%;
                        }
                        &__right {
                            width: 61%;
                            height: 480px;
                            @media screen and (min-width: 1920px) and (min-height: 1080px) {
                                height: 640px;
                            }
                        }
                    }
                }
                .single-information-text {
                    width: 500px;
                    &__left {
                        font-size: 24px;
                        @media screen and (max-width: 1390px) {
                            font-size: 18px;
                        }
                        font-weight: 500;
                        text-align: left;
                        p {
                            width: 75%;
                            @media screen and (max-width: 1100px) {
                                width: 100%;
                            }
                            .add-report-button {
                                border: none;
                                background-color: white;
                                color: @secondary-color;
                                font-size: 30px;
                                font-weight: bold;
                                margin-top: 30px;
                                &-icon {
                                    color: @secondary-color;
                                    font-size: 30px;
                                    font-weight: bold;
                                    margin-right: 30px;
                                }
                                @media screen and (max-width: 400px) {
                                    font-size: 24px;
                                    &-icon {
                                        font-size: 24px;
                                    }
                                }
                            }
                        }
                        .to-map-link, .to-map-link:visited {
                            color: @secondary-color;
                            font-weight: 500;
                        }
                        .to-map-link:hover {
                            text-decoration: underline;
                        }
                    }
                    &__right {
                        color: white;
                        background-color: @secondary-color;
                        text-align: left;
                        padding: 30px 60px 30px 120px;
                        @media screen and (max-width: 420px) {
                            padding-right: 20px;
                        }
                        display: grid;
                        position: relative;
                        .icon-paragraph {
                            margin: 0;
                        }
                        p:nth-of-type(1) {
                            font-size: 32px;
                            @media screen and (max-width: 1390px) {
                                font-size: 22px;
                            }
                            @media screen and (max-width: 800px) {
                                text-align: left;
                                margin-left: -70px;
                            }
                            font-weight: bold;
                            margin: 0;
                        }
                        p:nth-of-type(2) {
                            &::after {
                                content: "";
                                background-image: url("@/assets/notification_bell_white.svg");
                                background-repeat: no-repeat;
                                background-size: contain;
                                width: 48px;
                                height: 48px;
                                position: absolute;
                                left: -80px;
                                top: 50%;
                                transform: translateY(-75%);
                                @media screen and (min-width: 1920px) and (min-height: 1080px) {
                                    top: 30%;
                                }
                            }
                        }
                        p:nth-of-type(3) {
                            &::after {
                                content: "";
                                background-image: url("@/assets/lightning_white.svg");
                                background-repeat: no-repeat;
                                background-size: contain;
                                width: 48px;
                                height: 48px;
                                position: absolute;
                                left: -80px;
                                top: 50%;
                                transform: translateY(-75%);
                                @media screen and (min-width: 1920px) and (min-height: 1080px) {
                                    top: 30%;
                                }
                            }
                        }
                        p:nth-of-type(4) {
                            &::after {
                                content: "";
                                background-image: url("@/assets/pylon_white.svg");
                                background-repeat: no-repeat;
                                background-size: contain;
                                width: 48px;
                                height: 48px;
                                position: absolute;
                                left: -80px;
                                top: 50%;
                                transform: translateY(-75%);
                                @media screen and (min-width: 1920px) and (min-height: 1080px) {
                                    top: 30%;
                                }
                            }
                        }
                        p:nth-of-type(5) {
                            margin: 0;
                            font-weight: 900;
                            @media screen and (max-width: 800px) {
                                text-align: left;
                                margin-left: -70px;
                            }
                        }
                        p {
                            font-size: 24px;
                            @media screen and (max-width: 1390px) {
                                font-size: 18px;
                            }
                            font-weight: 700;
                            position: relative;
                            &.font-size-change {
                                font-size: 24px;
                            }
                        }
                    }
                    &__middle {
                        color: white;
                        background-color: @tint-bordeaux-00;
                        width: 1000px;
                        height: 170px;
                        position: absolute;
                        bottom: -28%;
                        @media screen and (min-width: 1920px) and (min-height: 1080px) {
                            bottom: -25%;
                        }
                        left: -64%;
                        display: flex;
                        align-items: center;
                        gap: 40px;
                        padding: 20px 40px;
                        &-mobile {
                            display: none;
                            @media screen and (max-width: 600px) {
                                padding-left: 20px;
                                padding-right: 20px;
                            }
                        }
                        @media screen and (max-width: 420px) {
                            flex-direction: column;
                        }
                        .icon-box {
                            width: 128px;
                            height: 128px;
                            border-radius: 32px;
                            background-color: white;
                            .single-icon-element {
                                .icon-element {
                                    width: 32px;
                                    height: 32px;
                                }
                            }
                        }
                        p {
                            width: 80%;
                            font-size: 24px;
                            @media screen and (max-width: 1390px) {
                                font-size: 18px;
                            }
                            font-weight: bold;
                            text-align: left;
                        }
                    }
                }
                @media screen and (max-width: 1100px) {
                    .single-information-header {
                        width: fit-content;
                    }
                    .left-right-text {
                        flex-direction: column;
                        height: auto;
                        .single-information-text {
                            &__left {
                                width: 100%;
                                height: auto;
                            }
                            &__right {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                    .single-information-text {
                        &__right {
                            p:nth-of-type(2), p:nth-of-type(3), p:nth-of-type(4) {
                                &::after {
                                    width: 36px;
                                    height: 36px;
                                    top: 60%;
                                    left: -60px;
                                }
                                max-width: 570px;
                                margin-top: 10px;
                                margin-bottom: 10px;
                            }
                        }
                        &__middle {
                            &-desktop {
                                display: none;
                            }
                            &-mobile {
                                display: flex;
                                position: relative;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                height: auto;
                                margin-top: 40px;
                            }
                        }
                    }
                }
            }
            &__third {
                padding: 100px 50px 100px 140px;
                @media screen and (max-width: 1500px) {
                    padding-left: 100px;
                }
                background-image: url("../../assets/aboutThirdBG.jpg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                position: relative;
                .single-information-header {
                    width: 390px;
                }
                .single-information-text {
                    &__right {
                        background-color: @tint-bordeaux-00;
                        width: 600px;
                        @media screen and (max-width: 1390px) {
                            width: 460px;
                        }
                        height: 100vh;
                        @media screen and (min-width: 1101px) and (max-height: 840px) {
                            height: 840px;
                        }
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: 100px 80px 100px 80px;
                        @media screen and (max-width: 600px) {
                            padding: 40px 20px 40px 20px;
                        }
                        @media screen and (max-width: 1100px) {
                            margin-top: 20px;
                        }
                        .warning {
                            &-text, &-number {
                                color: white;
                                font-size: 32px;
                                @media screen and (max-width: 1390px) {
                                    font-size: 26px;
                                }
                                font-weight: bold;
                            }
                            &-text {
                                text-align: left;
                            }
                            &-number {
                                text-align: center;
                                position: relative;
                                margin-top: 50px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                img {
                                    width: 32px;
                                    height: 32px;
                                }
                                // &::after {
                                //     content: "";
                                //     background-image: url("@/assets/phone.svg");
                                //     height: 32px;
                                //     width: 32px;
                                //     background-position: center;
                                //     background-repeat: no-repeat;
                                //     background-size: cover;
                                //     position: absolute;
                                //     left: 90px;
                                //     top: 6px;
                                //     @media screen and (max-width: 1390px) {
                                //         height: 32px;
                                //         width: 32px;
                                //         top: 2px;
                                //         left: 40px;
                                //     }
                                // }
                            }
                        }
                    }
                    &__leftup, &__leftdown {
                        background-color: @secondary-color;
                        width: 425px;
                        // @media screen and (max-width: 1500px) {
                        //     width: 425px;
                        // }
                        color: white;
                        font-size: 24px;
                        @media screen and (max-width: 1390px) {
                            font-size: 18px;
                            width: 400px;
                        }
                        font-weight: bold;
                        text-align: left;
                        padding: 20px 40px;
                        position: absolute;
                    }
                    &__leftup {
                        height: 300px;
                        bottom: 370px;
                    }
                    &__leftdown {
                        height: 250px;
                        bottom: 90px;
                        .find-out-why-button {
                            color: white;
                            border: none;
                            background-color: transparent;
                            font-size: 24px;
                            font-weight: bold;
                            margin-top: 20px;
                            display: flex;
                            @media screen and (max-width: 1390px) {
                                font-size: 18px;
                            }
                            &-icon {
                                font-size: 32px;
                                font-weight: bold;
                                margin-right: 20px;
                                @media screen and (max-width: 1390px) {
                                    font-size: 24px;
                                }
                            }
                        }
                    }
                    @media screen and (max-width: 1390px) {
                        &__leftup {
                            height: 200px;
                        }
                        &__leftdown {
                            height: 180px;
                        }
                    }
                    @media screen and (min-width: 1100px) and (max-height: 850px) {
                        &__leftup {
                            bottom: 320px;
                        }
                        &__leftdown {
                            bottom: 50px;
                        }
                    }
                }
                @media screen and (max-width: 1100px) {
                    padding: 50px 40px 50px 40px;
                    .single-information-header {
                        margin-bottom: 40px;
                        width: fit-content;
                    }
                    .single-information-text {
                        &__right {
                            width: 100%;
                            height: auto;
                            position: relative;
                            .warning {
                                &-number {
                                    width: fit-content;
                                    margin: 0 auto;
                                    // &::after {
                                    //     top: 0;
                                    //     left: -40px;
                                    // }
                                }
                            }
                        }
                        &__leftup, &__leftdown {
                            position: relative;
                            bottom: 0;
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                @media screen and (max-width: 600px) {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
    }
</style>
