<template>
    <div>
        <Toast/>
        <DataTable ref="plannedOutageReportTable" :value="plannedOutageReports" :lazy="true"
                   :paginator="true"
                   v-model:rows="searchParams.page.limit" v-model:first="searchParams.page.offset"
                   :totalRecords="totalRecords" :loading="loading" :search-criteria="searchCriteria"
                   @page="onPage" @sort="onPage" removableSort v-model:selection="selectedOutages"
                   class="outages-table public-outages-table " :rows="10" :rowsPerPageOptions="[5,10,20,50]"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink
                   LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Elementy na stronie"
                   responsiveLayout="scroll" style="padding-bottom: 50px;"
        >
            <Column header="Adres" style="min-width: 400px; max-width: 1000px;">
                <template #body="slotProps">
                    <Accordion class="planned-outages-table-tab">
                        <AccordionTab>
                            <template #header>
                                <ul>
                                    <li v-for="address, index in slotProps.data.addresses" :key="index + tableKey">
                                        <div class="list-address-streetName">
                                            {{ address.streetName.toUpperCase() }}
                                        </div>
                                        <div class="list-address-houseNumbers">
                                            {{ address.houseNumbers.toUpperCase() }}
                                        </div>
                                    </li>
                                </ul>
                            </template>
                            <p class="planned-outage-comment__header">
                                Przyczyna wyłączenia:
                            </p>
                            <p class="planned-outage-comment">
                                {{slotProps.data.comment !== null && slotProps.data.comment !== undefined ?
                                    slotProps.data.comment : "Brak informacji."}}
                            </p>
                            <p class="planned-outage-comment__info">
                                Planowane wyłączenie może być odwołane bez uprzedzenia.
                            </p>
                        </AccordionTab>
                    </Accordion>
                </template>
            </Column>
            <Column field="outageStart" header="Planowany czas rozpoczęcia" :sortable="true"/>
            <Column field="outageEnd" header="Planowany czas zakończenia" :sortable="true"/>
            <template #loading>
                Ładowanie planowanych wyłączeń. Proszę czekać.
            </template>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
</template>

<script>

    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Toast from "primevue/toast";
    import Accordion from "primevue/accordion";
    import AccordionTab from "primevue/accordiontab";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {TimeUtils} from "@/utils/TimeUtils";
    import {
        searchPlannedOutageCompressedReportCount as searchPlannedOutageReportCount,
        searchPlannedOutageCompressedReportPublic as searchPlannedOutageReportPublicCompressed,
    } from "@/swagger/vue-api-client";

    export default {
        name: "PlannedOutageReportTablePublic",

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        data() {
            return {
                loading: false,
                totalRecords: 0,
                plannedOutageReports: [],
                selectedOutages: null,
                tableKey: 0,
            };
        },

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;
                console.log(this.searchCriteria);

                searchPlannedOutageReportPublicCompressed({
                    body: this.searchCriteria,
                })
                    .then((response) => {
                        this.plannedOutageReports = response.data;
                        this.plannedOutageReports.forEach((report) => {
                            report.outageStart = new Date(report.outageStart)
                                .toLocaleString("pl-PL", TimeUtils.options);
                            report.outageEnd = new Date(report.outageEnd)
                                .toLocaleString("pl-PL", TimeUtils.options);
                        });
                        this.tableKey += 1;
                        this.loading = false;
                    });

                SearchCriteria.updateUrl(this);
            },

            updateTotalRecords() {
                searchPlannedOutageReportCount({body: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },
        },

        components: {
            Column,
            DataTable,
            Toast,
            Accordion,
            AccordionTab,
        },
    };
</script>

<style lang="less">
@import "../../../components/assets/less/datatable.less";
@import "../../assets/less/eon-table.less";
@import "../../../assets/theme/variable.less";

.deleted-row {
    background-color: @tint-grey-ultra-ultralight !important;
}

.public-outages-table {
    .p-datatable-tbody {
        td:first-child {
            padding: 0 0 0 10px !important;
        }
        tr {
            border-bottom: none !important;
            position: relative;
            &::after {
                content: "";
                border-bottom: 2px solid @color-grey-light;
                width: 96%;
                display: block;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
            &:first-child::after {
                border: none;
            }
        }
    }
    .p-sortable-column:not(.p-highlight):hover, .p-sortable-column:focus {
        background-color: @color-grey-light !important;
    }
    ul {
        padding-left: 0;
        padding-right: 10px;
        li {
            list-style: none;
            display: flex;
            gap: 10px;
            .list-address {
                &-houseNumbers {
                    flex: 1;
                }
            }
        }
    }
}

.planned-outages-table-tab {
    .p-accordion-tab {
        box-shadow: none !important;
    }
    .p-accordion-tab-active {
        margin-bottom: 0 !important;
        & .p-accordion-header a:focus {
            text-decoration: none;
        }
    }
    .p-accordion-tab .p-accordion-header a {
        padding-top: 10px;
        padding-bottom: 10px;
        &:hover, &:active, &:focus {
            text-decoration: none;
        }
        &:focus {
            z-index: 0;
        }
        & .p-accordion-toggle-icon {
            margin-right: -8px;
        }
        & .pi-chevron-right::before, & .pi-chevron-down::before {
            color: @secondary-color;
        }
    }
    .planned-outage-comment {
        margin-top: 0;
        &__info {
            margin-bottom: 0;
            font-weight: bold;
            color: @primary-color;
        }
        &__header {
            font-weight: bold;
        }
    }
}
</style>
