<template>
    <div class="places-autocomplete-field" :style="'width: ' + width + 'px;'" id="address-autocomplete">
        <input
            class="places-autocomplete-input"
            id="outage-address"
            :name="name"
            required
            autocomplete="off"
            :placeholder="placeholder"
            :disabled="disabled"
        />
        <i style="margin-left: -1px" class="places-autocomplete-icon pi pi-search"/>
    </div>
</template>
<script>

    export default {
        name: "PlacesAutocomplete",
        props: {
            googleMapsApi: null,
            name: {
                type: String,
            },
            placeholder: {
                type: String,
            },
            width: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            searchType: {
                default: "address",
            },
        },
        data() {
            return {
                autocomplete: null,
            };
        },
        emits: ["updateAddress", "updateCoords", "updateAddressDetails"],
        mounted() {
            if (this.googleMapsApi) {
                const inputField = document.querySelector("#outage-address");
                this.autocomplete = new this.googleMapsApi.places.Autocomplete(inputField, {
                    fields: ["formatted_address", "geometry", "address_components"],
                    types: [this.searchType],
                });
                this.autocomplete.addListener("place_changed", this.setPlace);
            }
        },
        watch: {
            googleMapsApi() {
                const inputField = document.querySelector("#outage-address");
                this.autocomplete = new this.googleMapsApi.places.Autocomplete(inputField, {
                    fields: ["formatted_address", "geometry", "address_components"],
                    types: [this.searchType],
                    componentRestrictions: {country: "pl"},
                    bounds: {
                        north: 52.38333,
                        south: 52.08333,
                        east: 21.28333,
                        west: 20.84583,
                    },
                });
                this.autocomplete.addListener("place_changed", this.setPlace);
            },
        },
        methods: {
            coorFromAddress(googleMapsApi, address) {
                this.warsawAddress = address.toUpperCase();
                if (!(this.warsawAddress.indexOf("WARSZAWA") > -1)) {
                    this.warsawAddress += " Warszawa";
                }
                if (this.warsawAddress.indexOf("(W)") > -1) {
                    this.warsawAddress = this.warsawAddress.replace("(W)", "Wesoła");
                }
                if (googleMapsApi) {
                    this.geocoder = new googleMapsApi.Geocoder();
                    this.geocoder.geocode({address: this.warsawAddress}, (results) => {
                        const place = {...results[0]};
                        this.$emit("updateAddress", place?.formatted_address);
                        this.$emit("updateAddressDetails", place?.address_components);
                        if (place.geometry) {
                            this.$emit("updateCoords", {
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng(),
                            });
                        }
                    });
                }
            },
            setPlace() {
                const place = this.autocomplete.getPlace();
                if (place.name) {
                    this.coorFromAddress(this.googleMapsApi, place.name);
                    return;
                }
                this.$emit("updateAddress", place?.formatted_address);
                this.$emit("updateAddressDetails", place?.address_components);
                if (place.geometry) {
                    this.$emit("updateCoords", {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    });
                }
            },
        },
    };

</script>

<style lang="less">
@import "../../assets/theme/variable.less";

.pac-logo:after {
    display: none;
}

.pac-item {
    font-size: 12px;
    color: #d9d9d9;
    font-family: Eon Brix Sans Web, Roboto, sans-serif;
}
.pac-icon {
    display: none;
}

.places-autocomplete-field {
    display: flex;
    .places-autocomplete-input {
        &::placeholder {
            color: @color-neutral-800;
            font-size: 15px;
        }
        font-family: Eon Brix Sans Web, Roboto, sans-serif;
        height: 2.5rem;
        padding: 0 1em 0 1em;
        border: 2px solid @color-neutral-500;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: none;
        margin: 0;
        width: calc(100% - 48px);
        height: 48px;
        &:focus {
            border: 2px solid @color-turquoise;
            border-right: none;
        }
        &:not(:disabled) {
            &:hover {
                border: 2px solid @color-turquoise;
                border-right: none;
            }
            &:hover + .places-autocomplete-icon {
                border: 2px solid @color-turquoise;
                border-left: none;
                color: @color-grey-middle-active;
            }
        }
        &:focus-visible {
            outline: none;
        }
        &:disabled {
            background: @color-grey-ultralight;
        }
    }
    .places-autocomplete-icon {
        background: @color-grey-light;
        border: 2px solid @color-neutral-500;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-left: none;
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: @color-grey-middle-active;
    }
    &:focus-within {
        .places-autocomplete-icon {
            border: 2px solid @color-turquoise;
            border-left: none;
            color: @color-grey-middle-active;
        }
    }
}
</style>
