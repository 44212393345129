/* eslint-disable */
import axios from 'axios'

export const FileUtils = {

    forceFileDownload(response, fileName) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },

    downloadFile(query, fileName, url) {
        axios({
            url: process.env.VUE_APP_API_URL + url,
            method: 'post',
            responseType: 'blob',
            data: query,
        }).then((response) => {
            this.forceFileDownload(response, fileName);
        });
    },

    downloadFileUsingGET(fileName, url) {
        axios({
            url: process.env.VUE_APP_API_URL + url,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            this.forceFileDownload(response, fileName);
        });
    },
};
