<template>
    <div class="add-report-completed-field">
        <h2> {{this.text}} </h2>
        <p> W przypadku dodatkowych pytań zadzwoń do nas. </p>
        <div class="add-report-completed-buttons">
            <a v-if="isMobileDeviceOrMac()" href="tel:22-821-31-31" >
                <span class="screenreader-text">Numer na infolinię: 22 821 31 31 </span>
                <EonOutlinedButton class="turquoise-button" label="22 821 31 31" icon="pi pi-phone" extension="20"/>
            </a>
            <span v-else>
                <span class="screenreader-text">Numer na infolinię: 22 821 31 31 </span>
                <EonOutlinedButton class="turquoise-button" label="22 821 31 31" icon="pi pi-phone" extension="20"/>
            </span>
            <EonButton class="turquoise-button" label="Zamknij" @click="redirect"/>
        </div>
    </div>
</template>
<script>
    import EonButton from "@/components/form/EonButton";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";

    export default {
        name: "UserReportAddCompleted",
        components: {EonButton, EonOutlinedButton},
        data() {
            return {
                text: "Dziękujemy! Zajmiemy się Twoim zgłoszeniem.",
            };
        },
        beforeMount() {
            const {alreadyCompleted} = this.$route.params;
            if (alreadyCompleted !== undefined) {
                this.text = "Twoje zgłoszenie zostało już zarejestrowane.";
            }
        },
        methods: {
            redirect() {
                this.$cookies.set("instantPoll", true, "30s");
                const {lng} = this.$route.params;
                const {lat} = this.$route.params;
                if (lng !== undefined && lat !== undefined) {
                    this.$router.push({path: `/public/${lng}/${lat}`});
                } else {
                    this.$router.push("/public");
                }
            },
            isMobileDeviceOrMac() {
                const os = this.getOS();
                return os === "macos" || os === "ios" || os === "android";
            },
            getOS() {
                const userAgent = window.navigator.userAgent.toLowerCase();
                const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
                const windowsPlatforms = /(win32|win64|windows|wince)/i;
                const iosPlatforms = /(iphone|ipad|ipod)/i;
                let os = null;

                if (macosPlatforms.test(userAgent)) {
                    os = "macos";
                } else if (iosPlatforms.test(userAgent)) {
                    os = "ios";
                } else if (windowsPlatforms.test(userAgent)) {
                    os = "windows";
                } else if (/android/.test(userAgent)) {
                    os = "android";
                } else if (!os && /linux/.test(userAgent)) {
                    os = "linux";
                }

                return os;
            },
        },
    };
</script>
<style lang="less" scoped>
@import "../../../assets/theme/variable.less";
.add-report-completed-field {
    width: 100%;
    padding-left: 3%;
    h2 {
        color: @color-turquoise;
    }
    .add-report-completed-buttons {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
}
.screenreader-text {
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    top: -10px;
}
</style>
