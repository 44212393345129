<template>
    <div class="admin-layout-wrapper">
        <EonTabMenu :leftItems="menuLeftItems" :rightItems="menuRightItems"/>
        <div class="with-tab-menu-body">
            <router-view class="admin-layout-body"/>
        </div>
    </div>
</template>

<script>
    import EonTabMenu from "@/components/form/EonTabMenu";

    export default {
        name: "LayoutAdmin",
        components: {EonTabMenu},
        data() {
            return {
                myHeight: "",
                menuLeftItems: [
                    {
                        label: "Użytkownicy",
                        to: "/admin/users",
                    },
                    {
                        label: "Obszary",
                        to: "/admin/areas",
                    },
                    {
                        label: "Przerwa serwisowa",
                        to: "/admin/maintenance",
                    },
                ],
                menuRightItems:
                    [
                        {
                            icon: "pi pi-user",
                            label: "Moje konto",
                            to: "/admin/user/profile",
                        },
                        {
                            label: "Wyloguj",
                            icon: "pi pi-sign-out",
                            to: "/login",
                            onClick: this.logout,
                        },
                    ],
            };
        },
        methods: {
            logout() {
                localStorage.removeItem("token");
                localStorage.removeItem("userId");
                localStorage.removeItem("role");
                localStorage.removeItem("fullName");
                localStorage.removeItem("area");
                localStorage.removeItem("email");
                localStorage.removeItem("phone");
            },
        },
        mounted() {
            if (!localStorage.getItem("token")) {
                this.$router.push({name: "login"});
            }
        },
    };
</script>

<style lang="less" scoped>
@import "../assets/less/layout-admin.less";
@import "../assets/less/view-with-tab-menu.less";

</style>
