<template>
    <div class="cookies-field">
        <PrimeDialog class="cookies" :id="name" v-model:visible="computedDisplay" :draggable="false"
                     :closable="false" :modal="true" :style="'width: ' + dialogWidth + 'px;'">
            <div class="cookies-logo">
                <img src="../../assets/logoStoenRed.svg" alt="Logo"/>
            </div>
            <h2 class="cookie-message-title"> {{ $t('message.cookies.title') }} </h2>
            <p class="cookie-message"> {{ $t('message.cookies.message') }} </p>
            <p class="cookie-message">{{ $t('message.cookies.policyLinkPref') }}
                <a :href="cookiesPolicyUrl">
                    {{ $t('message.cookies.policyLink') }}
                </a>{{ $t('message.cookies.policyLinkSuf') }}
            </p>
            <div class = "cookie-buttons">
                <EonButton class="cookie-button  inverted turquoise" @click="later"
                           :label="$t('message.cookies.acceptNecessary')" />
                <EonButton class="cookie-button" @click="close" :label="$t('message.cookies.accept')" />
            </div>
        </PrimeDialog>
    </div>
</template>
<script>
    import PrimeDialog from "primevue/dialog";
    import EonButton from "@/components/form/EonButton";
    import VueCookies from "vue-cookies";

    export default {
        name: "EonCookies",
        props: {
            name: {
                type: String,
            },
            buttonLabel: {
                type: String,
            },
            dialogWidth: {
                type: String,
            },
        },
        components: {PrimeDialog, EonButton, VueCookies},
        data() {
            return {
                value: null,
                display: true,
                cookiesPolicyUrl: process.env.VUE_APP_COOKIES_URL,
            };
        },
        mounted() {
            if (this.computedDisplay) {
                document.body.classList.add("modal-blur");
            } else if (this.$cookies.isKey("cookies")) {
                this.addGoogleAnalytics();
            }
        },
        methods: {
            close() {
                this.$cookies.set("cookies", true, "1y");
                this.addGoogleAnalytics();
                this.display = false;
                document.body.classList.remove("modal-blur");
            },
            later() {
                this.$cookies.set("cookies_reduced", true, "90d");
                this.display = false;
                document.body.classList.remove("modal-blur");
            },
            addGoogleAnalytics() {
                const script1 = document.createElement("script");
                script1.async = true;
                script1.src = "https://www.googletagmanager.com/gtag/js?id=G-8FXC6HB48L";
                const script2 = document.createElement("script");
                const content = document.createTextNode(
                    "window.dataLayer = window.dataLayer || [];"
                        + "function gtag(){dataLayer.push(arguments);}"
                        + "gtag('js', new Date());"
                        + "gtag('config', 'G-8FXC6HB48L');",
                );
                script2.appendChild(content);
                const head = document.getElementsByTagName("head")[0];
                head.appendChild(script1);
                head.appendChild(script2);
            },
        },
        computed: {
            computedDisplay() {
                return (this.display && !this.$cookies.isKey("cookies")
                    && !this.$cookies.isKey("cookies_reduced"));
            },
        },
    };
</script>

<style lang="less">
@import "../assets/less/dialog.less";
@import "../assets/less/eon-cookies.less";
</style>
