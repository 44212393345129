<template>
    <!-- eslint-disable max-len -->
    <div class="faq-wrapper">
        <div class="faqBG">
            <div class="faq-header-container">
                <div class="faq-header-container__text">
                    <h1>Najczęściej zadawane pytania</h1>
                </div>
                <div class="faq-header-container__img"></div>
            </div>
            <section class="faq-body">
                <EonList :FAQList="true" :arrowIcons="true"/>
            </section>
        </div>
    </div>
    <!-- eslint-enable max-len -->
</template>

<script>
    import EonList from "@/components/form/EonList";

    export default {

        data() {
            return {
            };
        },

        beforeMount() {
        },

        methods: {
        },

        components: {
            EonList,
        },
    };
</script>

<style lang="less" scoped>
@import "@/assets/theme/variable.less";
.faq-wrapper {
    .faqBG {
        margin-bottom: 100px;
        .faq-header-container {
            width: 100%;
            height: 500px;
            display: flex;
            margin-bottom: 150px;
            &__text {
                background-color: @secondary-color;
                width: 48%;
                padding: 50px 140px;
                display: flex;
                align-items: center;
                justify-content: center;
                @media screen and (max-width: 700px) {
                    width: 100%;
                }
                h1 {
                    color: white;
                    font-size: 100px;
                    font-weight: 900;
                    text-align: right;
                    @media screen and (max-width: 1130px) {
                        font-size: 80px;
                    }
                    @media screen and (max-width: 940px) {
                        font-size: 60px;
                    }
                    @media screen and (max-width: 700px) {
                        font-size: 80px;
                    }
                    @media screen and (max-width: 450px) {
                        font-size: 60px;
                    }
                }
            }
            &__img {
                width: 52%;
                background-image: url("../../assets/faqViewBG.jpeg");
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                @media screen and (max-width: 700px) {
                    display: none;
                }
            }
        }
        .faq-body {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
}
</style>

<style lang="less">
@import "@/assets/theme/variable.less";
.faq-wrapper {
    padding-top: 4.5rem;
    .faqBG {
        .faq-body {
            .pi.sublist, .sublist-label {
                color: @color-turquoise-dark;
                font-size: 32px;
            }
            .sublist-description {
                margin-top: 60px;
                ul {
                    padding-left: 20px;
                }
            }
            .faq-list {
                @media screen and (max-width: 700px) {
                    margin-right: 20px;
                    .sublist-header-button label {
                        text-align: left;
                    }
                }
            }
        }
    }
}
</style>
