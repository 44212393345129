<template>
    <span class="info-button">
        <OverlayPanel class="info-button-panel" ref="infoPanel" appendTo="body" :breakpoints="{'350px': '1vw'}">
            <div>{{ infoText }}</div>
            <EonOutlinedButton v-if="showPhone" class="turquoise-button"
                               label="22 821 31 31" icon="pi pi-phone" extension="20"/>
        </OverlayPanel>
        <PrimeButton class="info-button-icon" @click="onClick($event)"/>
    </span>
</template>

<script>
    import PrimeButton from "primevue/button";
    import OverlayPanel from "primevue/overlaypanel";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";

    export default {
        name: "CustomInfoButton",

        components: {
            PrimeButton,
            OverlayPanel,
            EonOutlinedButton,
        },

        props: {
            infoText: {
                type: String,
            },
            showPhone: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            onClick(event) {
                this.$refs.infoPanel.toggle(event);
            },
        },
    };
</script>

<style scoped lang="less">
    .info-button {
        padding: 0 10px 0 10px;
        .info-button-icon {
            background-image: url("@/assets/info.svg");
            border: none;
            background-color: transparent;
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            &:hover {
                background-image: url("@/assets/info_filled.svg");
            }
        }
    }
</style>
<style lang="less">
    .info-button-panel {
        &.p-overlaypanel .p-overlaypanel-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;
        }
    }
</style>
