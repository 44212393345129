<template>
    <Toast />
    <!-- eslint-disable max-len -->
    <div class="poll-wrapper">
        <div class="pollBG">
            <div class="poll-header-container">
                <div class="poll-header-container__text">
                    <h1>Ankieta</h1>
                </div>
                <div class="poll-header-container__img"></div>
            </div>
        </div>
        <div class="poll-form">
            <VeeForm  @submit="savePoll" @keydown.enter="$event.preventDefault()"
                      class="form poll-form-container">
                <div class="poll-single-question">
                    <h4 class="poll-single-question-header">Adres e-mail</h4>
                    <EonInputText width="250" label="E-mail" name="email"
                                  v-model="poll.email" rules="required|email"/>
                </div>
                <div class="poll-single-question">
                    <h4 class="poll-single-question-header">W jakim celu odwiedziłeś Portal planowanych wyłączeń i awarii?</h4>
                    <div class="poll-answer-checkboxes">
                        <EonCheckbox v-model="poll.reportFailureTarget"
                                     :label="($t('message.poll.reportFailureTarget'))"
                                     @click="uncheckOthers('reportFailureTarget')"/>
                        <EonCheckbox v-model="poll.failureCheckTarget"
                                     :label="($t('message.poll.failureCheckTarget'))"
                                     @click="uncheckOthers('failureCheckTarget')"/>
                        <EonCheckbox v-model="poll.plannedCheckTarget"
                                     :label="($t('message.poll.plannedCheckTarget'))"
                                     @click="uncheckOthers('plannedCheckTarget')"/>
                    </div>
                </div>
                <div class="poll-single-question">
                    <h4 class="poll-single-question-header">Czy udało Ci się zrealizować cel wizyty?</h4>
                    <div class="poll-answer-checkboxes">
                        <EonCheckbox v-model="poll.targetAchieved"
                                     :label="($t('message.poll.yes'))"
                                     @click="uncheckOthers('yes')"/>
                        <EonCheckbox v-model="poll.targetNotAchieved"
                                     :label="($t('message.poll.no'))"
                                     @click="uncheckOthers('no')"/>
                    </div>
                </div>
                <div class="poll-single-question">
                    <h4 class="poll-single-question-header">Co możemy poprawić w Portalu planowanych wyłączeń i awarii?</h4>
                    <EonInputTextArea name="additionalRemarks" :rows="8" :autoResize="true"
                                      v-model="poll.additionalRemarks" />
                </div>
                <RecaptchaPolicyHintView/>
                <div class="poll-submit-button">
                    <EonButton :visible="false" label="Prześlij odpowiedź" type="submit" :disabled="!isCorrectlyFilled()"/>
                </div>
            </VeeForm>
        </div>
    </div>
    <!-- eslint-enable max-len -->
</template>

<script>
    import Toast from "primevue/toast";
    import EonList from "@/components/form/EonList";
    import EonCheckbox from "@/components/form/EonCheckbox";
    import EonButton from "@/components/form/EonButton";
    import {Form as VeeForm} from "vee-validate";
    import EonInputTextArea from "@/components/form/EonInputTextArea";
    import {
        addPoll,
    } from "@/swagger/vue-api-client";
    import EonInputText from "@/components/form/EonInputText";
    import RecaptchaPolicyHintView from "../static/RecaptchaPolicyHintView";

    export default {

        data() {
            return {
                poll: {
                    email: "",
                    reportFailureTarget: false,
                    plannedCheckTarget: false,
                    failureCheckTarget: false,
                    targetAchieved: false,
                    targetNotAchieved: false,
                    additionalRemarks: "",
                    recaptchaToken: "",
                },
            };
        },

        beforeMount() {
        },

        methods: {
            uncheckOthers(chosen) {
                if (chosen === "yes") {
                    this.poll.targetNotAchieved = false;
                } else if (chosen === "no") {
                    this.poll.targetAchieved = false;
                }
            },
            isCorrectlyFilled() {
                return (this.poll.targetNotAchieved || this.poll.targetAchieved)
                    && (this.poll.plannedCheckTarget || this.poll.failureCheckTarget || this.poll.reportFailureTarget);
            },
            clearPoll() {
                this.poll.reportFailureTarget = false;
                this.poll.targetAchieved = false;
                this.poll.targetNotAchieved = false;
                this.poll.failureCheckTarget = false;
                this.poll.plannedCheckTarget = false;
            },
            async getRecaptchaToken() {
                await this.$recaptchaLoaded();
                return this.$recaptcha("POLL");
            },
            async savePoll() {
                this.poll.recaptchaToken = await this.getRecaptchaToken();
                addPoll({body: this.poll}).then(() => {
                    this.$cookies.set("poll", "filled", "1y");
                    this.clearPoll();
                    this.$toast.removeAllGroups();
                    this.$toast.add({
                        severity: "success",
                        summary: "Dziękujemy za Twoją odpowiedź!",
                        life: 2500,
                    });
                    this.showToast = true;
                    setTimeout(() => {
                        this.$router.replace({
                            name: "publicMapView",
                        });
                    }, 1800);
                })
                    .catch(() => {
                        this.$toast.removeAllGroups();
                        this.$toast.add({
                            severity: "error",
                            summary: "Wystąpił nieoczekiwany błąd",
                            life: 2500,
                        });
                    });
            },
        },

        components: {
            EonInputText,
            RecaptchaPolicyHintView,
            EonInputTextArea,
            EonButton,
            EonCheckbox,
            EonList,
            VeeForm,
            Toast,
        },
    };
</script>

<style lang="less" scoped>
    @import "@/assets/theme/variable.less";
    .poll-wrapper {
        padding-top: 4.5rem;

        .pollBG {
            margin-bottom: 50px;
            .poll-header-container {
                width: 100%;
                height: 300px;
                display: flex;
                &__text {
                    background-color: @secondary-color;
                    width: 48%;
                    padding: 50px 140px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: 700px) {
                        width: 100%;
                    }
                    h1 {
                        color: white;
                        font-size: 100px;
                        font-weight: 900;
                        text-align: right;
                        @media screen and (max-width: 1130px) {
                            font-size: 80px;
                        }
                        @media screen and (max-width: 940px) {
                            font-size: 60px;
                        }
                        @media screen and (max-width: 700px) {
                            font-size: 80px;
                        }
                        @media screen and (max-width: 450px) {
                            font-size: 60px;
                        }
                    }
                }
                &__img {
                    width: 52%;
                    background-image: url("../../assets/pollViewBG.jpg");
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    @media screen and (max-width: 700px) {
                        display: none;
                    }
                }
            }
        }
    }

    .poll-form {
        display: flex;
        justify-content: center;
        margin-bottom: 100px;
        @media screen and (max-width: 1000px) {
            margin: 0 25px 100px 25px;
        }
        &-container {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 1rem;
            .poll-single-question {
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 1rem;
                width: 100%;
                &-header {
                    text-align: left;
                }
                .poll-answer-checkboxes {
                    display: flex;
                    text-align: left;
                    gap: 1rem;
                    flex-wrap: wrap;
                }
                .eon-text-area {
                    width: 100%;
                }
            }
            .poll-submit-button {
                display: flex;
                align-self: center;
            }
        }
    }
</style>
<style lang="less">
    .poll-form-container {
        .poll-single-question {
            .poll-answer-checkboxes {
                .eon-checkbox-field {
                    .eon-checkbox-off {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
</style>
