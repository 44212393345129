/* eslint-disable */
export const SearchCriteria = {
    timeSearchCriteriaKeys: ["start", "end"],

    updateUrl(context) {
        const urlParam = {};
        Object.entries(context.searchCriteria).forEach(([key, value]) => {
            if (key !== "page") {
                if (value || value === false) {
                    if (this.timeSearchCriteriaKeys.includes(key)) {
                        value = new Date(value).toISOString();
                    }
                        urlParam[key] = "" + value;
                }
            } else {
                Object.entries(value).forEach(([pageKey, pageValue]) => {
                    if (pageValue) {
                        urlParam["page" + pageKey] = "" + pageValue;
                    }
                });
            }
        });

        if (JSON.stringify(urlParam) !== JSON.stringify(context.$route.query)) {
            context.$router.push({name: context.$route.name, query: urlParam});
        }
    },

    loadCriteria(context) {
        Object.entries(context.$route.query).forEach(([key, value]) => {
            if (value) {
                if (key.startsWith("page")) {
                    context.searchCriteria.page[key.substr(4)] = isNaN(value) ? value : parseInt(value, 10);
                } else if (this.timeSearchCriteriaKeys.includes(key)) {
                    context.searchCriteria[key] = new Date(value);
                } else {
                    switch (value) {
                        case "false":
                            context.searchCriteria[key] = false;
                            break;
                        case "true":
                            context.searchCriteria[key] = true;
                            break;
                        default:
                            context.searchCriteria[key] = isNaN(value) ? value : parseInt(value, 10);
                    }
                }
            }
        });
    },

};
