<template>
    <div class="eon-multiselect-field" :style="'width: ' + width + 'px;'">
        <span class="p-float-label">
            <MultiSelect v-model="value" :options="items" :optionLabel="itemLabel" display="chip" />
            <label :for="name">{{ placeholder }}</label>
        </span>
    </div>
</template>
<script>
    import MultiSelect from "primevue/multiselect";

    export default {
        name: "EonMultiselect",
        props: {
            name: {
                type: String,
            },
            placeholder: {
                type: String,
            },
            width: {
                type: String,
            },
            itemLabel: {
                type: String,
            },
            itemValue: {
                type: String,
            },
            items: {
                type: Array,
            },
        },
        components: {MultiSelect},
        data() {
            return {
                value: null,
            };
        },
    };
</script>

<style lang="less">
@import "../assets/less/eon-multiselect.less";
@import "../assets/less/eon-checkbox.less";
</style>
