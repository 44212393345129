<template>
    <Toast />
    <h1>Planowane wyłączenia - panel mistrza</h1>
    <PlannedOutageReportMasterSearchCriteria ref="plannedOutageReportMasterSearchCriteria" v-model="searchCriteria"
                                             @update:modelValue="search" @confirmDelete="deleteSelectedOutages" />
    <PlannedOutageReportMasterTable ref="plannedOutageReportMasterTable" :search-criteria="searchCriteria" />
</template>

<script>
    import Toast from "primevue/toast";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import PlannedOutageReportMasterSearchCriteria from "./components/PlannedOutageReportMasterSearchCriteria";
    import PlannedOutageReportMasterTable from "./components/PlannedOutageReportMasterTable";

    export default {
        name: "PlannedOutageReportMasterView",

        components: {
            PlannedOutageReportMasterSearchCriteria,
            PlannedOutageReportMasterTable,
            Toast,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        mounted() {
            if (this.$route.params.showToast === "show") {
                this.$toast.add({
                    severity: "success",
                    summary: "Zapisano dane planowanego wyłączenia",
                    life: 2500,
                });
                this.$route.params.showToast = "hide";
            }
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.plannedOutageReportMasterTable.search();
            },

            createdBy() {
                this.$router.push({name: "plannedOutageReportMasterCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

            getClearSearchCriteria() {
                return {
                    id: null,
                    outageStart: null,
                    outageEnd: null,
                    deleted: false,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },

            deleteSelectedOutages() {
                this.$refs.plannedOutageReportMasterTable.deleteSelectedOutages();
            },
        },
    };
</script>

<style lang="less" scoped>
</style>
