<template>
    <div>
        <div class="eon-change-password-field">
            <div>
                <div>
                    <h2>Zmień hasło</h2>
                    <div v-show="!afterChange">
                        <p>Podaj nowe hasło</p>
                    </div>
                </div>
                <VeeForm @submit.prevent="change" v-slot="{ errors }">
                    <div v-show="!afterChange">
                        <EonPassword class="input-password" color="turquoise" name="newPassword"
                                     rules="required|validPassword"
                                     v-model="changePasswordForm.newPassword"
                                     label="Nowe hasło"/>
                        <EonPassword class="repeat-password" name="repeatPassword" color="turquoise"
                                     v-model="repeatPassword" label="Powtórz hasło"/>

                        <div v-show="!passwordEqual" class="valid-error">
                            Wpisane hasła nie są takie same.
                        </div>
                        <EonButton :disabled="isDisabled(errors)" class="inverted turquoise"
                                   label="Zmień hasło" @click="change" type="submit"/>
                    </div>
                </VeeForm>

                <div v-show="afterChange">
                    <div v-if="success">
                        <h3>Hasło zostało zmienione.</h3>
                        <router-link :to="{name: 'login'}">
                            <EonButton class="inverted turquoise" label="Zaloguj się"/>
                        </router-link>
                    </div>
                    <div v-else>
                        <h3>Nie udało się zmienić hasła.</h3>
                        <router-link :to="{name: 'remindPassword'}">
                            <EonButton class="inverted turquoise" label="Spróbuj ponownie"/>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {changePassword} from "@/swagger/vue-api-client";
    import EonPassword from "@/components/form/EonPassword";
    import EonButton from "@/components/form/EonButton";
    import {Form as VeeForm} from "vee-validate";

    export default {
        name: "EonChangePasswordView",
        components: {
            EonPassword,
            EonButton,
            VeeForm,
        },

        computed: {
            passwordEqual() {
                return this.repeatPassword === this.changePasswordForm.newPassword;
            },
        },

        data() {
            return {
                changePasswordForm: {
                    resetPasswordToken: "",
                    newPassword: "",
                },
                repeatPassword: "",
                afterChange: false,
                success: false,
            };
        },

        mounted() {
            this.changePasswordForm.resetPasswordToken = this.$route.query.resetPasswordToken;
        },

        methods: {
            change() {
                changePassword({body: this.changePasswordForm}).then((response) => {
                    this.success = response.data;
                    this.afterChange = true;
                });
            },
            isDisabled(errors) {
                return !(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype);
            },
        },
    };
</script>

<style lang="less" scoped>
@import "assets/less/eon-change-password.less";
</style>
