<script>
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "HomePageView",
        data() {
            return {
                token: localStorage.getItem("token"),
                Role: SystemRole,
            };
        },
        mounted() {
            if (this.token) {
                if (this.Role.isAdmin()) {
                    this.$router.push({name: "admin"});
                } else if (this.Role.isDispatcher()) {
                    this.$router.push({name: "dispatcher"});
                } else if (this.Role.isAccepter()) {
                    this.$router.push({name: "callCenter"});
                }
            } else {
                this.$router.push({name: "publicMapView"});
            }
        },
    };
</script>
