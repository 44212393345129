<template>
    <div class="report-check-dialog">
        <div v-if="isNoVoltage" class="dialog-input">
            <h2>Zgłoszenie dotyczy:</h2>
            <div v-if="!isHouse" style="display: inline-flex">
                <EonCheckbox class="bold-checkbox" v-model="isFlat"
                             :label="($t('message.addReport.flat'))"
                             @click="uncheckOthers('flat')"/>
                <EonInfoButton info-text="np. blok, kamienica"></EonInfoButton>
            </div>
            <p v-if="isFlat" class="no-margin-paragraph">Potwierdzam, że:</p>
            <EonCheckbox v-if="isFlat" class="sub-checkbox" v-model="invoicesPaid"
                         :label="($t('message.addReport.invoicesPaid'))"/>
            <EonCheckbox v-if="isFlat" class="sub-checkbox" v-model="checkedFuses"
                         :label="($t('message.addReport.checkedFuses'))"/>
            <EonCheckbox v-if="isFlat" class="sub-checkbox" v-model="checkedElectricityMeter"
                         :label="($t('message.addReport.checkedElectricityMeter'))"/>
            <p v-if="isFlat" class="no-margin-paragraph">
                Czy u sąsiadów i w częściach wspólnych budynku jest światło?
            </p>
            <EonCheckbox v-if="isFlat" class="sub-checkbox"
                         :label="($t('message.addReport.yes'))" v-model="isLight" />
            <EonCheckbox v-if="isFlat" class="sub-checkbox"
                         :label="($t('message.addReport.no'))" v-model="isNoLight" />
            <div v-if="!isFlat" style="display: inline-flex">
                <EonCheckbox class="bold-checkbox" v-model="isHouse"
                             :label="($t('message.addReport.house'))"
                             @click="uncheckOthers('house')"/>
                <EonInfoButton info-text="np. dom jednorodzinny"></EonInfoButton>
            </div>
            <p v-if="showInfoText" class="adm-info-bubble">
                {{ this.textToShow }}
            </p>
            <p class="no-margin-paragraph" v-if="isHouse">Potwierdzam, że:</p>
            <EonCheckbox v-if="isHouse" class="sub-checkbox" v-model="invoicesPaidHouse"
                         :label="($t('message.addReport.invoicesPaid'))"/>
            <EonCheckbox v-if="isHouse" class="sub-checkbox" v-model="checkedFusesHouse"
                         :label="($t('message.addReport.checkedFusesHouse'))"/>
            <EonCheckbox v-if="isHouse" class="sub-checkbox" v-model="checkedElectricityMeterHouse"
                         :label="($t('message.addReport.checkedElectricityMeterHouse'))"/>
            <p v-if="isHouse" class="no-margin-paragraph">
                Czy w sąsiednich budynkach jest włączone światło?
            </p>
            <EonCheckbox v-if="isHouse" class="sub-checkbox"
                         :label="($t('message.addReport.no'))" v-model="isNoLightHouse"
                         @click="uncheckOthers('noLightHouse')" />
            <EonCheckbox v-if="isHouse" class="sub-checkbox"
                         :label="($t('message.addReport.yes'))" v-model="isLightHouse"
                         @click="uncheckOthers('lightHouse')" />
            <p v-if="isLightHouse" class="no-margin-paragraph sub-checkbox"
               style="display: flex; align-items: center;">
                Budynek zasilany:
            </p>
            <EonCheckbox v-if="isLightHouse" class="sub-sub-checkbox"
                         :label="($t('message.addReport.airLine'))" v-model="airLine"
                         @click="uncheckOthers('airline')" />
            <EonCheckbox v-if="isLightHouse" class="sub-sub-checkbox"
                         :label="($t('message.addReport.cable'))" v-model="cable"
                         @click="uncheckOthers('cable')" />
            <p v-if="isLightHouse" class="no-margin-paragraph sub-checkbox">
                Czy awaria została potwierdzona przez elektryka?
            </p>
            <EonCheckbox v-if="isLightHouse" class="sub-sub-checkbox"
                         :label="($t('message.addReport.yes'))" v-model="electricanConfirmed"
                         @click="uncheckOthers('confirmed')" />
            <EonCheckbox v-if="isLightHouse" class="sub-sub-checkbox"
                         :label="($t('message.addReport.no'))" v-model="electricanNotConfirmed"
                         @click="uncheckOthers('notConfirmed')" />
        </div>
        <div v-else class="dialog-input">
            <h2>Zgłoszenie dotyczy:</h2>
            <EonCheckbox v-if="!isHouse" class="bold-checkbox" v-model="isFlat"
                         :label="($t('message.addReport.flat'))"
                         @click="uncheckOthers('flat')"/>
            <EonCheckbox v-if="!isFlat" class="bold-checkbox" v-model="isHouse"
                         :label="($t('message.addReport.house'))"
                         @click="uncheckOthers('house')"/>
            <p v-if="showInfoText" class="adm-info-bubble">
                {{ this.textToShow }}
            </p>
            <p v-if="isHouse" class="no-margin-paragraph sub-checkbox"
               style="display: flex; align-items: center;">
                Budynek zasilany:
            </p>
            <EonCheckbox v-if="isHouse" class="sub-sub-checkbox"
                         :label="($t('message.addReport.airLine'))" v-model="airLine"
                         @click="uncheckOthers('airline')" />
            <EonCheckbox v-if="isHouse" class="sub-sub-checkbox"
                         :label="($t('message.addReport.cable'))" v-model="cable"
                         @click="uncheckOthers('cable')" />
            <p v-if="isFlat || isHouse" class="no-margin-paragraph sub-checkbox">
                Czy awaria została potwierdzona przez elektryka?
            </p>
            <EonCheckbox v-if="isFlat || isHouse" class="sub-sub-checkbox"
                         :label="($t('message.addReport.yes'))" v-model="electricanConfirmed"
                         @click="uncheckOthers('confirmed')" />
            <EonCheckbox v-if="isFlat || isHouse" class="sub-sub-checkbox"
                         :label="($t('message.addReport.no'))" v-model="electricanNotConfirmed"
                         @click="uncheckOthers('notConfirmed')" />
        </div>
        <div class="add-report-description-buttons">
            <EonOutlinedButton class="turquoise-button" label="Powrót" @click="goBack"/>
            <EonButton class="red-button wide-button" :disabled="isDisabled"
                       label="Zgłoś awarię" @click="submit"/>
        </div>
    </div>
</template>
<script>
    import {
        getCallNetAddressStatus as checkIsADM,
    } from "@/swagger/vue-api-client";
    import EonCheckbox from "@/components/form/EonCheckbox";
    import EonButton from "@/components/form/EonButton";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import EonInfoButton from "@/components/form/EonInfoButton";
    import {Form as VeeForm} from "vee-validate";

    export default {
        name: "AddCheck",
        components: {
            EonButton, EonCheckbox, EonOutlinedButton, VeeForm, EonInfoButton,
        },
        emits: [
            "submitReport", "checkReport",
        ],
        props: {
            addressADM: null,
        },
        data() {
            return {
                isFlat: false,
                isHouse: false,
                checkedFuses: false,
                invoicesPaid: false,
                checkedElectricityMeter: false,
                isLight: false,
                isNoLight: false,
                checkedFusesHouse: false,
                invoicesPaidHouse: false,
                checkedElectricityMeterHouse: false,
                isLightHouse: false,
                isNoLightHouse: false,
                airLine: false,
                cable: false,
                electricanConfirmed: false,
                electricanNotConfirmed: false,
                isADM: null,
                textToShow: "",
                showInfoText: false,
                description: "",
                isSubmitting: false,
                isNoVoltage: false,
                inExtensiveArea: false,
                extensiveAreaDescription: "",
            };
        },
        beforeMount() {
            checkIsADM({body: this.addressADM})
                .then((response) => {
                    this.isADM = response.data;
                });

            const {params} = this.$route;
            if (params.isNoVoltage !== undefined) {
                this.isNoVoltage = (params.isNoVoltage === "true");
            }
            if (params.inExtensiveArea !== undefined) {
                this.inExtensiveArea = (params.inExtensiveArea === "true");
            }
            if (params.extensiveAreaDescription) {
                this.extensiveAreaDescription = params.extensiveAreaDescription;
            }
        },
        computed: {
            isDisabled() {
                if (this.isSubmitting === true) {
                    return true;
                }
                if (this.isNoVoltage) {
                    return !(((this.isFlat && (
                        this.checkedFuses
                        && this.invoicesPaid
                        && this.checkedElectricityMeter
                        && this.isNoLight)
                    )) || (this.isHouse && (
                        this.checkedFusesHouse
                        && this.invoicesPaidHouse
                        && this.checkedElectricityMeterHouse
                        && (
                            this.isNoLightHouse || (
                                this.isLightHouse && (
                                    this.airLine || this.cable
                                ) && (
                                    this.electricanConfirmed || this.electricanNotConfirmed
                                )
                            )))
                    ));
                }
                return !((this.isFlat && (this.electricanConfirmed || this.electricanNotConfirmed)) || (
                    this.isHouse && (
                        (this.cable || this.airLine)
                        && (this.electricanConfirmed || this.electricanNotConfirmed)
                    )
                ));
            },
        },
        methods: {
            submit(e) {
                this.isSubmitting = true;
                e.preventDefault();
                if (this.isFlat) {
                    this.description += "Lokal mieszkalny/użytkowy w zabudowie wielolokalowej; ";
                }
                if (this.isHouse) {
                    this.description += "Zabudowa jednolokalowa; ";
                }
                if (this.isNoLight) {
                    this.description += "u sąsiadów i w częściach wspólnych brak światła; ";
                }
                if (this.isLightHouse) {
                    this.description += "w sąsiednich budynkach jest włączone światło; ";
                }
                if (this.isNoLightHouse) {
                    this.description += "w sąsiednich budynkach brak światła; ";
                }
                if (this.airLine) {
                    this.description += "linia napowietrzna; ";
                }
                if (this.cable) {
                    this.description += "linia kablowa; ";
                }
                if (this.electricanConfirmed) {
                    this.description += "awaria potwierdzona przez elektryka; ";
                }
                if (this.electricanNotConfirmed) {
                    this.description += "awaria niepotwierdzona przez eleketryka; ";
                }
                if (this.inExtensiveArea) {
                    this.description += "[KOMUNIKAT] " + this.extensiveAreaDescription;
                }
                this.$emit("checkReport", {
                    description: this.description,
                });
                this.$emit("submitReport");
            },
            goBack() {
                this.$router.replace({name: "publicAddReportDescription"});
            },
            // redirect() {
            //     const {params} = this.$route;
            //     params.isFlat = this.isFlat;
            //     params.isHouse = this.isHouse;
            //     this.$router.replace({name: "publicAddReportDescription", params});
            // },
            uncheckOthers(option) {
                if (option === "flat" || (option === "house" && !this.isHouse)) {
                    this.isHouse = false;
                    this.checkedFusesHouse = false;
                    this.invoicesPaidHouse = false;
                    this.checkedElectricityMeterHouse = false;
                    this.isLightHouse = false;
                    this.isNoLightHouse = false;
                    this.cable = false;
                    this.airLine = false;
                    this.electricanNotConfirmed = false;
                    this.electricanConfirmed = false;
                }
                if (option === "house" || (option === "flat" && !this.isFlat)) {
                    this.isFlat = false;
                    this.checkedFuses = false;
                    this.invoicesPaid = false;
                    this.checkedElectricityMeter = false;
                    this.isLight = false;
                    this.isNoLight = false;
                }
                if (option === "lightHouse") {
                    this.isNoLightHouse = false;
                }
                if (option === "noLightHouse") {
                    this.isLightHouse = false;
                    this.cable = false;
                    this.airLine = false;
                    this.electricanNotConfirmed = false;
                    this.electricanConfirmed = false;
                }
                if (option === "airline") {
                    this.cable = false;
                }
                if (option === "cable") {
                    this.airLine = false;
                }
                if (option === "confirmed") {
                    this.electricanNotConfirmed = false;
                }
                if (option === "notConfirmed") {
                    this.electricanConfirmed = false;
                }
            },
        },
        watch: {
            isLight(value) {
                if (value && this.isADM) {
                    this.textToShow = "Skontaktuj się z administracją budynku/elektrykiem administracji.";
                    this.showInfoText = true;
                } else if (value && !this.isADM) {
                    /* eslint-disable-next-line max-len */
                    this.textToShow = "Usterka najprawdopodobniej nastąpiła w instalacji wewnętrznej Twojego lokalu. Naprawa przyczyn braku energii wewnątrz lokalu nie jest zadaniem dla Pogotowia energetycznego Stoen Operator. Prosimy o indywidualny kontakt z elektrykiem lub zarządcą budynku.";
                    this.showInfoText = true;
                } else if (!this.isNoLight) {
                    this.showInfoText = false;
                }
                if (value && this.isNoLight) {
                    this.isNoLight = false;
                }
            },
            isNoLight(value) {
                if (value && this.isLight) {
                    this.isLight = false;
                }
                if (value) {
                    /* eslint-disable-next-line max-len */
                    this.textToShow = "Sprawdź czy elektrycy administracji nie wykonują żadnych prac - ogłoszenie na tablicy na klatce.";
                    this.showInfoText = true;
                } else if (!this.isLight) {
                    this.showInfoText = false;
                }
                if (value && this.isLight) {
                    this.isLight = false;
                }
            },
        },
    };
</script>
<style lang="less" scoped>
@import "../../../assets/theme/variable.less";
.dialog-input {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 1em 2em 3em 2em;
    text-align: left;
    width: 100%;
}
.report-check-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
    width: 100%;
}
h2 {
    color: @color-turquoise;
}
.eon-checkbox-off {
    display: flex !important;
}
.sub-checkbox {
    padding-left: 1em;
}
.sub-sub-checkbox {
    padding-left: 2em;
}
.sub-sub-sub-checkbox {
    padding-left: 3em;
}
.sub-sub-sub-sub-checkbox {
    padding-left: 4em;
}
.bold-checkbox {
    font-weight: 500;
}
.no-margin-paragraph {
    margin: 0 0 0 0;
}
// .wide-button {
//     margin-bottom: 2em;
// }
.add-report-description-buttons {
    display: flex;
    justify-content: center;
    column-gap: 0.5em;
}
.adm-info-bubble {
    background-color: rgba(234, 27, 10, 0.1);
    color: @color-red;
    text-align: center;
    padding: 0.75rem 1rem;
    width: 100%;
}
</style>
