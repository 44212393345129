<template>
    <div class="sublist-wrapper">
        <div class="sublist-header" :class="computedClass + computedClassFAQ">
            <PrimeButton class="sublist-header-button" @click="change"
                         :binary="true" :class="computedClass">
                <i v-if="arrowIcons" class="pi pi-angle-down sublist arrow-rotate" :class="computedClassArrow" />
                <i v-else :class="computedIcon" />
                <label class="sublist-label" v-if="label" for="show-more">{{ label }}</label>
            </PrimeButton>
        </div>
        <p v-if="clicked && description && !FAQList" class="sublist-description">
            {{description}}
        </p>
        <div v-if="clicked && description && FAQList" class="sublist-description" v-html = description>
        </div>
    </div>
</template>

<script>
    import PrimeButton from "primevue/button";

    export default {
        name: "EonSublist",

        props: {
            label: {
                type: String,
            },
            description: {
                type: String,
            },
            FAQList: {
                default: false,
            },
            arrowIcons: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["area-clicked"],

        data() {
            return {
                clicked: false,
            };
        },

        methods: {
            change() {
                this.$emit("area-clicked");
                this.clicked = !this.clicked;
            },
        },

        computed: {
            computedClassFAQ() {
                return this.FAQList ? "faq-list" : "";
            },
            computedClass() {
                return this.clicked ? "li-clicked" : "li-not-clicked";
            },
            computedIcon() {
                return this.clicked ? "pi pi-eye-slash sublist" : "pi pi-eye sublist";
            },
            computedClassArrow() {
                return this.clicked ? "toggle-up" : "toggle-down";
            },
        },

        components: {
            PrimeButton,
        },
    };
</script>

<style lang="less">
    @import "../assets/less/sublist.less";
    .sublist-header {
        .toggle-down {
            transform: rotate(0deg);
        }
        .toggle-up {
            transform: rotate(180deg);
        }
        .arrow-rotate {
            transition: all 0.5s;
        }
    }
</style>
