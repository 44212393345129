<template>
    <PrimeButton iconPos="right" class="eon-button" :width="svgWidth" :color="color" :type="type">
        <span class="button-inside">
            <span class="button-icon-label">
                <span class="button-icon" v-if="icon" :class="icon"/>
                <span class="button-label" v-if="label && type === 'submit'" for="eon-button">{{ label }}</span>
                <label class="button-label" v-if="label && type !== 'submit'" for="eon-button">{{ label }}</label>
            </span>
            <span v-if="withSvg">
                <!-- eslint-disable max-len -->
                <svg :width="svgWidth" height="48" :viewBox="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.76724 0.99305C4.60071 1.5442 2.11529 4.01404 1.54424 7.17705C-0.514748 18.2986 -0.514748 29.7035 1.54424 40.8251C2.11651 43.9857 4.60225 46.4523 7.76724 47C13.2302 47.893 24.0002 48 24.0002 48V4.95911e-05C24.0002 4.95911e-05 12.6162 0.12005 7.76724 0.99305Z" fill="#EA1B0A"/>
                    <rect :width="rectWidth" height="48" transform="translate(23.0002 9.91821e-05)" fill="#EA1B0A"/>
                    <path :transform="transform" fill-rule="evenodd" clip-rule="evenodd" d="M118 4.95911e-05V48C118 48 128.77 47.893 134.233 47C137.399 46.452 139.886 43.984 140.457 40.822C142.515 29.7005 142.515 18.2956 140.457 7.17405C139.885 4.01205 137.399 1.5437 134.233 0.99305C129.384 0.12005 118 4.95911e-05 118 4.95911e-05Z" fill="#EA1B0A"/>
                </svg>
                <!-- eslint-enable max-len -->
            </span>
        </span>
    </PrimeButton>
</template>

<script>

    import PrimeButton from "primevue/button";

    export default {
        name: "EonButton",
        components: {PrimeButton},
        computed: {
            svgWidth() {
                return 142 + Number(this.extension);
            },
            rectWidth() {
                return 96 + Number(this.extension);
            },
            viewBox() {
                return "0 0 " + this.svgWidth + " 48";
            },
            transform() {
                return "translate(" + this.extension + ")";
            },
        },
        props: {
            label: {
                type: String,
            },
            type: {
                type: String,
            },
            icon: {
                type: String,
                default: null,
            },
            withSvg: {
                type: Boolean,
                default: true,
            },
            extension: {
                type: String,
                default: "0",
            },
            color: {
                type: String,
                default: "@color-red",
            },
        },
    };
</script>

<style lang="less">
@import "../assets/less/button";
.eon-button {
    .button-inside {
        label.button-label {
            cursor: pointer;
        }
    }
}
</style>
