<template>
    <div class="container">
        <EonPublicMap class="map" ref="map" @update:api="updateApi"/>
        <PublicAddReportDialog class="add-report-button" :google-maps-api="api"/>
    </div>
</template>

<script>
    import EonPublicMap from "@/components/EonPublicMap";
    import PublicAddReportDialog from "@/views/public/components/PublicAddReportDialog";

    export default {
        name: "PublicAddReportView",
        components: {
            EonPublicMap,
            PublicAddReportDialog,
        },
        data() {
            return {
                api: null,
            };
        },
        methods: {
            updateApi(api) {
                this.api = api;
            },
        },
    };
</script>

<style lang="less" scoped>
.container {
    height: 100%;
}
.map {
    height: 100%;
    width: 100%;
}
.add-report-button {
    position: absolute;
    bottom: 2em;
    left: 2em;
}
</style>
