<template>
    <div class="dialog-field">
        <EonButton label="Dodaj zgłoszenie" icon="pi pi-plus" extension="50" />
        <PrimeDialog class="eon-dialog add-report-dialog" :id="name" v-model:visible="display" :draggable="false"
                     :modal="true" :style="'width: ' + dialogWidth + 'px;'" @hide="redirect">
            <template #header>
                <Steps class="add-report-steps" :model="stepItems" :readonly="true"/>
            </template>
            <router-view v-slot="{Component}" >
                <keep-alive>
                    <component :is="Component"
                               :google-maps-api="googleMapsApi"
                               @locationToReport="locationReport"
                               @detailsReport="detailsReport"
                               @descriptionReport="descriptionReport"
                               @checkReport="checkReport"
                               @submit-report="submitReport"
                               :addressADM="addressADM"
                               :report="report"/>
                </keep-alive>
            </router-view>
        </PrimeDialog>
    </div>
</template>
<script>
    import PrimeDialog from "primevue/dialog";
    import EonButton from "@/components/form/EonButton";
    import Steps from "primevue/steps";
    import {
        createCallNetReport as addReport,
        getAreaByCoordinates as getAreaByCoords,
        getConfig,
    } from "@/swagger/vue-api-client";
    import {TimeUtils} from "@/utils/TimeUtils";
    import {StatusCodes} from "@/utils/StatusCodes";

    export default {
        name: "EonAddReportDialog",
        props: {
            name: {
                type: String,
            },
            dialogWidth: {
                type: String,
            },
            extensiveAreas: {
                type: Array,
            },
            googleMapsApi: null,
        },
        components: {PrimeDialog, EonButton, Steps},
        data() {
            return {
                value: null,
                display: true,
                report: {
                    id: null,
                    city: null,
                    district: null,
                    street: null,
                    residenceNumber: null,
                    apartmentNumber: null,
                    postcode: null,
                    reporter: null,
                    phoneNumber: null,
                    email: null,
                    description: "",
                    coordinateX: null,
                    coordinateY: null,
                    // counterMalfunction: null,
                    // highVoltage: null,
                    // isFlat: null,
                    // isHouse: null,
                    // lowVoltage: null,
                    // partialVoltageLack: null,
                    // completeVoltageLack: null,
                    // voltageFluctuation: null,
                    outageStart: null,
                    reportType: "",
                    recaptchaToken: null,
                    canAddReportOutsideArea: null,
                },
                isNoVoltage: false,
                stepItems: [{
                                label: "Step 1",
                                to: "/public/add-report/1",
                            },
                            {
                                label: "Step 2",
                                to: "/public/add-report/2",
                            },
                            {
                                label: "Step 3",
                                to: "/public/add-report/3",
                            },
                            {
                                label: "Step 4",
                                to: "/public/add-report/4",
                            },
                            {
                                label: "Step 5",
                                to: "/public/add-report/5",
                            }],
                addressADM: null,
            };
        },
        async beforeMount() {
            await getConfig({id: "canAddReportOutsideArea"}).then((response) => {
                this.canAddReportOutsideArea = Boolean(response.data.value);
            });
        },
        methods: {
            locationReport(value) {
                this.report.street = value.address.street;
                this.report.residenceNumber = value.address.streetNumber;
                this.report.city = value.address.city;
                this.report.postcode = value.address.postcode;
                this.report.district = value.address.district;
                this.report.coordinateX = value.coordinates.lng;
                this.report.coordinateY = value.coordinates.lat;
            },
            detailsReport(value) {
                this.report.city = value.city;
                this.report.street = value.street;
                this.report.residenceNumber = value.residenceNumber;
                this.report.apartmentNumber = value.apartmentNumber;
                this.report.reporter = value.name;
                this.report.phoneNumber = value.phone;
                this.report.email = value.email;
                this.report.district = value.district;
                this.report.coordinateX = value.coordinates.lng;
                this.report.coordinateY = value.coordinates.lat;
                this.addressADM = {
                    street: value.street,
                    district: value.district,
                    residenceNumber: value.residenceNumber,
                    apartmentNumber: value.apartmentNumber,
                    city: value.city,
                };
            },
            descriptionReport(value) {
                this.report.reportType = value.type;
            },
            checkReport(value) {
                this.report.description = value.description;
            },
            redirect() {
                this.$router.push({name: "publicMapView"});
            },
            async invalidCoords() {
                try {
                    /* eslint-disable-next-line max-len */
                    await getAreaByCoords({coordinatesRequest: {lat: this.report.coordinateY, lng: this.report.coordinateX}});
                    return false;
                } catch (e) {
                    return !this.canAddReportOutsideArea;
                }
            },
            async getRecaptchaToken() {
                await this.$recaptchaLoaded();
                return this.$recaptcha("CALL_NET_REPORT");
            },
            async submitReport() {
                this.report.recaptchaToken = await this.getRecaptchaToken();
                addReport({body: TimeUtils.adjustRequestBody(this.report)})
                    .then(() => {
                        this.$router.push({path: "/public/add-report/5"});
                    })
                    .catch((error) => {
                        if (error.response === undefined) {
                            this.$router.push("/public/add-report/error");
                        }
                        switch (error.response.status) {
                            case StatusCodes.FORBIDDEN:
                                this.$router.push({path: "/public/add-report/5/true"});
                                break;
                            default:
                                this.$router.push("/public/add-report/error");
                        }
                    });
                // this.report.address.streetNumber = data.num;
                // this.report.address.street = data.str;
                // this.report.address.city = data.city;
                // this.report.address.postcode = data.post;
                // this.report.author.name = data.name;
                // this.report.author.phone = data.phone;
                // this.report.author.email = data.email;
                // this.report.coordinates.lat = data.lat;
                // this.report.coordinates.lng = data.lng;
                // if (!this.report.address.city || !this.report.address.street
                //     || !this.report.address.streetNumber || !this.report.address.postcode
                //     || !this.report.author.name || !this.report.author.email || !this.report.author.phone
                //     || !this.report.coordinates.lat
                //     || !this.report.coordinates.lng || await this.invalidCoords()) {
                //     await this.$router.push("/public/add-report/error");
                // } else {
                //     this.report.recaptchaToken = await this.getRecaptchaToken();
                //     this.report.comment = data.comment;
                //     this.report.brokenCable = data.brokenCable;
                //     this.report.brokenWires = data.brokenWires;
                //     this.report.counterMalfunction = data.counterMalfunction;
                //     this.report.fire = data.fire;
                //     this.report.highVoltage = data.highVoltage;
                //     this.report.isFlat = data.isFlat;
                //     this.report.isHealthDanger = data.isHealthDanger;
                //     this.report.isHouse = data.isHouse;
                //     this.report.isOther = data.isOther;
                //     this.report.isPowerFailure = data.isPowerFailure;
                //     this.report.lowVoltage = data.lowVoltage;
                //     this.report.no1Phase = data.no1Phase;
                //     this.report.no2Phase = data.no2Phase;
                //     this.report.no3Phase = data.no3Phase;
                //     this.report.noVoltage = data.noVoltage;
                //     this.report.openedCableConnections = data.openedCableConnections;
                //     this.report.voltageFluctuation = data.voltageFluctuation;
                //     this.report.outageStart = new Date();
                //     addReport({userOutageReportRequest: TimeUtils.adjustRequestBody(this.report)})
                //         .then(() => {
                //             this.$router.push({path: "/public/add-report/5"});
                //         })
                //         .catch(() => {
                //             this.$router.push("/public/add-report/error");
                //         });
                // }
            },
        },
    };
</script>

<style lang="less">
.add-report-dialog {
    width: 50%;
    min-width: 400px;
    max-width: 800px;
}
.eon-dialog.p-dialog.add-report-dialog .p-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.add-report-steps {
    width: 80%;
}

@import "../../../components/assets/less/dialog.less";
@import "../../../components/assets/less/steps.less";

</style>
