<template>
    <div :class="['eon-password-field', color, computedClass]" :style="'width: ' + width + 'px;'">
        <span :class="['p-float-label', color]">
            <Password :class="color" :id="name" v-model="internalValue"
                      :feedback="false" :toggleMask="true" @input="handleChange" @blur="handleBlur" />
            <label :for="name">{{ label }}</label>
        </span>
        <span v-show="errorMessage" class="p-invalid">
            <i class="pi pi-exclamation-triangle" />
            {{ errorMessage }}
        </span>
    </div>
</template>

<script>
    import Password from "primevue/password";
    import {useField} from "vee-validate";

    export default {
        name: "EonPassword",
        props: {
            name: {
                type: String,
            },
            width: {
                type: String,
            },
            label: {
                type: String,
            },
            color: {
                type: String,
            },
            modelValue: null,
            rules: undefined,
        },
        components: {Password},
        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            computedClass() {
                return this.errorMessage ? "invalid-input" : "";
            },
        },
        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                errorMessage,
                inputValue,
                handleBlur,
                handleChange,
                meta,
            };
        },
    };
</script>

<style lang="less">

@import "../assets/less/inputtext.less";
@import "../assets/less/eon-inputtext.less";
@import "../assets/less/eon-password.less";

</style>
