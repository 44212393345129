<template>
    <div class="public-layout-wrapper">
        <div class="main p-fluid">
            <router-view />
        </div>
    </div>
</template>

<script>
    export default {
        name: "LayoutPublic",

    };
</script>

<style lang="less">
</style>
