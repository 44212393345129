<template>
    <GoogleMap id="map" ref="GMap" :api-promise="apiPromise"
               style="width: 100%; height: 100%;"
               :scale-control="true" @idle="setupZoomControls"
               :center="polygonCenter !== null ? polygonCenter : center"
               :zoom="polygonZoom !== null ? polygonZoom : zoom" :streetViewControl="false" :fullscreenControl="false"
               :mapTypeControl="false" :styles="styles">
        <div v-if="plannedOutageArea[0].paths.length >= 2">
            <GPolygon v-for="area in plannedOutageArea" :options="area" :key="hashArea(area)"/>
        </div>
        <GMarker v-if="plannedOutageArea[0].paths.length < 2 && plannedOutageArea[0].paths.length > 0"
                 :options="markerOptions" />
    </GoogleMap>
</template>

<script>
    import {
        getPublicAreas,
    } from "@/swagger/vue-api-client";
    import {defineComponent} from "vue";
    import {GoogleMap, Marker as GMarker, Polygon as GPolygon} from "vue3-google-map";
    import hash from "object-hash";
    import {MapUtils} from "@/utils/MapUtils";
    import {Loader} from "@googlemaps/js-api-loader";
    import svgPlus from "@/components/form/svg/plus.svg";
    import svgMinus from "@/components/form/svg/minus.svg";

    export default defineComponent({
        name: "EonMapShowArea",

        props: {
            zoom: {
                type: Number,
                default: 11,
            },
            modelValue: null,
            canAddOutside: {
                default: false,
            },
            coords: null,
            coordsArr: null,
            coordsToRemove: null,
        },

        emits: ["emitApi", "update:modelValue", "convexHull"],

        data() {
            return {
                api: null,
                apiPromise: null,
                areas: this.null,
                newArea: [],
                plannedOutageArea: [
                    {
                        name: "PlannedOutageArea",
                        paths: [],
                        options: {
                            strokeColor: "#EA1B0A",
                            fillColor: "#F05B48",
                        },
                    },
                ],
                styles: [
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#9d9d9d",
                            },
                        ],
                    },
                    {
                        featureType: "landscape",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#f6f6f4",
                            },
                        ],
                    },
                    {
                        featureType: "poi",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#fdfdfd",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels",
                        stylers: [
                            {
                                visibility: "simplified",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        featureType: "transit",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#cad2d3",
                            },
                        ],
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#7a7a7a",
                            },
                        ],
                    },
                ],
                polygonCenter: null,
                polygonZoom: null,
            };
        },
        async beforeMount() {
            const loader = new Loader({
                apiKey: "AIzaSyBzgXAazWmBUNOesptSkmRKhA-EVDLE8DY",
                version: "weekly",
                libraries: ["places"],
            });
            this.apiPromise = loader.load();
            this.api = (await this.apiPromise).maps;
            this.$emit("emitApi", this.api);
            getPublicAreas().then((response) => {
                this.areas = MapUtils.adjustAreaOutlines(response.data);
            });
        },
        async mounted() {
            await this.setupZoomControls();
        },

        methods: {
            centerOfPolygon(points) {
                const lng = points.map((i) => i.lng);
                const lat = points.map((i) => i.lat);

                lng.sort();
                lat.sort();

                const lowX = lng[0];
                const highX = lng[lng.length - 1];
                const lowY = lat[0];
                const highY = lat[lat.length - 1];

                const centerX = lowX + ((highX - lowX) / 2);
                const centerY = lowY + ((highY - lowY) / 2);

                this.updateCenter({lng: centerX, lat: centerY});

                this.zoomCenter(lowX, highX);
            },
            orientation(p, q, r) {
                const val = (r.lat - q.lat) * (q.lng - p.lng) - (q.lat - p.lat) * (r.lng - q.lng);

                if (val === 0) return 0;
                return (val > 0) ? 1 : -1;
            },
            dist(p, q) {
                return Math.sqrt((q.lng - p.lng) ** 2 + (q.lat - p.lat) ** 2);
            },
            makeConvexHull(points) {
                const hull = [];
                let firstPoint;
                let nextPoint;

                points.sort((a, b) => a.lng - b.lng);

                const checkFirstPoint = points.filter((x) => x.lng === points[0].lng);

                if (checkFirstPoint.length > 1) {
                    checkFirstPoint.sort((a, b) => a.lat - b.lat);
                    [firstPoint] = checkFirstPoint;
                } else {
                    [firstPoint] = points;
                }

                while (firstPoint !== hull[0]) {
                    hull.push(firstPoint);

                    [nextPoint] = points;

                    for (let i = 0; i < points.length; i += 1) {
                        const o = this.orientation(firstPoint, nextPoint, points[i]);

                        if (nextPoint === firstPoint || o === 1
                            || (o === 0 && this.dist(firstPoint, points[i]) > this.dist(firstPoint, nextPoint))) {
                            nextPoint = points[i];
                        }
                    }
                    firstPoint = nextPoint;
                }
                this.plannedOutageArea[0].paths = hull;
                this.centerOfPolygon(hull);
                this.$emit("convexHull", hull);
            },
            updateCenter(coords) {
                this.polygonCenter = coords;
                this.$emit("update:modelValue", {lng: coords.lng, lat: coords.lat});
            },

            zoomCenter(lowX, highX) {
                if (highX - lowX === 0) {
                    this.polygonZoom = 19;
                    return;
                }

                const helpArr = [];

                for (let i = 0; i <= 22; i += 1) {
                    let x = 360;
                    x /= 2 ** i;
                    helpArr.push(x);
                }

                const widthDifference = highX - lowX;

                const closet = helpArr.reduce((a, b) => (Math.abs(b - widthDifference) < Math.abs(a - widthDifference)
                    ? b : a));

                if (this.plannedOutageArea[0].paths.length === 2) {
                    this.polygonZoom = helpArr.indexOf(closet) - 1;
                    return;
                }
                this.polygonZoom = helpArr.indexOf(closet) + 1;
            },

            hashArea(area) {
                return hash(area);
            },

            toggleVisibility(areaId) {
                const area = this.areas.find((el) => el.id === areaId);
                area.visible = !area.visible;
            },

            addMarker(event) {
                this.$emit("update:modelValue", {lng: event.latLng.lng(), lat: event.latLng.lat()});
            },

            addMarkerOutsideArea(event) {
                if (this.canAddOutside) {
                    this.addMarker(event);
                }
            },
            async setupZoomControls() {
                await setTimeout(() => {
                    Array.from(document.getElementsByClassName("gm-control-active")).forEach((button) => {
                        // Usunięcie tła.
                        button.parentElement.style.boxShadow = "";
                        button.parentElement.style.backgroundColor = "";

                        // Zmiana plusa/minusa na ten z figmy.
                        if (button.title === "Zoom in" || button.title === "Powiększ") {
                            Array.from(button.children).forEach((child) => {
                                child.src = svgPlus;
                            });
                        } else if (button.title === "Zoom out" || button.title === "Pomniejsz") {
                            Array.from(button.children).forEach((child) => {
                                child.src = svgMinus;
                            });
                        }
                        const iconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                        const iconPath = document.createElementNS(
                            "http://www.w3.org/2000/svg",
                            "path",
                        );

                        if (button.getAttribute("aria-label") === "Zoom in"
                            || button.getAttribute("aria-label") === "Powiększ") {
                            button.style.setProperty("top", "-4px");
                        }

                        iconSvg.setAttribute("fill", "none");
                        iconSvg.setAttribute("viewBox", "0 0 48 48");
                        iconSvg.setAttribute("width", "40");
                        iconSvg.setAttribute("height", "40");
                        iconPath.setAttribute(
                            "d",
                            // eslint-disable-next-line max-len
                            "M7.76825 1.544C6.22594 1.83684 4.80747 2.5871 3.69741 3.69716C2.58735 4.80723 1.83709 6.22569 1.54425 7.768C-0.51475 18.589 -0.51475 29.411 1.54425 40.232C1.83709 41.7743 2.58735 43.1928 3.69741 44.3028C4.80747 45.4129 6.22594 46.1632 7.76825 46.456C13.1184 47.4794 18.5531 47.9964 24.0002 48C29.4474 47.9964 34.8821 47.4794 40.2323 46.456C41.7746 46.1632 43.193 45.4129 44.3031 44.3028C45.4132 43.1928 46.1634 41.7743 46.4563 40.232C48.5153 29.411 48.5153 18.589 46.4563 7.768C46.1634 6.22569 45.4132 4.80723 44.3031 3.69716C43.193 2.5871 41.7746 1.83684 40.2323 1.544C34.8821 0.520449 29.4474 0.00349601 24.0002 0C18.5531 0.00349601 13.1184 0.520449 7.76825 1.544Z",
                        );
                        iconPath.setAttribute("clip-rule", "evenodd");
                        iconPath.setAttribute("fill-rule", "evenodd");
                        iconPath.setAttribute("fill", "#EA1B0A");
                        iconSvg.appendChild(iconPath);
                        button.appendChild(iconSvg);
                    });
                }, 2000);
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            markerOptions() {
                return {
                    position: this.plannedOutageArea[0].paths.length === 1
                        ? this.plannedOutageArea[0].paths[0] : this.coords,
                };
            },
            center() {
                return this.coords ? this.coords : {lat: 52.232222, lng: 21.008333};
            },
        },

        watch: {
            coords(value) {
                if (this.newArea.length === 0) {
                    this.newArea = [
                        {
                            name: "NewArea",
                            paths: [
                                {
                                    lat: value.lat,
                                    lng: value.lng,
                                },
                            ],
                        },
                    ];
                } else {
                    this.newArea[0].paths = [
                        ...this.newArea[0].paths,
                        {
                            lat: value.lat,
                            lng: value.lng,
                        },
                    ];
                }

                this.makeConvexHull(this.newArea[0].paths);
            },
            coordsArr(value) {
                this.newArea[0] = {paths: value};
                this.makeConvexHull(this.newArea[0].paths);
            },
            coordsToRemove(value) {
                if (this.newArea[0].paths.length === 1) {
                    this.newArea[0].paths = [];
                    this.plannedOutageArea[0].paths = this.newArea[0].paths;
                    return;
                }
                const newValue = {lat: value.lat, lng: value.lng};
                const index = this.newArea[0].paths.findIndex((el) => el.lat === newValue.lat);
                this.newArea[0].paths.splice(index, 1);
                this.makeConvexHull(this.newArea[0].paths);
            },
        },

        components: {
            GoogleMap,
            GPolygon,
            GMarker,
        },
    });
</script>

<style lang="less" scoped>
@import "@/assets/theme/variable.less";

.address-search {
    position: absolute;
    top: 10em;
    left: 5.1em;
    width: 320px;
    background: @trans-white-80;
    border-radius: 4px;
    color: @tint-grey-dark-01;
    text-align: left;
    padding: 0 1em 1.5em 1em;
}

.address-autocomplete {
    width: 100%;
}

</style>
