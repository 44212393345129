<template>
    <div>
        <div class="eon-confirm-email-field">
            <h2>
                {{ $t('message.register.confirmEmailMessage') }}
            </h2>
            <div>
                <router-link :to="{name: 'publicLogin'}">
                    <EonButton class="right-0 inverted turquoise" label="Zaloguj się"/>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {confirmEmail} from "@/swagger/vue-api-client";
    import EonButton from "@/components/form/EonButton";

    export default {
        name: "EonConfirmEmailView",
        components: {EonButton},

        beforeMount() {
            this.confirmEmail();
        },

        methods: {
            confirmEmail() {
                confirmEmail({confirmEmailToken: this.$route.query.confirmEmailToken}).then((response) => {
                    this.success = response.data;
                });
            },
        },
    };
</script>

<style lang="less" scoped>
@import "assets/less/eon-confirm-email.less";
</style>
