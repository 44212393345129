<template>
    <div>
        <EonSelectOne :items="items" :empty-value="emptyValue" :width="width"
                      v-model="internalValue" item-label="label" item-value="value" :label="label"
                      v-bind:allValues="false" :style="style"/>
    </div>
</template>

<script>
    import EonSelectOne from "./EonSelectOne";

    export default {
        name: "EonSelectOneDeletedOrCC",
        props: {
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            width: {
                type: String,
            },
            modelValue: null,
            selectType: {
                default: "",
            },
        },

        emits: ["update:modelValue"],

        computed: {
            style() {
                return "width: " + this.width + "px;";
            },
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            items() {
                if (this.selectType === "user") {
                    return this.deletedUsers;
                }
                if (this.selectType === "report") {
                    return this.deletedReports;
                }
                return this.callCenter;
            },
        },
        mounted() {
            if (this.allValues) {
                this.internalItems = [{value: null, label: this.$t("message.other.allValues")}, ...this.items];
            }
        },

        data() {
            return {
                deletedUsers: [
                    {label: "Usunięty", value: "true"},
                    {label: "Nieusunięty", value: "false"},
                ],
                deletedReports: [
                    {label: "Usunięte", value: "true"},
                    {label: "Nieusunięte", value: "false"},
                ],
                callCenter: [
                    {label: "Zgłoszone przez CC", value: "true"},
                    {label: "Zgłoszone przez sieć", value: "false"},
                ],
            };
        },

        components: {EonSelectOne},
    };
</script>

<style scoped>
</style>
