<template>
    <div :class="['eon-inputnumber-field', color, computedClass]">
        <span :class="['p-float-label', {'p-input-icon-right': computedIcon}, color]">
            <i :class="[{'eon-inputnumber-icon-on': computedIcon},{'eon-inputnumber-icon-off': !computedIcon}, icon]" />
            <InputNumber :id="name" v-model="internalValue" @input="change" :disabled="disabled"
                         :style="'width: ' + width + 'px;'" :useGrouping="false"/>
            <label :for="name">{{ label }}</label>
        </span>
        <span v-show="errorMessage" class="p-invalid">
            <i class="pi pi-exclamation-triangle" /> {{ errorMessage }}
        </span>
    </div>
</template>

<script>
    import InputNumber from "primevue/inputnumber";
    import {useField} from "vee-validate";

    export default {
        name: "EonInputNumber",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            width: {
                type: String,
            },
            icon: {
                type: String,
                default: null,
            },
            color: {
                type: String,
            },
            rules: undefined,
            modelValue: null,
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            change(v) {
                this.internalValue = v.value;
            },
        },

        emits: ["update:modelValue"],

        computed: {
            computedIcon() {
                return this.icon != null;
            },

            computedClass() {
                return this.errorMessage ? "invalid-input" : "";
            },

            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {InputNumber},
    };
</script>

<style lang="less">

@import "../assets/less/inputtext.less";
@import "../assets/less/float-label.less";

</style>
