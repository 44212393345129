<template>
    <RadioButton class="custom-radio-button" :name="name" :value="value" v-model="internalValue"/>
</template>

<script>
    import RadioButton from "primevue/radiobutton";

    export default {
        props: {
            value: {
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            modelValue: null,
        },
        emits: ["update:modelValue"],
        name: "EonRadioButton",
        components: {RadioButton},
        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="less">
@import "../assets/less/radio-button";
</style>
