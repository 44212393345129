<template>
    <div class="eon-checkbox-field">
        <div :class="computedClass">
            <Checkbox :id="name" v-model="internalValue" :binary="true" :disabled="disabled" />
            <label v-if="!noLabel" :for="name">{{ label }}</label>
        </div>
    </div>
</template>

<script>
    import Checkbox from "primevue/checkbox";

    export default {
        name: "EonCheckbox",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            noLabel: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            modelValue: null,
        },
        emits: ["update:modelValue"],
        components: {Checkbox},
        data() {
            return {
                checked: false,
            };
        },
        computed: {
            computedClass() {
                return this.checked ? "eon-checkbox-on" : "eon-checkbox-off";
            },
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="less">
@import "../assets/less/eon-checkbox.less";
</style>
