<template>
    <div class="add-report-error-field">
        <h2> Wystąpił błąd </h2>
        <p> Nie udało się dodać zgłoszenia </p>
        <router-link :to="{name: 'publicAddReportLocation'}">
            <EonButton class="turquoise-button" icon="pi pi-replay" extension="50" label="Spróbuj ponownie" />
        </router-link>

    </div>
</template>
<script>
    import EonButton from "@/components/form/EonButton";

    export default {
        name: "UserAddReportError",
        components: {EonButton},
    };
</script>
<style lang="less" scoped>
@import "../../../assets/theme/variable.less";

.add-report-error-field {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
    justify-content: center;
    align-items: center;
    width: 100%;
    h2 {
        color: @color-turquoise;
    }
}
</style>
