<template>
    <div class="custom-autocomplete-field" :style="'width: ' + width + 'px;'" id="address-autocomplete">
        <GMapAutocomplete
            class=" p-inputtext"
            :placeholder="placeholder"
            @place_changed="setPlace"
            :disabled="disabled"
            :options="{
                componentRestrictions: {country:'pl'},
                // fields: ['address_components', 'geometry', 'formatted_address'],
                bounds: {
                    north: 52.38333,
                    south: 52.08333,
                    east: 21.28333,
                    west: 20.84583,
                }}"
        >
        </GMapAutocomplete>
    </div>
</template>
<script>

    export default {
        name: "EonAddressAutocomplete",
        props: {
            name: {
                type: String,
            },
            placeholder: {
                type: String,
            },
            width: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            modelValue: null,
        },
        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("updateAddress", value);
                },
            },
        },
        components: {},
        data() {
            return {
                value: null,
                bounds: null,
            };
        },
        emits: ["updateAddress", "updateAddressDetails", "updateCoords"],
        methods: {
            setPlace(event) {
                this.$emit("updateAddress", event.formatted_address);
                this.$emit("updateAddressDetails", event.address_components);
                this.$emit("updateCoords", {
                    lat: event.geometry.location.lat(),
                    lng: event.geometry.location.lng(),
                });
            },
        },
    };

</script>

<style lang="less">
@import "../assets/less/autocomplete.less";
</style>
