<template>
    <div class="time-picker-field" :style="'width: ' + width + 'px;'">
        <span class="p-float-label" :style="'width: ' + width + 'px;'">
            <Calendar :style="'width: ' + width + 'px;'" :id="name" v-model="internalValue" :showTime="showTime"
                      @update:modelValue="handleChange" @blur="handleBlur" :showSeconds="false" :showIcon="true"
                      panelClass="time-picker-calendar" showButtonBar :placeholder="placeholder" autocomplete="off"
                      ref="eonCalendar" :minDate="minDate">
                <template #footer>
                    <PrimeButton class="calendar-apply-button" @click="closeCalendar">Zatwierdź</PrimeButton>
                </template>
            </Calendar>
            <label v-show="showLabel" :for="name">{{ label }}</label>
        </span>
        <span v-show="errorMessage" class="p-invalid">
            <i class="pi pi-exclamation-triangle" /> {{ errorMessage }}
        </span>
    </div>
</template>
<script>
    import Calendar from "primevue/calendar";
    import {useField} from "vee-validate";
    import PrimeButton from "primevue/button";

    export default {
        name: "EonTimePicker",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            width: {
                type: String,
            },
            minDate: {
                type: Date,
                default: null,
            },
            rules: undefined,
            modelValue: null,
            showTime: {
                type: Boolean,
                default: true,
            },
            showLabel: {
                type: Boolean,
                default: true,
            },
            placeholder: {
                type: String,
            },
        },

        methods: {
            closeCalendar() {
                this.$refs.eonCalendar.overlayVisible = false;
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },
        components: {Calendar, PrimeButton},
    };
</script>

<style lang="less">
@import "../assets/less/calendar.less";
@import "../assets/less/timepicker.less";
@import "../../assets//theme/variable.less";
.time-picker-field {
    flex-direction: column;
}
.time-picker-calendar {
    .p-datepicker-buttonbar {
        .p-button {
            background-color: transparent;
            border: none;
            color: @secondary-color;
            font-weight: bold;
        }
    }
    .calendar-apply-button {
        background-color: transparent;
        border: none;
        color: @secondary-color;
        font-weight: bold;
        position: absolute;
        bottom: 22px;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>
