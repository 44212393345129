<template>
    <div class="container">
        <EonPublicMap ref="map" class="map" @update:api="updateApi" :centerProp="centerProp"
                      :userLocation="userLocation" />
        <div class="address-search">
            <p> Szukaj wyłączeń/awarii </p>
            <PlacesAutocomplete class="address-autocomplete"
                                :google-maps-api="googleMapsApi"
                                placeholder="Adres"
                                @updateCoords="updateCenter"
                                :searchType="null"/>
            <EonIconButton icon="pi pi-search" />
        </div>
        <div class="legend-container">
            <EonIconButton :icon="show ? 'pi pi-chevron-left' : 'pi pi-book'" @click="toggleLegend" />
            <div class="legend">
                <div class="legend-inner-field">
                    <header class="header"> Legenda </header>
                    <div class="legend-item">
                        <img class="legend-icon" src="@/assets/notification_bell_cluster.svg" alt="Turquoise bell"/>
                        <p class="item-desc"> Zgłoszenie użytkownika </p>
                    </div>
                    <div class="legend-item">
                        <img class="legend-icon" src="@/assets/pylon_cluster.svg" alt="Claret pylon"/>
                        <p class="item-desc"> Planowane wyłączenie </p>
                    </div>
                    <div class="legend-item">
                        <img class="legend-icon" src="@/assets/lightning_cluster.svg" alt="Red lightning"/>
                        <p class="item-desc"> Potwierdzona awaria </p>
                    </div>
                </div>
            </div>
        </div>
        <EonIconButton class="map-type-control-button" icon="pi pi-map" @click="toggleMapTypeControl" medium/>
        <EonIconButton class="user-localize-button" icon="pi pi-map-marker" @click="locateUser" medium/>
        <div class="map-controls">
            <EonSlider v-model="duration" class="time-slider"
                       label="Zgłoszone w ciągu ostatnich" label-suff="h" :min=1 :max=24 />
        </div>
        <router-link :to="{name: 'publicAddReportLocation'}">
            <EonButton class="add-report-button" label="Dodaj zgłoszenie" icon="pi pi-plus" extension="50"/>
        </router-link>
    </div>
</template>

<script>
    import EonPublicMap from "@/components/EonPublicMap";
    import EonButton from "@/components/form/EonButton";
    import EonSlider from "@/components/form/EonSlider";
    import PlacesAutocomplete from "@/components/form/PlacesAutocomplete";
    import Button from "primevue/button";
    import EonIconButton from "../../components/form/EonIconButton";

    export default {
        name: "PublicMapView",
        components: {
            PlacesAutocomplete,
            EonSlider,
            EonPublicMap,
            EonButton,
            EonIconButton,
            // eslint-disable-next-line vue/no-reserved-component-names
            Button,
        },
        data() {
            return {
                centerProp: {lat: 52.232222, lng: 21.008333},
                duration: 24,
                googleMapsApi: null,
                userLocation: {
                    position: null,
                },
                show: false,
                showSidebar: false,
            };
        },
        beforeMount() {
            const {lng} = this.$route.params;
            const {lat} = this.$route.params;
            if (lng !== undefined && lat !== undefined) {
                this.centerProp = {lat: Number(lat), lng: Number(lng)};
            }
        },
        watch: {
            duration(newDuration) {
                const newDurationString = "PT" + newDuration + "H";
                this.$refs.map.reload(newDurationString);
            },
        },
        methods: {
            updateApi(api) {
                this.googleMapsApi = api;
            },
            updateCenter(coords) {
                this.$refs.map.updateCenter(coords);
            },
            toggleMapTypeControl() {
                if (this.$refs.map.$refs.GMap) {
                    const mapType = this.$refs.map.$refs.GMap.map.getMapTypeId();
                    this.$refs.map.$refs.GMap.map.setMapTypeId(mapType === "roadmap" ? "hybrid" : "roadmap");
                }
            },
            locateUser() {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            this.userLocation.position = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                        },
                    );
                }
            },
            toggleLegend() {
                if (!this.show) {
                    document.querySelector(".legend").style.display = "flex";
                    document.querySelector(".legend").style.transform = "translateX(0%)";
                    document.querySelector(".legend").style.trasition = "1s";
                    document.querySelector(".legend-container .eon-icon-button").style.transform = "translateX(296px)";
                    document.querySelector(".legend-container .eon-icon-button").style.right = "4px";
                    this.show = true;
                } else {
                    document.querySelector(".legend").style.transform = "translateX(-300px)";
                    document.querySelector(".legend-container .eon-icon-button").style.transform = "translateX(0)";
                    document.querySelector(".legend-container .eon-icon-button").style.right = "16px";
                    this.show = false;
                }
            },
        },
    };
</script>

<style lang="less" scoped>
@import "../../assets/theme/variable.less";

.container {
    position: relative;
    height: 100%;
    padding-top: 4.5rem;
}
.map {
    height: 100%;
    width: 100%;
}
.add-report-button {
    position: absolute;
    bottom: 2em;
    left: 2em;
}
.map-controls {
    position: absolute;
    top: 5em;
    right: 1em;
    .eon-icon-button {
        display: none;
    }
}
.legend {
    width: 284px;
    margin-top: 1em;
    border-radius: 4px;
    background: @trans-white-80;
    padding: 1em;
    color: @color-neutral-800;
    display: flex;
}
.legend-inner-field {
    flex-grow: 1;
    width: 0;
}
.legend-item {
    display: flex;
    justify-content: left;
    text-align: left;
    column-gap: 2em;
}
.legend-icon {
    width: 24px;
}
.item-desc {
    max-width: 80%;
}
.time-slider {
    width: 284px;
}
.address-search {
    top: 5em;
    position: absolute;
    left: 1em;
    width: 25%;
    background: @trans-white-80;
    border-radius: 4px;
    color: @color-neutral-800;
    text-align: left;
    padding: 0 1em 1.5em 1em;
    min-width: 284px;
    .eon-icon-button {
        display: none;
    }
}
.legend-container {
    width: 100%;
    max-width: 284px;
    position: absolute;
    top: calc(124px + 5em);
    left: 1em;
    display: inline-block;
    .eon-icon-button {
        display: none;
    }
}
.map-type-control-button {
    position: absolute;
    right: 3px;
    bottom: 148px;
    .button-icon {
        bottom: 50%;
        transform: translateY(25%);
    }
}
.user-localize-button {
    position: absolute;
    right: 3px;
    bottom: 103px;
    .button-icon {
        bottom: 50%;
        transform: translateY(25%);
    }
}
.address-autocomplete {
    width: 100%;
    height: 48px;

    & > input {
        height: 48px;
    }
}

@media screen and (max-width: 830px) {
    .map-controls {
        top: 6em;
    }
    .address-search {
        top: 6em;
    }
    .legend-container {
        //top: 14em;
        top: calc(124px + 6em);
    }
}

@media screen and (max-width: 700px) {
    .container {
        .eon-icon-button:focus {
            border: none;
        }
    }
    .add-report-button {
        left: 0.5em;
    }
    .address-search {
        padding: 0;
        left: 0;
        transform: translateX(-100%);
        width: 80%;
        transition: 1s;

        .eon-icon-button {
            display: block;
            position: absolute;
            top: -3px;
            right: -62px;
            transition: opacity 1s;
        }

        &:hover {
            transform: translateX(5%);
            & ~ .map-controls {
                opacity: 0;
                z-index: -9999;
            }
            .eon-icon-button {
                opacity: 0;
                transition: opacity 1s;
            }
        }

        & > p {
            display: none;
        }
    }

    .legend-container {
        top: 8em;
        transition: opacity 1s;
        // overflow: hidden;
        pointer-events: none;
        transform: translateX(-24px);
        left: 37px;
        .legend {
            width: 100%;
            transition: 1s;
            pointer-events: fill;
            transform: translateX(-300px);
            margin-top: 2em;
        }
        .eon-icon-button {
            display: block;
            position: fixed;
            margin-top: 2em;
            left: -5px;
            transition: 1s;
            pointer-events: fill;
            &:hover {
                transition: 1s;
            }
        }
    }
}
@media screen and (min-width: 701px) {
    .legend {
        transform: none !important;
    }
}
</style>
<style lang="less">
    .map-type-control-button {
        .button-icon {
            bottom: 50%;
            transform: translateY(25%);
        }
        &:enabled:focus {
            border: none;
        }
    }
    .user-localize-button {
        .button-icon {
            bottom: 50%;
            transform: translateY(25%);
        }
        &:enabled:focus {
            border: none;
        }
    }
</style>
