/* eslint-disable */
export const SystemRole = {
    ADMIN: "ADMIN",
    DISPATCHER: "DISPATCHER",
    ACCEPTER: "ACCEPTER",
    CALLCENTER: "CALLCENTER",
    PUBLIC: "PUBLIC",

    isAdmin() {
        return localStorage.getItem("role") === this.ADMIN;
    },

    isDispatcher() {
        return localStorage.getItem("role") === this.DISPATCHER;
    },

    isAccepter() {
        return localStorage.getItem("role") === this.ACCEPTER;
    },

    isCallCenter() {
        return localStorage.getItem("role") === this.CALLCENTER;
    },

    isPublic() {
        return localStorage.getItem("role") === this.PUBLIC;
    },

    getUserRole() {
        return localStorage.getItem("role");
    },

};
