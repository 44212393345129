<template>
    <h1>Planowane wyłączenia - panel Call Center</h1>
    <PlannedOutageReportCallCenterSearchCriteria ref="plannedOutageReportAcceptSearchCriteria" v-model="searchCriteria"
                                                 @update:modelValue="search"/>
    <PlannedOutageReportCallCenterTable ref="plannedOutageReportAcceptTable" :search-criteria="searchCriteria" />
</template>

<script>
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import PlannedOutageReportCallCenterTable
        from "@/views/plannedOutrageReportCallCenter/components/PlannedOutageReportCallCenterTable";
    import PlannedOutageReportCallCenterSearchCriteria
        from "@/views/plannedOutrageReportCallCenter/components/PlannedOutageReportCallCenterSearchCriteria";

    export default {
        name: "PlannedOutageReportCallCenterView",

        components: {
            PlannedOutageReportCallCenterSearchCriteria,
            PlannedOutageReportCallCenterTable,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.plannedOutageReportAcceptTable.search();
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

            getClearSearchCriteria() {
                return {
                    id: null,
                    addresses: {address: ""},
                    outageStart: null,
                    deleted: false,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style lang="less" scoped>

</style>
