<template>
    <div>
        <div class="eon-stop-send-email-field">
            <h2 v-if="responseStatus === 200">
                {{ $t('message.register.stopSendEmailSuccessMessage') }}
            </h2>
            <h2 v-else>
                {{ $t('message.register.stopSendEmailErrorMessage') }}
            </h2>
            <div>
                <router-link :to="{name: 'publicMapView'}">
                    <EonButton class="right-0 inverted turquoise" label="Widok główny"/>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import EonButton from "@/components/form/EonButton";
    import {unsubscribeFromEmailNotifications} from "@/swagger/vue-api-client";

    export default {
        name: "EonStopSendEmailView",
        components: {EonButton},

        data() {
            return {
                responseStatus: null,
            };
        },

        beforeMount() {
            this.stopSendEmail();
        },

        methods: {
            stopSendEmail() {
                unsubscribeFromEmailNotifications({hash: this.$route.query.emailConsentToken})
                    .then((response) => {
                        this.responseStatus = response.status;
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
@import "assets/less/eon-stop-send-email.less";
</style>
