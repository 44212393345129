<template>
    <div class="profile-view">
        <h1>Moje konto</h1>
        <div v-if="token">
            <div class="user-field">
                <p class="field-label">Rola: </p>
                <p>{{ getSystemRoleName(role) }}</p>
            </div>
            <div class="user-field">
                <p class="field-label">Imię i nazwisko: </p>
                <p>{{ fullName }}</p>
            </div>
            <div class="user-field">
                <p class="field-label">E-mail: </p>
                <p>{{ email }}</p>
            </div>
            <div class="user-field">
                <p class="field-label">Numer telefonu: </p>
                <p>{{phone === "null" ? "Nie podano numeru telefonu." : phone }}</p>
            </div>
        </div>
        <div v-else>
            <h2 style="text-align: left;">Nie jesteś zalogowany.</h2>
        </div>
    </div>
</template>

<script>

    export default {
        name: "UserFormView",

        components: {
        },

        props: {
            value: {},
        },

        data() {
            return {
                loaded: false,
                role: localStorage.getItem("role"),
                area: localStorage.getItem("area"),
                email: localStorage.getItem("email"),
                phone: localStorage.getItem("phone"),
                fullName: localStorage.getItem("fullName"),
                token: localStorage.getItem("token"),
            };
        },
        methods: {
            getSystemRoleName(role) {
                switch (role) {
                    case "ADMIN":
                        return "Administrator";
                    case "DISPATCHER":
                        return "Mistrz Prac Planowych";
                    case "ACCEPTER":
                        return "Akceptujący";
                    default:
                        return "Pracownik Call Center";
                }
            },
        },
    };
</script>

<style lang="less" scoped>

    .profile-view {
        height: 100%;
        width: 100%;
        padding-top: 1%;
        padding-right: 5%;
        padding-left: 5%;
        display: flex;
        flex-direction: column;
        gap: 1%;

        .user-field {
            display: flex;
            gap: 1%;
            width: 100%;

            .field-label {
                font-weight: bold;
            }
        }

        h1 {
            margin-left: 0;
        }
    }

</style>
