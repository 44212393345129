<template>
    <div class="custom-select-one" :style="'width: ' + width + 'px;'">
        <EonDropdown :items="internalItems" :itemLabel="itemLabel" :itemValue="itemValue" :width="width"
                     :placeholder="internalValue ? '' : label" :name="name" v-model="internalValue"
                     :filter="filter"/>
    </div>
</template>

<script>
    import Dropdown from "primevue/dropdown";
    import {useField} from "vee-validate";
    import EonDropdown from "@/components/form/EonDropdown";

    export default {
        name: "EonSelectOne",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            itemLabel: {
                type: String,
            },
            itemValue: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            items: {
                type: Array,
            },
            modelValue: null,
            rules: undefined,
            width: {
                type: String,
            },
            filter: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:modelValue", "change"],

        data() {
            return {
                internalItems: this.items,
                selectedValue: null,
            };
        },

        mounted() {
            if (this.emptyValue) {
                this.internalItems = [{value: null, label: this.$t("message.other.emptyValue")}, ...this.items];
            }
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            onChange(val) {
                if (val && val.value) {
                    this.handleChange(val.originalEvent);
                    this.$emit("change", val.value);
                } else {
                    this.handleChange(undefined);
                    this.$emit("change", undefined);
                }
            },
        },

        components: {EonDropdown, Dropdown},

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

    };
</script>

<style lang="less" scoped>
@import "../../assets/theme/variable.less";
.custom-select-one {
    margin-bottom: 0;
    position: relative;

    .p-dropdown {
        width: 100%;
        text-align: left;
    }
}
</style>
