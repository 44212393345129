<template>
    <div class="eon-text-area" :style="'width: ' + width + 'px;'">
        <PrimeTextarea :id="name" v-model="internalValue" :placeholder="label" :rows="rows"
                       :cols="columns" :autoResize="autoResize" />
    </div>
</template>
<script>
    import PrimeTextarea from "primevue/textarea";
    import {useField} from "vee-validate";

    export default {
        name: "EonInputTextArea",
        props: {
            name: {
                type: String,
            },
            rows: {
                type: Number,
                default: 1,
            },
            columns: {
                type: Number,
                default: 30,
            },
            autoResize: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
            },
            width: {
                type: String,
            },
            modelValue: null,
        },
        emits: ["update:modelValue"],
        components: {PrimeTextarea},
        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },
    };
</script>

<style lang="less">

@import "../assets/less/inputtext.less";
@import "../assets/less/eon-inputtext.less";

</style>
