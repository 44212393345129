<template>
    <div class="slider-field" :style="'width: ' + width + 'px;'">
        <label v-if="internalValue" :for="name">{{ label }}: {{ computedValue }} {{labelSuff}} </label>
        <label v-else :for="name"> Wybierz czas zgłoszenia: </label>
        <Slider :id="name" v-model="internalValue" :min="min" :max="max" :step="step" />
    </div>

</template>
<script>
    import Slider from "primevue/slider";

    export default {
        name: "EonSlider",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            labelSuff: {
                type: String,
            },
            width: {
                type: String,
            },
            min: {
                type: Number,
                default: 0,
            },
            max: {
                type: Number,
            },
            step: {
                type: Number,
            },
            modelValue: null,
        },
        components: {Slider},
        data() {
            return {
                value: this.max,
            };
        },
        emits: ["update:modelValue"],
        computed: {
            computedValue() {
                if (this.internalValue == null) {
                    return this.max;
                }
                return this.internalValue;
            },
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="less">
@import "../assets/less/slider.less";
</style>
