<template>
    <div class="view">
        <Toast />
        <h1 v-if="this.edit">Edytuj planowane wyłączenie</h1>
        <h1 v-else>Dodaj planowane wyłączenie</h1>
        <div class="add-edit-form-container">
            <VeeForm v-if="loaded" @submit="saveReport" @keydown.enter="$event.preventDefault()"
                     v-slot="{ errors }" class="form">
                <div class="field">
                    <p>Czas rozpoczęcia</p>
                    <EonTimePicker class="input-width-set" name="outageStart" rules="required"
                                   v-model="report.outageStart" />
                </div>
                <div class="field">
                    <p>Czas zakończenia</p>
                    <EonTimePicker class="input-width-set" name="outageEnd" v-model="report.outageEnd"
                                   rules="required" />
                </div>
                <div class="field">
                    <EonButton name="importExcel" @click="importFile" extension="80"
                               label="Importuj plik Excel" type="button"/>
                    <input type="file" ref="file" v-show="false" v-on:change="handleFile"
                           id="importExcel" accept=".xlsx, .xls" multiple />
                    <label class="hidden" for="importExcel">Importuj plik Excel</label>
                    <EonButton name="addClipboard" @click="getClipboardAddressData" extension="80"
                               label="Dodaj adresy ze schowka" type="button"/>
                    <label class="hidden" for="addClipboard">Dodaj adresy ze schowka</label>
                </div>
                <div class="field">
                    <p>Adres</p>
                    <EonInputText class="input-width-set" name="addAddress" :addButton="true"
                                  @takeAddress="addAddress" v-model="inputAddress"/>
                </div>
                <div class="field">
                    <DataTable name="addressesList" :value="report.addresses" tableStyle="height: 160px"
                               scrollable scrollHeight="160px" class="addresses-table input-width-set">
                        <Column field="address" style="flex-grow: 8;">
                            <template #body="slotProps">
                                <b>{{ slotProps.data.nonFormattedAddress }}</b> - {{ slotProps.data.street }}
                                {{ slotProps.data.wesolaDistrict ? "(W)" : "" }}
                                {{ slotProps.data.streetNumber }}{{ ", " + slotProps.data.postcode }}
                            </template>
                        </Column>
                        <Column style="justify-content: flex-end;">
                            <template #body="slotProps">
                                <PrimeButton class="outages-button outages-button-trash"
                                             @click="removeAddress(api, slotProps.data)" />
                            </template>
                        </Column>
                        <template #empty>
                            Brak dodanych adresów
                        </template>
                    </DataTable>
                </div>
                <div class="field">
                    <p>Prace do wykonania</p>
                    <EonInputTextArea class="input-width-set" name="comment" :rows="8" :autoResize="true"
                                      v-model="report.comment" />
                </div>
                <div class="field">
                    <p>Data ogłoszenia</p>
                    <EonTimePicker class="input-width-set" name="outageAnnouncement" :showTime="false"
                                   v-model="report.outageAnnouncement" rules="required" />
                </div>
                <div class="field">
                    <p>Napięcie</p>
                    <EonSelectOne name="voltage" v-model="report.voltageLevel" rules="required" class="input-width-set"
                                  :items="voltages" itemLabel="name" itemValue="value" :emptyValue="false" />
                </div>
                <div class="field">
                    <p>Nr SAP/CallNet</p>
                    <EonInputText class="input-width-set" name="taskNumber"
                                  v-model="report.taskNumber" />
                </div>
                <div class="buttons">
                    <EonButton label="Zapisz" type="submit" :disabled="isDisabled(errors)"/>
                    <EonOutlinedButton label="Anuluj" type="button" @click="goBack" />
                </div>
            </VeeForm>
            <div class="map">
                <EonMapShowArea @emitApi="takeApi" :coords="coords"
                                @convexHull="saveConvexHull"
                                :coordsArr="coordsArr"
                                :coordsToRemove="coordsToRemove" />
            </div>
        </div>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import Papa from "papaparse";
    import {Form as VeeForm} from "vee-validate";
    import {
        addOrEditPlannedOutageReport as createReport,
        getPlannedOutageReportById as getReport,
        deletePlannedOutageReport as deleteReport,
        getAreaByCoordinates as getArea,
        importAddressList as importAddressListExcel,
    } from "@/swagger/vue-api-client";
    import EonTimePicker from "@/components/form/EonTimePicker";
    import EonInputTextArea from "@/components/form/EonInputTextArea";
    import EonButton from "@/components/form/EonButton";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import EonInputText from "@/components/form/EonInputText";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import PrimeButton from "primevue/button";
    import {StatusCodes} from "@/utils/StatusCodes";
    import {TimeUtils} from "@/utils/TimeUtils";
    import EonMapShowArea from "@/components/EonMapShowArea";
    import EonSelectOne from "../../components/form/EonSelectOne";

    export default {
        name: "PlannedOutageReportMasterAddEditView",

        components: {
            VeeForm,
            EonTimePicker,
            EonInputTextArea,
            EonButton,
            EonOutlinedButton,
            EonInputText,
            DataTable,
            Column,
            PrimeButton,
            Toast,
            EonMapShowArea,
            EonSelectOne,
        },

        props: {
            value: {},
            edit: {
                type: Boolean,
            },
        },

        data() {
            return {
                report: {
                    id: null,
                    taskNumber: null,
                    outageStart: null,
                    outageEnd: null,
                    outageAnnouncement: null,
                    comment: null,
                    type: "PLANNED",
                    addresses: [],
                    points: [],
                    recipient: null,
                },
                inputAddress: "",
                loaded: false,
                showErrorMessage: false,
                api: null,
                coords: null,
                coordsArr: [],
                coordsToRemove: null,
                importAddressList: [],
                voltages: [
                    {name: "nN", value: "LV"},
                    {name: "SN", value: "MV"},
                    {name: "WN", value: "HV"},
                ],
                recipients: [
                    {name: "SAP", value: "SAP"},
                    {name: "CallNet", value: "CallNet"},
                ],
                showToast: false,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getReport({id: this.$route.params.id}).then((response) => {
                    this.report = response.data;
                    const helpCoorArr = [];
                    this.report.addresses.forEach((address) => {
                        helpCoorArr.push(address.coordinates);
                    });
                    this.coordsArr = helpCoorArr;
                    if (response.data.outageStart) {
                        this.report.outageStart = new Date(response.data.outageStart);
                    }
                    if (response.data.outageEnd) {
                        this.report.outageEnd = new Date(response.data.outageEnd);
                    }
                    if (response.data.outageAnnouncement) {
                        this.report.outageAnnouncement = new Date(response.data.outageAnnouncement);
                    }
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
            }
        },

        mounted() {
            this.importAddressList = Object.values(this.$route.query);

            setTimeout(() => {
                if (this.api) {
                    const inputField = document.querySelector("#addAddress");
                    this.autocomplete = new this.api.places.Autocomplete(inputField, {
                        fields: ["formatted_address", "geometry", "address_components"],
                        types: ["address"],
                        bounds: {
                            north: 52.38333,
                            south: 52.08333,
                            east: 21.28333,
                            west: 20.84583,
                        },
                        strictBounds: true,
                    });
                    // this.autocomplete.addListener("place_changed", this.setPlace);
                }
            }, 100);
        },

        methods: {
            importFile(e) {
                e.preventDefault();
                this.$refs.file.click();
            },
            handleFile() {
                for (let i = 0; i < this.$refs.file.files.length; i += 1) {
                    const formData = new FormData();
                    formData.append("file", this.$refs.file.files[i]);

                    importAddressListExcel({body: formData}).then((response) => {
                        this.addressList = response.data;
                        for (let j = 0; j < this.addressList.length; j += 1) {
                            this.coorFromAddress(this.api, this.addressList[j]);
                        }
                    });
                }
            },
            getClipboardAddressData() {
                navigator.clipboard.readText().then((addressCSV) => {
                    Papa.parse(addressCSV, {
                        complete: (results) => {
                            if (results.errors.length > 0 && results.data.length === 0) {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Niepoprawny format danych",
                                    life: 2500,
                                });
                                return;
                            }
                            const addressColumnIndex = results.data[0].indexOf("[PPE] Adres");
                            if (addressColumnIndex === -1) {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Niepoprawny format danych",
                                    life: 2500,
                                });
                                return;
                            }
                            for (let i = 1; i < results.data.length; i += 1) {
                                this.coorFromAddress(this.api, results.data[i][addressColumnIndex]);
                            }
                        },
                    });
                });
            },
            saveConvexHull(value) {
                this.report.points = value;
            },
            coorFromAddress(googleMapsApi, address) {
                console.log(address);
                if (!address) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Nie wpisano żadnego adresu",
                        life: 2500,
                    });
                    return;
                }
                let warsawAddress = address.toUpperCase();
                if (!(warsawAddress.indexOf("WARSZAWA") > -1)) {
                    this.warsawAddress += " Warszawa";
                }
                if (warsawAddress.indexOf("(W)") > -1) {
                    warsawAddress = warsawAddress.replace("(W)", "Wesoła");
                }
                if (googleMapsApi) {
                    this.geocoder = new googleMapsApi.Geocoder();
                    this.geocoder.geocode({address: warsawAddress}, (results) => {
                        let reportAddress = {
                            street: "",
                            streetNumber: "",
                            postcode: "",
                            city: "",
                            district: "",
                            wesolaDistrict: false,
                        };
                        if (results === null) {
                            this.report.addresses.push({
                                ...reportAddress,
                                nonFormattedAddress: address,
                                coordinates: {
                                    lng: null,
                                    lat: null,
                                },
                            });
                            return;
                        }
                        const coordinates = {
                            lng: results[0].geometry.location.lng(),
                            lat: results[0].geometry.location.lat(),
                        };
                        for (let i = 0; i < results[0].address_components.length; i += 1) {
                            reportAddress = {
                                street: results[0].address_components[i].types.includes("route")
                                    ? results[0].address_components[i].long_name : reportAddress.street,
                                streetNumber: results[0].address_components[i].types.includes("street_number")
                                    ? results[0].address_components[i].long_name : reportAddress.streetNumber,
                                postcode: results[0].address_components[i].types.includes("postal_code")
                                    ? results[0].address_components[i].long_name : reportAddress.postcode,
                                district: results[0].address_components[i].types.includes("sublocality")
                                    ? results[0].address_components[i].long_name : reportAddress.district,
                                city: results[0].address_components[i].types.includes("locality")
                                    ? results[0].address_components[i].long_name : reportAddress.city,
                                wesolaDistrict: results[0].address_components[i].types.includes("sublocality")
                                    && results[0].address_components[i].long_name === "Wesoła"
                                    ? true : reportAddress.wesolaDistrict,
                            };
                        }
                        // case when Google has not found street number (happens occasionally)
                        if (reportAddress.streetNumber === undefined || reportAddress.streetNumber.length === 0) {
                            reportAddress.streetNumber = this.extractStreetNumber(warsawAddress);
                        }
                        this.report.addresses.push({
                            ...reportAddress,
                            nonFormattedAddress: address,
                            coordinates: {
                                lng: results[0].geometry.location.lng(),
                                lat: results[0].geometry.location.lat(),
                            },
                        });
                        this.takeCoords(coordinates);
                    });
                }
            },
            extractStreetNumber(addressString) {
                const addressStringWithoutPostalCode = addressString.replace(/[0-9]{2}-[0-9]{3}/, "");
                const addressPartsArray = addressStringWithoutPostalCode.replace(",", "").replace(";", "").split(" ");
                let streetNumber = "";
                // take the last part of address starting with digit
                for (let i = addressPartsArray.length - 1; i >= 0; i -= 1) {
                    if (addressPartsArray[i].match(/^\d/)) {
                        streetNumber = addressPartsArray[i];
                        break;
                    }
                }
                return streetNumber;
            },
            takeApi(value) {
                this.api = value;
                if (this.importAddressList.length > 0) {
                    for (let i = 0; i < this.importAddressList.length; i += 1) {
                        this.coorFromAddress(this.api, this.importAddressList[i]);
                    }
                }
            },
            takeCoords(value) {
                this.coords = value;
            },
            async saveReport() {
                if (!this.validateDates()) {
                    return;
                }
                if (this.edit) {
                    deleteReport({id: this.$route.params.id});
                    this.report.id = null;
                }

                this.report = {...this.report, type: "PLANNED", acceptStatus: null};
                // eslint-disable-next-line max-len
                createReport({body: TimeUtils.adjustRequestBody(this.report)}).then(() => {
                    this.$toast.removeAllGroups();
                    this.$toast.add({
                        severity: "success",
                        summary: "Zapisano dane planowanego wyłączenia",
                        life: 2500,
                    });
                    this.showToast = true;
                    this.goBack();
                })
                    .catch((error) => {
                        this.$toast.removeAllGroups();
                        if (error.response.status === StatusCodes.FORBIDDEN) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Nie masz wystarczających uprawnień",
                                life: 2500,
                            });
                        } else {
                            this.$toast.add({
                                severity: "error",
                                summary: "Wystąpił nieoczekiwany błąd",
                                life: 2500,
                            });
                        }
                    });
            },
            isDisabled(errors) {
                return (!(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype));
            },
            goBack() {
                if (this.showToast) {
                    this.$router.push({name: "plannedMaster", params: {showToast: "show"}});
                    this.showToast = false;
                } else {
                    this.$router.push({name: "plannedMaster", params: {showToast: "hide"}});
                }
            },
            addAddress(value) {
                const inputField = document.querySelector("#addAddress");
                this.coorFromAddress(this.api, inputField.value);
                if (value !== "" && value !== null && value !== undefined) {
                    this.inputAddress = "";
                }
            },
            removeAddress(googleMapsApi, value) {
                // TODO to raczej nie powinno tak działać..
                if (googleMapsApi) {
                    this.geocoder = new googleMapsApi.Geocoder();
                    this.geocoder.geocode({address: value.nonFormattedAddress}, (results) => {
                        if (results === null) return;
                        const coordinates = {
                            lng: results[0].geometry.location.lng(),
                            lat: results[0].geometry.location.lat(),
                        };
                        this.coordsToRemove = coordinates;
                    });

                    const index = this.report.addresses.indexOf(value);
                    this.report.addresses.splice(index, 1);
                }
            },
            updateCoordinates(value) {
                this.coordinates = value;
                getArea({body: value}).then((response) => {
                    this.pointOutOfBounds = false;
                    this.area = response.data.name;
                }).catch((error) => {
                    if (error.response.status === StatusCodes.NOT_FOUND) {
                        this.area = null;
                        this.pointOutOfBounds = true;
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Wystąpił nieoczekiwany błąd",
                            life: 2500,
                        });
                    }
                });
            },
            validateDates() {
                // TODO przerzucić do VeeValidate - to jest quickfix
                if (this.report.outageStart > this.report.outageEnd) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Data lub godzina zakończenia jest wcześniejsza niż rozpoczęcia",
                        life: 2500,
                    });
                    this.showErrorMessage = true;
                    return false;
                }
                return true;
            },
        },

        watch: {
            api() {
                setTimeout(() => {
                    const inputField = document.querySelector("#addAddress");
                    this.autocomplete = new this.api.places.Autocomplete(inputField, {
                        fields: ["formatted_address", "geometry", "address_components"],
                        types: ["address"],
                        componentRestrictions: {country: "pl"},
                        bounds: {
                            north: 52.38333,
                            south: 52.08333,
                            east: 21.28333,
                            west: 20.84583,
                        },
                        strictBounds: true,
                    });
                    // this.autocomplete.addListener("place_changed", this.setPlace);
                }, 100);
            },
        },
    };
</script>

<style lang="less" scoped>
    .add-edit-form-container {
        width: 100%;
        display: flex;
        gap: 2%;
        justify-content: center;
        margin-bottom: 40px;
        .input-width-set {
            width: 510px;
            @media screen and (max-width: 1480px) {
                width: 80%;
            }
        }
        .map {
            width: 40%;
            height: 700px;
        }
        .form {
            width: 42%;
            .buttons {
                display: flex;
                justify-content: center;
                margin-left: 220px;
                gap: 40px;
            }
            .field {
                gap: 0;
                align-items: flex-start;
                justify-content: flex-end;
                display: flex;
            }
            .field-with-button {
                gap: 10px;
            }
            p {
                margin-right: 10px;
            }
            .outages-button {
                background-color: transparent;
                border: none;
                width: 24px;
                height: 24px;
                &-trash {
                    background-image: url("@/assets/trash_bin.svg");
                }
            }
        }
    }
</style>

<style lang="less">
    @import "../../assets/theme/variable.less";
    .addresses-table {
        border: 2px solid @color-neutral-500;
        border-radius: 8px;
        div.p-datatable-wrapper {
            overflow-x: hidden;
            max-width: 506px;
            &:hover::-webkit-scrollbar {
                width: 10px;
            }
            table {
                thead {
                    display: none;
                }
                tr {
                    border: none;
                    border-radius: 8px;
                    max-width: 496px;
                    td {
                        padding: 0.5rem 1rem;
                    }
                }
            }
        }
    }
    .add-edit-form-container {
        .eon-text-area {
            height: 160px;
            textarea {
                height: 160px !important;
            }
        }
        .p-dropdown .p-dropdown-trigger {
            width: 48px;
        }
    }
</style>
