<template>
    <Toast/>
    <div>
        <DataTable ref="userTable" :value="users" :lazy="true" :paginator="true"
                   v-model:rows="searchParams.page.limit" v-model:first="searchParams.page.offset"
                   :totalRecords="totalRecords" :loading="loading" :search-criteria="searchCriteria"
                   @page="onPage" @sort="onPage" removableSort
                   class="outages-table" :rows="10" :rowsPerPageOptions="[5,10,20,50]"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink
                   LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Elementy na stronie"
                   responsiveLayout="scroll">
            <template #loading>
                Ładowanie użytkowników. Proszę czekać.
            </template>
            <template #empty>
                Nie znaleziono użytkowników.
            </template>
            <Column field="id" header="Numer" :sortable="true"/>
            <Column header="Rola" :sortable="true">
                <template #body="slotProps">
                    {{ getSystemRoleName(slotProps.data.systemRole) }}
                </template>
            </Column>
            <Column field="fullName" header="Imię i nazwisko" :sortable="true"/>
            <Column field="email" header="E-mail" :sortable="true"/>
            <Column field="phone" header="Numer telefonu"/>
            <Column>
                <template #body="slotProps">
                    <router-link :to="{name: 'userEditByAdmin', params: {'id': slotProps.data.id}}">
                        <EonIconButton class="red" :withSvg="false" icon="pi pi-pencil"/>
                    </router-link>
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <EonDeleteOrRefreshPopup v-if="slotProps.data.deleted" v-bind:icon-button="true" icon-color="red"
                                             icon="pi pi-refresh" :entity-id="slotProps.data.id" :reload="search"
                                             entityType="user"/>
                    <EonDeleteOrRefreshPopup v-else v-bind:icon-button="true" icon-color="red" entityType="user"
                                             icon="pi pi-trash" :entity-id="slotProps.data.id" :reload="search"/>
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <EonIconButton class="red" :withSvg="false" icon="pi pi-envelope"
                                   @click="disableEmailNotification(slotProps.data.id)"/>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import {
        searchUser,
        searchUserCount,
        sendUnsubscribeEmailToUser,
    } from "@/swagger/vue-api-client";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Row from "primevue/row";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import Toast from "primevue/toast";
    import EonIconButton from "@/components/form/EonIconButton";
    import {SystemRole} from "@/utils/SystemRole";
    import EonDeleteOrRefreshPopup from "@/components/form/EonDeleteOrRefreshPopup";

    export default {
        name: "UserTable",
        components: {
            EonDeleteOrRefreshPopup,
            EonIconButton,
            Column,
            Row,
            DataTable,
            Toast,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        data() {
            return {
                deleted: false,
                loading: false,
                totalRecords: 0,
                users: [],
                Role: SystemRole,
            };
        },

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            disableEmailNotification(id) {
                sendUnsubscribeEmailToUser({id}).then(() => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Wysłano mail do wyłączenia powiadomień",
                        life: 5000,
                        closable: false,
                    });
                });
            },
            getSystemRoleName(role) {
                switch (role) {
                    case "ADMIN":
                        return "Administrator";
                    case "DISPATCHER":
                        return "Mistrz prac planowych";
                    case "CALLCENTER":
                        return "Call Center";
                    default:
                        return "Akceptujący";
                }
            },
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchUser({body: this.searchCriteria}).then((response) => {
                    this.users = response.data;
                    this.loading = false;
                });
                SearchCriteria.updateUrl(this);
            },

            updateTotalRecords() {
                searchUserCount({body: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },
        },

    };
</script>

<style lang="less" scoped>
@import "../../../components/assets/less/datatable.less";
@import "../../assets/less/eon-table.less";
</style>
