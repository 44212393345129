<template>
    <p class="recaptcha-info"> Tę stronę chroni rozwiązanie reCAPTCHA. Obowiązuje
        <a href="https://policies.google.com/privacy?hl=pl">Polityka Prywatności</a> oraz
        <a href="https://policies.google.com/terms?hl=pl">Regulamin</a> Google.
    </p>
</template>

<script>
    export default {
        name: "AddUserReportDetails",
    };
</script>

<style scoped lang="less">
@import "../../assets/theme/variable.less";

.recaptcha-info {
    color: @color-grey-middle;
    width: 100%;
    text-align: center;
    background-color: white;
    border-radius: 4px;
    padding: 2px 4px;
}
</style>
