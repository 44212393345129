import {createApp, h} from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import ToastService from "primevue/toastservice";
import PrimeVue from "primevue/config";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import {createI18n} from "vue-i18n";
import VueCookies from "vue-cookies";
import {VueReCaptcha} from "vue-recaptcha-v3";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import router from "./router";
import store from "./store";
import App from "./App";
import {setDomain, isTokenValid} from "./swagger/vue-api-client";
import "./assets/theme/theme.css";
import {SystemRole} from "./utils/SystemRole";
import {messagesEN} from "./messages/messages_en";
import {messagesPL} from "./messages/messages_pl";
import "./validators";

setDomain(process.env.VUE_APP_API_URL);

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        isTokenValid().then(() => {
            // token jest ważny, teraz należy sprawdzić role
            const role = SystemRole.getUserRole();

            if (to.matched.some((record) => record.meta.roles && record.meta.roles.indexOf(role) < 0)) {
                // użytkownik nie ma odpowiedniej roli aby wejść na daną stronę
                next({name: "forbidden"});
            } else {
                next();
            }
        }, () => {
            localStorage.removeItem("token");
            if (to.meta.roles.includes("PUBLIC")) {
                next({name: "publicLogin"});
            } else {
                next({name: "login"});
            }
        }).catch(() => {
            localStorage.removeItem("token");
            next({name: "login"});
        });
    } else {
        next();
    }
});

const messages = {
    EN: {
        message: messagesEN,
    },
    PL: {
        message: messagesPL,
    },
};

// Create VueI18n instance with options
const i18n = createI18n({
    locale: localStorage.getItem("language") ? localStorage.getItem("language") : "PL",
    messages,
});

const app = createApp({
    i18n,
    render: () => h(App),
    }).use(router)
    .use(store)
    .use(VueGoogleMaps, {
        load: {
            key: "AIzaSyBzgXAazWmBUNOesptSkmRKhA-EVDLE8DY",
            libraries: "places",
        },
    })
    .use(VueReCaptcha, {siteKey: "6LeAuhgiAAAAAGnxCl9pBpCIl0E5I-XbxI5KNoyu"})
    .use(VueAxios, axios)
    .use(ToastService)
    .use(VueCookies)
    .use(i18n)
    .use(PrimeVue, {
        locale: {
            startsWith: "Starts with",
            contains: "Zawiera",
            notContains: "Nie zawiera",
            endsWith: "Ends with",
            equals: "Equals",
            notEquals: "Not equals",
            noFilter: "No Filter",
            lt: "Less than",
            lte: "Less than or equal to",
            gt: "Greater than",
            gte: "Greater than or equal to",
            dateIs: "Date is",
            dateIsNot: "Date is not",
            dateBefore: "Date is before",
            dateAfter: "Date is after",
            clear: "Wyczyść",
            apply: "Apply",
            matchAll: "Match All",
            matchAny: "Match Any",
            addRule: "Add Rule",
            removeRule: "Remove Rule",
            accept: "Yes",
            reject: "No",
            choose: "Choose",
            upload: "Upload",
            cancel: "Cancel",
            dayNames: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
            dayNamesShort: ["Nd", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"],
            dayNamesMin: ["Nd", "Pn", "Wt", "Śr", "Czw", "Pt", "Sb"],
            // eslint-disable-next-line max-len
            monthNames: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec",
                "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
            monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            today: "Dzisiaj",
            weekHeader: "Wk",
            firstDayOfWeek: 1,
            dateFormat: "dd/mm/yy",
            weak: "Weak",
            medium: "Medium",
            strong: "Strong",
            passwordPrompt: "Enter a password",
            emptyFilterMessage: "No results found",
            emptyMessage: "No available options",
        },
    })
    .mount("#app");

app.axios.interceptors.request.use(async (config) => {
    if (localStorage.getItem("token") !== null) {
        config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }

    return config;
});
