<template>
    <div class="add-report-description">
        <h2>Zgłoszenie dotyczy:</h2>
        <p><b>Braku zasilania</b></p>
        <!--        <EonCheckbox class="sub-checkbox" v-model="isNoVoltage" @click="uncheckOthers('noVoltage')"-->
        <!--                     :label="($t('message.addReport.noVoltage'))"/>-->
        <EonCheckbox class="sub-sub-checkbox" v-model="partialVoltageLack"
                     :label="($t('message.addReport.partialVoltageLack'))"
                     @click="uncheckOthers('partialVoltageLack', 'LIMITED_VOLTAGE')"/>
        <EonCheckbox class="sub-sub-checkbox" v-model="completeVoltageLack"
                     :label="($t('message.addReport.completeVoltageLack'))"
                     @click="uncheckOthers('completeVoltageLack', 'NO_VOLTAGE')"/>
        <!--        <EonCheckbox class="sub-checkbox" v-model="lowVoltage"
        @click="uncheckOthers('lowVoltage', 'LOW_VOLTAGE')"-->
        <!--                     :label="($t('message.addReport.lowVoltage'))"/>-->
        <!--        <EonCheckbox class="sub-checkbox" v-model="highVoltage"
        @click="uncheckOthers('highVoltage', 'HIGH_VOLTAGE')"-->
        <!--                     :label="($t('message.addReport.highVoltage'))"/>-->
        <!--        <p v-if="highVoltage" class="no-margin-paragraph sub-checkbox">-->
        <!--            Czy zainstalowana fotowoltaika?-->
        <!--        </p>-->
        <!--        <EonCheckbox v-if="highVoltage" class="sub-sub-checkbox" v-model="isPhotovoltaics"-->
        <!--                     :label="($t('message.addReport.yes'))"/>-->
        <!--        <EonCheckbox v-if="highVoltage" class="sub-sub-checkbox" v-model="isNoPhotovoltaics"-->
        <!--                     :label="($t('message.addReport.no'))"/>-->
        <!--        <EonCheckbox class="sub-checkbox" v-model="voltageFluctuation"-->
        <!--                     @click="uncheckOthers('voltageFluctuation', 'VOLTAGE_FLUCTUATION')"-->
        <!--                     :label="($t('message.addReport.voltageFluctuation'))"/>-->
        <!--        <p v-if="voltageFluctuation" class="sub-checkbox no-margin-paragraph">-->
        <!--            Czy zainstalowana fotowoltaika?-->
        <!--        </p>-->
        <!--        <EonCheckbox v-if="voltageFluctuation" class="sub-sub-checkbox" v-model="isPhotovoltaics"-->
        <!--                     :label="($t('message.addReport.yes'))"/>-->
        <!--        <EonCheckbox v-if="voltageFluctuation" class="sub-sub-checkbox" v-model="isNoPhotovoltaics"-->
        <!--                     :label="($t('message.addReport.no'))"/>-->
        <!--        <EonCheckbox class="sub-checkbox" v-model="counterMalfunction"-->
        <!--                     @click="uncheckOthers('counterMalfunction', 'COUNTER_MALFUNCTION')"-->
        <!--                     :label="($t('message.addReport.counterMalfunction'))"/>-->
        <!--        <p class="contact-info-bubble" v-if="showInfo">-->
        <!--            Aby przyjąć Twoje zgłoszenie potrzebujemy więcej informacji - zadzwoń-->
        <!--            <span class="contact-info-bubble-phone">-->
        <!--                <i class="pi pi-phone"></i> 22 821 31 31-->
        <!--            </span>-->
        <!--        </p>-->
        <div class="add-report-description-buttons">
            <EonOutlinedButton class="turquoise-button" label="Powrót" @click="goBack"/>
            <EonButton extension="30" class="turquoise-button"
                       label="Dalej" @click="submit" :disabled="isDisabled"/>
        </div>
    </div>
</template>
<script>
    import EonInputTextArea from "@/components/form/EonInputTextArea";
    import EonButton from "@/components/form/EonButton";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import EonCheckbox from "@/components/form/EonCheckbox";

    export default {
        name: "AddUserReportDescription",
        components: {
            EonCheckbox,
            EonInputTextArea,
            EonButton,
            EonOutlinedButton,
        },
        emits: [
            "descriptionReport",
        ],
        data() {
            return {
                type: "",
                isNoVoltage: true,
                partialVoltageLack: false,
                completeVoltageLack: false,
                noVoltage: false,
                lowVoltage: false,
                highVoltage: false,
                voltageFluctuation: false,
                counterMalfunction: false,
                isPhotovoltaics: false,
                isNoPhotovoltaics: false,
                showInfo: false,
                outageInArea: false,
                isPlanned: false,
                inExtensiveArea: false,
                extensiveAreaDescription: "",
            };
        },
        beforeMount() {
            const {params} = this.$route;
            if (params.outageInArea !== undefined) {
                this.outageInArea = (params.outageInArea === "true");
            }
            if (params.isPlanned !== undefined) {
                this.isPlanned = (params.isPlanned === "true");
            }
            if (params.inExtensiveArea !== undefined) {
                this.inExtensiveArea = (params.inExtensiveArea === "true");
            }
            if (params.extensiveAreaDescription) {
                this.extensiveAreaDescription = params.extensiveAreaDescription;
            }
        },

        methods: {
            goBack() {
                this.$router.replace({name: "publicAddReportDetails"});
            },
            submit() {
                this.$emit("descriptionReport", {
                    type: this.type,
                });
                const {
                    isNoVoltage, inExtensiveArea, extensiveAreaDescription,
                } = this;
                this.$router.replace({
                    name: "publicAddReportCheck",
                    params: {
                        isNoVoltage, inExtensiveArea, extensiveAreaDescription,
                    },
                });
            },
            uncheckOthers(option, reportType) {
                if (option === "noVoltage") {
                    this.lowVoltage = false;
                    this.highVoltage = false;
                    this.voltageFluctuation = false;
                    this.counterMalfunction = false;
                }
                if (this.lowVoltage || this.highVoltage || this.voltageFluctuation || this.counterMalfunction) {
                    this.completeVoltageLack = false;
                    this.partialVoltageLack = false;
                }
                if (this.isNoVoltage || this.lowVoltage || this.counterMalfunction) {
                    this.isPhotovoltaics = false;
                    this.isNoPhotovoltaics = false;
                }
                if (option === "lowVoltage") {
                    this.isNoVoltage = false;
                    this.highVoltage = false;
                    this.voltageFluctuation = false;
                    this.counterMalfunction = false;
                    this.type = reportType;
                }
                if (option === "highVoltage") {
                    this.isNoVoltage = false;
                    this.lowVoltage = false;
                    this.voltageFluctuation = false;
                    this.counterMalfunction = false;
                    this.type = reportType;
                }
                if (option === "voltageFluctuation") {
                    this.isNoVoltage = false;
                    this.lowVoltage = false;
                    this.highVoltage = false;
                    this.counterMalfunction = false;
                    this.type = reportType;
                }
                if (option === "counterMalfunction") {
                    this.isNoVoltage = false;
                    this.lowVoltage = false;
                    this.highVoltage = false;
                    this.voltageFluctuation = false;
                    this.type = reportType;
                }
                if (option === "partialVoltageLack") {
                    this.completeVoltageLack = false;
                    this.type = reportType;
                }
                if (option === "completeVoltageLack") {
                    this.partialVoltageLack = false;
                    if (this.inExtensiveArea) {
                        // obszar należy do awarii rozległej
                        this.type = "EXTENSIVE_FAILURE";
                    } else if (this.outageInArea && this.isPlanned) {
                        // wiemy już o planowanym wyłączeniu w tym obszarze
                        this.type = "WORKS";
                    } else if (this.outageInArea) {
                        // wiemy już o awarii w tym obszarze
                        this.type = "POWER_FAILURE";
                    } else {
                        // nowe zgłoszenie
                        this.type = reportType;
                    }
                }
            },
        },
        computed: {
            isDisabled() {
                if (this.showInfo) return this.showInfo;
                return !(this.completeVoltageLack
                    || this.partialVoltageLack
                    || this.lowVoltage
                    || (this.highVoltage && this.isPhotovoltaics)
                    || (this.voltageFluctuation && this.isPhotovoltaics)
                    || this.counterMalfunction);
            },
        },
        watch: {
            isPhotovoltaics(value) {
                if (value && this.isNoPhotovoltaics) {
                    this.isNoPhotovoltaics = false;
                }
            },
            isNoPhotovoltaics(value) {
                if (value && this.isPhotovoltaics) {
                    this.isPhotovoltaics = false;
                }
                this.showInfo = value;
            },
        },
    };
</script>
<style lang="less" scoped>
@import "../../../assets/theme/variable.less";

h2 {
    color: @color-turquoise;
}

.add-report-description {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    justify-content: left;
    width: 100%;
    .eon-text-area {
        width: 100%;
    }
    padding: 1em 2em 1em 2em;
    @media screen and (max-width: 880px) {
        padding: 0;
    }
}
.add-report-description-buttons {
    display: flex;
    justify-content: center;
    column-gap: 0.5em;
    padding-top: 1em;
}

a {
    color: @color-turquoise;
    &:hover {
        color: @color-turquoise-dark;
        text-decoration: underline;
    }
    &:visited {
        color: @color-turquoise-dark;
    }
}
.sub-checkbox {
    padding-left: 1em;
}
.sub-sub-checkbox {
    padding-left: 2em;
}
.bold-checkbox {
    font-weight: 500;
}
.no-margin-paragraph {
    margin: 0 0 0 0;
}
.contact-info-bubble {
    padding: 12px 16px;
    background-color: #F6C1B0;
    border: 1px solid @color-red;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    &-phone {
        display: flex;
        align-items: center;
        gap: 6px;
        color: @secondary-color;
        font-weight: 700;
    }
}
</style>
